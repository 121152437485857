<div class="login-user-credentials-container" [ngClass]="{'disabled-container': model.authenticatingUser}">
  <mat-card>
    <app-error-panel></app-error-panel>
    <mat-card-title class="cwi-mat-accent">Log på Business Connect</mat-card-title>

    <mat-card-content>
      <mat-form-field>
        <mat-label>Brugernavn</mat-label>
        <input type="text" matInput #username [(ngModel)]="model.userCredentials.username" placeholder="Brugernavn">
      </mat-form-field>
    </mat-card-content>

    <mat-card-content>
      <mat-form-field>
        <mat-label>Adgangskode</mat-label>
        <input matInput #password [(ngModel)]="model.userCredentials.password" type="password"
          [type]="model.hidePassword ? 'password' : 'text'" placeholder="Adgangskode">
        <button tabindex="-1" mat-icon-button matSuffix (click)="model.hidePassword = !model.hidePassword"
          [matTooltip]="model.hidePassword ? 'Vis adgangskode' : 'Skjul adgangskode'">
          <mat-icon class="icon-sm">{{ model.hidePassword ? "visibility_off" : "visibility" }}
          </mat-icon>
        </button>
      </mat-form-field>
    </mat-card-content>

    <mat-card-content style="display:flex; justify-content:space-between">
      <mat-checkbox tabindex="-1" color="accent" #rememberme [(ngModel)]="model.rememberMeChecked" (change)="setRememberMe()">
        Husk mig
      </mat-checkbox>
      <button mat-raised-button color="accent" [disabled]="password.value === ''|| username.value === ''" (click)="onLogin()">
        Log ind <mat-icon fontIcon="login"></mat-icon>
      </button>
    </mat-card-content>
  </mat-card>
</div>
<mat-spinner spinnerPosition="center" *ngIf="model.authenticatingUser"></mat-spinner>