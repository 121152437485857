// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
// Http
import { AxiosHttpClient } from 'src/app/_http/axios-http-client';
// Environment
import { environment } from 'src/environments/environment';
// Services
import * as AppServices from '../../../_services';
import { ErstStatusCodeConstantService } from './erst-status-code-constant.service';
import { ErrorTranslationService } from '../../error/_service/error-translation.service';
// Models
import * as ApiModels from './api-models';
import { ISubmitDisplayResponseModel } from '../_models/interfaces';

type SubmitType = 'ixbrl' | 'xbrl';
@Injectable({
  providedIn: 'root'
})

export class ErstService {
  constructor(
    private erstStatusCodeConstantService: ErstStatusCodeConstantService,
    private errorTranslationService: ErrorTranslationService,
    private httpClient: AxiosHttpClient,
  ) { }

  public submitXbrl(certPwd: string, certBase64: string, storeCert: boolean, token: string, fileNames: string[], xbrl: string, xbrlSupplerende: string, pdf: string, onlyTest: boolean): Observable<ISubmitDisplayResponseModel> {

    const XBRL_SUBMIT_REQUEST: ApiModels.RequestApiModels.ErstXbrlRequestApiModel = {
      certPwd: certPwd,
      certBase64: certBase64,
      storeCertOnServer: storeCert,
      token: token,
      fileNames: fileNames,
      xbrl: xbrl,
      pdf: pdf,
      xbrlSupplerende: xbrlSupplerende,
      onlyTest: onlyTest,
    };

    return this.httpClient.post(AppServices.BusinessCoreConstantService.ENDPOINT_INDSEND_XBRL, XBRL_SUBMIT_REQUEST, true, true)
      .pipe(
        timeout(environment.timeout),
        map((erstXbrlRequestApiModel: ApiModels.ResponseApiModels.IErstXbrlApiResponseModel) => {
          return this.mapToSubmitDisplayResponse(erstXbrlRequestApiModel, 'xbrl', onlyTest);
        }),
        catchError(error => {
          console.error(error);
          return throwError(() => this.mapToSubmitDisplayResponse(error, 'xbrl', onlyTest));
        })
      );
  }

  public submitIxbrl(certPwd: string, certBase64: string, storeCert: boolean, token: string, fileNames: string[], ixbrl: string, xbrlSupplerende: string, onlyTest: boolean): Observable<ISubmitDisplayResponseModel> {

    const IXBRL_SUBMIT_REQUEST: ApiModels.RequestApiModels.ErstIxbrlRequestApiModel = {
      certPwd: certPwd,
      certBase64: certBase64,
      storeCertOnServer: storeCert,
      token: token,
      fileNames: fileNames,
      iXbrl: ixbrl,
      iXbrlSupplerende: xbrlSupplerende,
      onlyTest: onlyTest,
      destinationErst: true,
    };

    return this.httpClient.post(AppServices.BusinessCoreConstantService.ENDPOINT_INDSEND_IXBRL, IXBRL_SUBMIT_REQUEST, true, true)
      .pipe(
        timeout(environment.timeout),
        map((erstIxbrlRequestApiModel: ApiModels.ResponseApiModels.IErstIxbrlApiResponseModel) => {
          return this.mapToSubmitDisplayResponse(erstIxbrlRequestApiModel, 'ixbrl', onlyTest);
        }),
        catchError(error => {
          console.error(error);
          return throwError(() => this.mapToSubmitDisplayResponse(error, 'ixbrl', onlyTest));
        })
      );
  }

  private mapToSubmitDisplayResponse(response: ApiModels.ResponseApiModels.IErstXbrlApiResponseModel | ApiModels.ResponseApiModels.IErstIxbrlApiResponseModel, submitType: SubmitType, onlyTest: boolean): ISubmitDisplayResponseModel {
    let submitDisplayResponse: ISubmitDisplayResponseModel = {
      status: null,
      statusMessage: null,
      message: [],
      buttonLabel: null,
      token: null,
    };

    let responseModel;
    if (submitType === 'ixbrl') {
      responseModel = response['indsendIXbrlResultModel'] as ApiModels.ResponseApiModels.IErstSubmitResponseResultModel;
    } else {
      responseModel = response['indsendXbrlResultModel'] as ApiModels.ResponseApiModels.IErstSubmitResponseResultModel;
    }

    // bc system error
    if (response.errorModel.systemError !== null) {
      submitDisplayResponse = this.mapBcSystemErrorToSubmitDisplayResponse(response, submitDisplayResponse);
    }
    // bc business error
    else if (response.errorModel.businessErrors.length > 0) {
      submitDisplayResponse = this.mapBcBusinessErrorToSubmitDisplayResponse(response, submitDisplayResponse);
    }
    // JsonScemaValidationError
    else if (responseModel.jsonSchemaValidationErrorModels.length > 0) {
      submitDisplayResponse = this.mapBcJsonScemaValidationErrorToSubmitDisplayResponse(responseModel, submitDisplayResponse);
    }
    // OK
    else if (responseModel.statusCode === this.erstStatusCodeConstantService.ERST_STATUS_CODE_0.VALUE) {
      submitDisplayResponse = this.mapErstStatusZeroToSubmitDisplayResponse(responseModel, submitDisplayResponse, onlyTest);
    }
    else if (responseModel.statusCode !== "") {
      submitDisplayResponse = this.mapErstResponseToSubmitDisplayResponse(responseModel, submitDisplayResponse, onlyTest);
    }
    return submitDisplayResponse;
  }

  private mapBcSystemErrorToSubmitDisplayResponse(response: ApiModels.ResponseApiModels.IErstXbrlApiResponseModel | ApiModels.ResponseApiModels.IErstIxbrlApiResponseModel, submitDisplayResponse: ISubmitDisplayResponseModel): ISubmitDisplayResponseModel {
    submitDisplayResponse.status = 'error';
    submitDisplayResponse.statusMessage = 'Fejl';
    submitDisplayResponse.message = this.errorTranslationService.translateSystemErrors([response.errorModel.systemError]).map(e => e.message);
    submitDisplayResponse.message.push(
      // refactor : move phone number to constant
      `<br>Der er opstået en teknisk fejl - prøv igen. Ved gentagen fejlmeddelelse bedes du kontakt vores support 4738 4484 eller support.dk@caseware.com.`
    );
    submitDisplayResponse.message.push(`<br>Vær opmærksom på at hvis systemet lukkes, gemmes indtastede/uploadede data ikke.`);
    return submitDisplayResponse;
  }

  private mapBcBusinessErrorToSubmitDisplayResponse(response: ApiModels.ResponseApiModels.IErstXbrlApiResponseModel | ApiModels.ResponseApiModels.IErstIxbrlApiResponseModel, submitDisplayResponse: ISubmitDisplayResponseModel): ISubmitDisplayResponseModel {
    submitDisplayResponse.status = 'warning';
    submitDisplayResponse.statusMessage = 'Fejl';
    submitDisplayResponse.message = this.errorTranslationService.translateBusinessErrors(response.errorModel.businessErrors).map(e => e.errorMessage);
    submitDisplayResponse.message.push(`<br>Du kan ikke indsende regnskab, før ovenstående er løst.`);
    return submitDisplayResponse;
  }

  private mapBcJsonScemaValidationErrorToSubmitDisplayResponse(response: ApiModels.ResponseApiModels.IErstSubmitResponseResultModel, submitDisplayResponse: ISubmitDisplayResponseModel): ISubmitDisplayResponseModel {
    submitDisplayResponse.status = 'error';
    submitDisplayResponse.statusMessage = 'Fejl';
    submitDisplayResponse.message = response.jsonSchemaValidationErrorModels.map(e => e.message);
    submitDisplayResponse.message.push(
      // refactor : move phone number to constant
      `<br>Der er opstået en teknisk fejl - prøv igen. Ved gentagen fejlmeddelelse bedes du kontakt vores support 4738 4484 eller support.dk@caseware.com.`
    );
    submitDisplayResponse.message.push(`<br>Vær opmærksom på at hvis systemet lukkes, gemmes indtastede/uploadede data ikke.`);
    return submitDisplayResponse;
  }

  private mapErstStatusZeroToSubmitDisplayResponse(response: ApiModels.ResponseApiModels.IErstSubmitResponseResultModel, submitDisplayResponse: ISubmitDisplayResponseModel, onlyTest: boolean): ISubmitDisplayResponseModel {
    submitDisplayResponse.status = 'succes';
    submitDisplayResponse.statusMessage = 'Regnskab indsendt';
    if (onlyTest) {
      submitDisplayResponse.statusMessage = 'Resultat af regnskabstest hos Erhvervsstyrelsen';
      submitDisplayResponse.message.push(`<br>De testede dokumenter er valideret uden fejl.`);
    } else {
      submitDisplayResponse.message = [`<b>Erhvervsstyrelsen Sagsnummer:</b> ${response?.uniqueReportIdentifier}`];
      submitDisplayResponse.message.push(`<br>Regnskabet er nu indsendt til Erhvervsstyrelsen`);
    }
    return submitDisplayResponse;
  }

  private mapErstResponseToSubmitDisplayResponse(response: ApiModels.ResponseApiModels.IErstSubmitResponseResultModel, submitDisplayResponse: ISubmitDisplayResponseModel, onlyTest: boolean): ISubmitDisplayResponseModel {
    submitDisplayResponse.message.push(`<b>Statuskode:</b> ${response?.statusCode}`);
    if (response?.statusText) {
      submitDisplayResponse.message.push(
        `<b>Statusbesked:</b> ${response?.statusText.replace(response?.statusCode, '').trim()}`
      );
    } else {
      submitDisplayResponse.message.push(
        `<b>Statusbesked:</b> Der er sket en fejl hos Erhvervsstyrelsen, prøv igen senere`
      );
    }

    if (response.statusDetail?.length) {
      // refactor
      let RegExpressions = {
        breakRegex: { regex: new RegExp(/&lt;br \/&gt;/g), replacement: '' },
        nonBreakingSpaceRegex: { regex: new RegExp(/&amp;nbsp;/g), replacement: ' ' },
        orderedListOpenRegex: { regex: new RegExp(/&lt;ul&gt;/g), replacement: '<ol>' },
        orderedListCloseRegex: { regex: new RegExp(/&lt;\/ul&gt;/g), replacement: '</ol>' },
        listOpenRegex: { regex: new RegExp(/&lt;li&gt;/g), replacement: '<li>' },
        listCloseRegex: { regex: new RegExp(/&lt;\/li&gt;/g), replacement: '</li>' },
        errorRegex: { regex: new RegExp(/FEJL:/g), replacement: '<br><br>FEJL:' },
        advisRegex: { regex: new RegExp(/ADVIS:/g), replacement: '<br><br>ADVIS:' },
      };

      let details = response.statusDetail;
      for (var prop in RegExpressions) {
        details = details.replace(RegExpressions[prop].regex, RegExpressions[prop].replacement);
      }

      submitDisplayResponse.message.push(`<b>Statusdetaljer:</b>${details.replace('<br>', '')}`);
    }

    // Advis
    if (response.statusCode === this.erstStatusCodeConstantService.ERST_STATUS_CODE_24.VALUE) {
      submitDisplayResponse.status = 'warning';
      submitDisplayResponse.statusMessage = 'Advis';
      submitDisplayResponse.buttonLabel = onlyTest ? 'Test med token' : 'Indsend med token';
      submitDisplayResponse.message.push(`<b>Advis token:</b> ${response.token}`);
      submitDisplayResponse.token = response.token;
    }
    // already submitted
    else if (response.statusCode === this.erstStatusCodeConstantService.ERST_STATUS_CODE_50.VALUE) {
      submitDisplayResponse.status = 'info';
      submitDisplayResponse.statusMessage = 'Regnskab allerede indsendt';
    }
    // other erst errors
    else {
      submitDisplayResponse.status = 'warning';
      submitDisplayResponse.statusMessage = 'Fejl';
      submitDisplayResponse.message.push(`<br>Du kan ikke indsende regnskab, før ovenstående er løst.`);
    }
    return submitDisplayResponse;
  }
}