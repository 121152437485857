<div class="digital-signing-step-email-info-container">
  <div>
    <mat-form-field floatLabel="always"
      hintLabel="Teksten her kommer til at stå øverst på dokumentet, som kunden åbner samt som navn på forsendelsen i dit arkiv">
      <mat-label>Forsendelsestitel</mat-label>
      <textarea matInput #signingPackageTitle cdkTextareaAutosize cdkAutosizeMaxRows="5" [(ngModel)]="model.signingPackageTitle"
        (change)="signingPackageTitleChange()" [required]="showFormErrors"></textarea>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field hintLabel="Teksten her er den tekst, der står som emnelinje i e-mailen der sendes til kunden">
      <mat-label>E-mail emnelinje</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMaxRows="5" placeholder="Dokumenter til din underskrift"
        [(ngModel)]="model.emailSubject" (change)="emailSubjectChange()" [required]="showFormErrors"></textarea>
    </mat-form-field>
  </div>
  <div>
    <!-- FIXME : hardcoded value -->
    <mat-form-field hintLabel="Teksten her kommer til at stå i den autogenerede mail fra Penneo til kunden">
      <mat-label>E-mail besked</mat-label>
      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="40" [(ngModel)]="model.emailText"
        (change)="emailTextChange()" [required]="showFormErrors"></textarea>
    </mat-form-field>
  </div>
  <!-- todo : BC call only get standard template and not user define templates. Button should be activated when this is fixed -->
  <!-- <div>
    <button mat-raised-button color="accent" (click)="getProviderEmailTemplate()">Hent standardtekst fra Penneo</button>
  </div> -->
</div>