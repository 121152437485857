// Angular
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as DigitalSigningSelectors from '../_state/digital-signing.selectors';
import * as DigitalSigningState from '../_state';
import * as LayoutState from '../../layout/_state';
// Services
import { BusinessCoreRouteConstantService } from 'src/app/_services';

interface IViewModel {
  loadingCaseFiles: boolean,
}

@Component({
  selector: 'app-digital-signing-retrieve',
  templateUrl: './digital-signing-retrieve.component.html',
  styleUrls: ['./digital-signing-retrieve.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: false,
      },
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DigitalSigningRetrieveComponent implements OnInit, OnDestroy {

  model: IViewModel = {
    loadingCaseFiles: false,
  };

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(LayoutState.LayoutActions.setNavigationRoutes(
      {
        routes: [
          { label: 'Overblik forsendelser', route: BusinessCoreRouteConstantService.SIGNING_RETRIEVE },
          { label: 'Opret forsendelse', route: BusinessCoreRouteConstantService.SIGNING_SUBMISSION },
        ]
      }
    ));

    this.store.dispatch(DigitalSigningState.DigitalSigningActions.loadCaseFiles());

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.isCaseFileLoading).subscribe(
      caseFileLoading => this.model.loadingCaseFiles = caseFileLoading
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.clearState());
  }
}
