<div class="dashboard-container" [ngClass]="{'disabled-container': model.loadingLicenses ||
   (model.erstCard && model.erstCard.validated === null) || 
   (model.digitalSigningCard && model.digitalSigningCard.validated === null)}">
  <mat-grid-list cols="4" rowHeight="450px">
    <mat-grid-tile *ngIf="model.erstCard">
      <app-dashboard-card [cardInfo]="model.erstCard"></app-dashboard-card>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="model.digitalSigningCard">
      <app-dashboard-card [cardInfo]="model.digitalSigningCard"></app-dashboard-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
<mat-spinner spinnerPosition="center" *ngIf="model.loadingLicenses || 
  (model.erstCard && model.erstCard.validated === null) ||
   (model.digitalSigningCard && model.digitalSigningCard.validated === null)">
</mat-spinner>