import { DigitalSigningCaseFileDocumentModel } from './DigitalSigningCaseFileDocumentModel';
import { DigitalSigningCaseFileResponseData } from '../_service/api-models/response-models';

export class DigitalSigningCaseFileModel {
  constructor(
    public caseFileId: number,
    public caseFileUrl: string,
    public statusName: string,
    public status: number,
    public vendorStatusDescription: string,
    public title: string,
    public caseFile: DigitalSigningCaseFileResponseData,
    public documents: DigitalSigningCaseFileDocumentModel[],
    public downloading: boolean,
    public error: boolean,
    public errorCode: number,
  ) { }
}