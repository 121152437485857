// Services
import { UserInputValidationService } from 'src/app/_services';
// Models
import { DigitalSigningDocumentCasewareRequest } from 'src/app/components/digital-signing/_models';
import { DigitalSigningSignerCasewareRequest } from 'src/app/components/digital-signing/_models';

export class SuggestionsModel {
  documents: DigitalSigningDocumentCasewareRequest[];
  signers: DigitalSigningSignerCasewareRequest[];

  constructor(data: any) {
    if (!validateInput(data)) return null;
    this.documents = data.documents ? data.documents.map(documents => new DigitalSigningDocumentCasewareRequest(documents)) : null;
    this.signers = data.signers ? data.signers.map(signer => new DigitalSigningSignerCasewareRequest(signer)) : null;
  }
}

function validateInput(input: any) {
  let documentsIsArray: boolean = true;
  let signersIsArray: boolean = true;

  if (input.documents) documentsIsArray = UserInputValidationService.arrayInput(input.documents, true, 'SuggestionsModel');
  if (input.signers) signersIsArray = UserInputValidationService.arrayInput(input.signers, true, 'SuggestionsModel');

  if (!documentsIsArray || !signersIsArray) return false;
  return true;
}