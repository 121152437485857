// Angular
import { Injectable } from '@angular/core';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom, tap } from 'rxjs/operators';
// NgRx
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// State
import * as AuthSelectors from "../../authentication/_state/auth.selectors";
import * as LicenseState from ".";
import * as LicenseSelectors from './license.selectors';
// Service
import { BusinessCoreLicenseService } from '../_services/business-core-license.service';
// Models
import { LicensedServicesModel } from '../_models/LicensedServicesModel';


@Injectable()
export class LicenseEffects {

    constructor(
        private actions$: Actions,
        private licenseService: BusinessCoreLicenseService,
        private store: Store<LicenseState.State>
    ) { }

    getAvailableLicensedServices$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(LicenseState.LicenseActions.getAvailableLicensedServices),
                concatLatestFrom(() => this.store.select(LicenseSelectors.getAvailableLicensedServicesLoadState)),
                filter(([, loadState]) => loadState === 'NOT_LOADED'),
                tap(() => this.store.dispatch(LicenseState.LicenseActions.setAvailableLicensedServicesLoadState({ state: 'LOADING' }))),
                mergeMap(() =>
                    this.licenseService.getAvailableLicensedServices().pipe(
                        map((licenseServices: LicensedServicesModel[]) => {
                            licenseServices.forEach(licens => {
                                // Indsendelses Service
                                if (licens.licensedServiceId === '5a0e3dc7-2063-4985-a0d9-ea931bc3b7e2') {
                                    this.store.dispatch(LicenseState.LicenseActions.setErstLicensedService({ licensedService: licens }));
                                }
                                // Digital Signing Service
                                else if (licens.licensedServiceGroup === 2) {
                                    this.store.dispatch(LicenseState.LicenseActions.setDigitalSigningLicensedService({ licensedService: licens }));
                                }
                            });
                            return LicenseState.LicenseApiActions.getAvailableLicensedServicesSuccess({ availableLicensedServices: licenseServices });
                        }),
                        catchError(error => {
                            this.store.dispatch(LicenseState.LicenseApiActions.getAvailableLicensedServicesFailure());
                            return EMPTY;
                        })
                    )
                ),
            );
    });

    setDigitalSigningLicensedService$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(LicenseState.LicenseActions.setDigitalSigningLicensedService),
                mergeMap((action) =>
                    this.licenseService.validateCredentials(action.licensedService.licensedServiceId).pipe(
                        map((licenseValidated: boolean) => {
                            if (licenseValidated) {
                                return LicenseState.LicenseApiActions.licenseValidatedSuccess({ licensedServiceId: action.licensedService.licensedServiceId });
                            } else {
                                return LicenseState.LicenseApiActions.licenseValidatedFailure({ licensedServiceId: action.licensedService.licensedServiceId });
                            }
                        }),
                        catchError(error => {
                            this.store.dispatch(LicenseState.LicenseApiActions.licenseValidatedFailure({ licensedServiceId: action.licensedService.licensedServiceId }));
                            return EMPTY;
                        })
                    )
                ),
            );
    });

    setErstLicensedService$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(LicenseState.LicenseActions.setErstLicensedService),
                withLatestFrom(
                    this.store.select(AuthSelectors.getAuthUser),
                ),
                mergeMap(([action, authUser]) => {
                    if (authUser?.userRoles.includes('ErhvervsstyrelsenIndsender')) {
                        return of(LicenseState.LicenseApiActions.licenseValidatedSuccess({ licensedServiceId: action.licensedService.licensedServiceId }));
                    } else {
                        return of(LicenseState.LicenseApiActions.licenseValidatedFailure({ licensedServiceId: action.licensedService.licensedServiceId }));
                    }
                }),
            );
    });
}