<div class="digital-signing-step-files-uploader-container">
  <div class="file-uploader-container">
    <file-uploader instructions="Træk filer hertil eller klik for at vælge filer" [acceptedTypes]="['application/pdf']"
      [multipleSelection]="true" [showFilesUnder]="false" [removeFileIndex]="removeFileInFileUploader.asObservable()"
      (fileUpload)="fileUpload($event)" (fileRemove)="fileRemove($event)" [resetComponent]="resetComponent">
    </file-uploader>
  </div>
  <div class="uploaded-files-list">
    <div *ngFor="let document of model.documents; index as i">
      <button mat-list-icon mat-icon-button (click)="openFileInNewWindow(document)">
        <mat-icon iconType="file"></mat-icon>
      </button>
      <span EllipsisToolTip [matTooltip]="document.title" class="file-name">
        <a (click)="openFileInNewWindow(document)">{{document.title}}</a>
      </span>
      <span class="document-type-selector">
        <mat-select-search label="Vælg dokumenttype" [options]="model.documentTypes" propertyOfValueToDisplay="name"
          (selectionChange)="setDocumentType($event, i)" [required]="showFormErrors" propertyOfValue="id"
          [value]="model.documents[i].documentTypeId"></mat-select-search>
      </span>
      <span class="file-remove">
        <button mat-icon-button (click)="removeDocument(i)">
          <mat-icon iconType="remove"></mat-icon>
        </button>
      </span>
    </div>
  </div>
</div>