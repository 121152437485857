// NgRx
import { createReducer, on } from '@ngrx/store';
// Models
import * as ErstState from './';
import * as ErstModels from '../_models';
import * as CommonModels from '../../../_models/common';

export interface IErstState {
  isLoading: boolean;
  isLoadingCertificateFromBusinessConnect: boolean;
  isLoadingSuggestedDocuments: boolean;
  activeStep: number,
  erstCertificateInfoLoadState: CommonModels.LoadState;
  erstCertificateInfoFromBusinessConnect: ErstModels.Interfaces.ICertificateDetails;
  erstCertificateNotUploadedInBusinessConnect: boolean;
  erstCertificateFile: CommonModels.FileDetailsModel,
  erstCertificateBase64: string,
  erstCertificatePassword: string,
  storeCertificate: boolean;
  erstIxbrlFile: CommonModels.FileDetailsModel,
  erstIxbrlBase64: string,
  erstPdfFile: CommonModels.FileDetailsModel,
  erstPdfBase64: string,
  erstXbrlFile: CommonModels.FileDetailsModel,
  erstXbrlBase64: string,
  erstXbrlRevenueFile: CommonModels.FileDetailsModel,
  erstXbrlRevenueBase64: string,
  isIxbrl: boolean,
  erstSubmitToken: string,
  erstSubmitResponse: ErstModels.Interfaces.ISubmitDisplayResponseModel;
}

export const initialState: IErstState = {
  isLoading: false,
  isLoadingCertificateFromBusinessConnect: false,
  isLoadingSuggestedDocuments: false,
  activeStep: 0,
  erstCertificateInfoLoadState: 'NOT_LOADED',
  erstCertificateInfoFromBusinessConnect: null,
  erstCertificateNotUploadedInBusinessConnect: null,
  erstCertificateFile: null,
  erstCertificateBase64: null,
  erstCertificatePassword: null,
  storeCertificate: false,
  erstIxbrlFile: null,
  erstIxbrlBase64: null,
  erstPdfFile: null,
  erstPdfBase64: null,
  erstXbrlFile: null,
  erstXbrlBase64: null,
  erstXbrlRevenueFile: null,
  erstXbrlRevenueBase64: null,
  isIxbrl: false,
  erstSubmitToken: null,
  erstSubmitResponse: null,
};

export const ErstReducer = createReducer<IErstState>(
  initialState,

  // erstActions
  on(ErstState.ErstActions.clearState, (state, actionArgs): IErstState => {
    return initialState;
  }),

  on(ErstState.ErstActions.clearStateAfterSubmit, (state, actionArgs): IErstState => {
    let returnToStep = state.erstCertificateNotUploadedInBusinessConnect ? 0 : 1;
    return {
      ...state,
      activeStep: returnToStep,
      erstCertificateFile: null,
      erstCertificateBase64: null,
      erstCertificatePassword: null,
      storeCertificate: false,
      erstIxbrlFile: null,
      erstIxbrlBase64: null,
      erstPdfFile: null,
      erstPdfBase64: null,
      erstXbrlFile: null,
      erstXbrlBase64: null,
      erstXbrlRevenueFile: null,
      isIxbrl: false,
      erstXbrlRevenueBase64: null,
      erstSubmitToken: null,
    };
  }),

  on(ErstState.ErstActions.clearSubmitResponse, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstSubmitResponse: null,
    };
  }),

  on(ErstState.ErstActions.setActiveStep, (state, actionArgs): IErstState => {
    return {
      ...state,
      activeStep: actionArgs.step,
    };
  }),

  on(ErstState.ErstActions.loadErstCertificateInfoFromBusinessConnect, (state, actionArgs): IErstState => {
    return {
      ...state,
      isLoadingCertificateFromBusinessConnect: state.erstCertificateInfoLoadState == 'NOT_LOADED' ? true : state.isLoadingCertificateFromBusinessConnect,
    };
  }),

  on(ErstState.ErstActions.setErstCertificateFile, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstCertificateFile: actionArgs.erstCertificateFile,
      erstCertificateBase64: actionArgs.erstCertificateFile ? actionArgs.erstCertificateFile.base64 : null
    };
  }),

  on(ErstState.ErstActions.setErstCertificatePassword, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstCertificatePassword: actionArgs.erstCertificatePassword
    };
  }),

  on(ErstState.ErstActions.setStoreCertificate, (state, actionArgs): IErstState => {
    return {
      ...state,
      storeCertificate: actionArgs.storeCertificate
    };
  }),

  on(ErstState.ErstActions.loadSuggestedDocuments, (state, actionArgs): IErstState => {
    return {
      ...state,
      isLoadingSuggestedDocuments: true,
    };
  }),

  on(ErstState.ErstActions.setErstIxbrlFile, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstIxbrlFile: actionArgs.erstIxbrlFile,
      erstIxbrlBase64: actionArgs.erstIxbrlFile ? actionArgs.erstIxbrlFile.base64 : null,
      isIxbrl: actionArgs.erstIxbrlFile ? true : false,
    };
  }),

  on(ErstState.ErstActions.setErstPdfFile, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstPdfFile: actionArgs.erstPdfFile,
      erstPdfBase64: actionArgs.erstPdfFile ? actionArgs.erstPdfFile.base64 : null,
      isIxbrl: actionArgs.erstPdfFile ? false : state.isIxbrl,
    };
  }),

  on(ErstState.ErstActions.setErstXbrlFile, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstXbrlFile: actionArgs.erstXbrlFile,
      erstXbrlBase64: actionArgs.erstXbrlFile ? actionArgs.erstXbrlFile.base64 : null,
      isIxbrl: actionArgs.erstXbrlFile ? false : state.isIxbrl,
    };
  }),

  on(ErstState.ErstActions.setErstXbrlRevenueFile, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstXbrlRevenueFile: actionArgs.erstXbrlRevenueFile,
      erstXbrlRevenueBase64: actionArgs.erstXbrlRevenueFile ? actionArgs.erstXbrlRevenueFile.base64 : null
    };
  }),

  on(ErstState.ErstActions.setXbrlToken, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstSubmitToken: actionArgs.token,
    };
  }),

  on(ErstState.ErstActions.submitIxbrl, (state, actionArgs): IErstState => {
    return {
      ...state,
      isLoading: true,
    };
  }),

  on(ErstState.ErstActions.submitXbrl, (state, actionArgs): IErstState => {
    return {
      ...state,
      isLoading: true,
    };
  }),

  // erstApiActions
  on(ErstState.ErstApiActions.loadErstCertificateInfoFromBusinessConnectSuccess, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstCertificateInfoLoadState: 'LOADED',
      erstCertificateInfoFromBusinessConnect: actionArgs.erstCertificateInfoFromBusinessConnect,
      erstCertificateNotUploadedInBusinessConnect: false,
      isLoadingCertificateFromBusinessConnect: false,
    };
  }),

  on(ErstState.ErstApiActions.loadErstCertificateInfoFromBusinessConnectFailure, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstCertificateInfoLoadState: 'NOT_LOADED',
      erstCertificateInfoFromBusinessConnect: null,
      erstCertificateNotUploadedInBusinessConnect: actionArgs.noCertificateUploaded,
      isLoadingCertificateFromBusinessConnect: false,
    };
  }),

  on(ErstState.ErstApiActions.loadSuggestedDocumentsSuccess, (state, actionArgs): IErstState => {
    return {
      ...state,
      isLoadingSuggestedDocuments: false,
    };
  }),

  on(ErstState.ErstApiActions.loadSuggestedDocumentsFailure, (state, actionArgs): IErstState => {
    return {
      ...state,
      isLoadingSuggestedDocuments: false,
    };
  }),

  on(ErstState.ErstApiActions.submitIxbrlSuccess, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstSubmitResponse: actionArgs.erstSubmitResponse,
      isLoading: false,
    };
  }),

  on(ErstState.ErstApiActions.submitIxbrlFailure, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstSubmitResponse: actionArgs.erstSubmitResponse,
      isLoading: false,
    };
  }),

  on(ErstState.ErstApiActions.submitXbrlSuccess, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstSubmitResponse: actionArgs.erstSubmitResponse,
      isLoading: false,
    };
  }),

  on(ErstState.ErstApiActions.submitXbrlFailure, (state, actionArgs): IErstState => {
    return {
      ...state,
      erstSubmitResponse: actionArgs.erstSubmitResponse,
      isLoading: false,
    };
  }),
);