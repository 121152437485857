// Angular modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// Angular Material
import { MaterialModule } from '../../_material/material.module';
// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Directives
import { IconDirective } from 'src/app/_directive/icon.directive';
// Components
import { UpdateComponent } from './update-component/update.component';
// Other
import { SharedComponentsModule } from '../_shared-components/shared-components.module';
import { UpdateEffects } from './_state/update.effects';
import { UpdateReducer } from './_state/update.reducer';


@NgModule({
    imports: [
        BrowserModule,
        MaterialModule,
        IconDirective,
        SharedComponentsModule,
        StoreModule.forFeature('update', UpdateReducer),
        EffectsModule.forFeature([UpdateEffects]),
    ],
    declarations: [
        UpdateComponent,
    ],
    exports: [
        UpdateComponent,
    ]
})
export class UpdateModule { }