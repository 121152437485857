// NgRx
import { createAction, props } from '@ngrx/store';
// Other
import * as ApiModels from '../../_service/api-models';
import * as DigitalSigningModels from '../../_models';

export const loadDigitalSigningProviderSuccess = createAction(
    '[DigitalSigning Api] Load digital signing provider Success',
    props<{
        digitalSigningProvider: DigitalSigningModels.DigitalSigningProviderModel,
    }>()
);

export const loadDigitalSigningProviderFailure = createAction(
    '[DigitalSigning Api] Load digital signing provider Failure',
);

export const getDigitalSigningEmailTemplateSuccess = createAction(
    '[DigitalSigning Api] Get email template from signing provider Success',
);

export const getDigitalSigningEmailTemplateFailure = createAction(
    '[DigitalSigning Api] Get email template from signing provider Failure',
);

export const submitDigitalSigningRequestSuccess = createAction(
    '[DigitalSigning Api] Submit digital signing request Success',
    props<{
        signingResponse: ApiModels.ResponseModels.DigitalSigningProviderResponseData;
    }>()
);

export const submitDigitalSigningRequestFailure = createAction(
    '[DigitalSigning Api] Submit digital signing request Failure',
);

export const loadCaseFilesSuccess = createAction(
    '[DigitalSigning Api] Load linked case files Success',
    props<{
        caseFiles: DigitalSigningModels.DigitalSigningCaseFileModel[];
    }>()
);

export const loadCaseFilesFailure = createAction(
    '[DigitalSigning Api] Load linked case files Failure',
);