// Services
import { UserInputValidationService } from 'src/app/_services';

export class CvDataGetRequest {
  group: string;
  form: string;
  id: string;

  constructor(cvData: any) {
    if (!validateInput(cvData)) return null;
    this.group = cvData.group ? cvData.group : '';
    this.form = cvData.form ? cvData.form : '';
    this.id = cvData.id;
  }
}

function validateInput(input: any): boolean {
  let idIsRequiredProperty: boolean = false;
  let idIsString: boolean = false;
  let groupIsString: boolean = true;
  let formIsString: boolean = true;

  idIsRequiredProperty = UserInputValidationService.propertyRequired(input, 'id', 'CvDataGetRequest');
  if (idIsRequiredProperty) idIsString = UserInputValidationService.stringInput(input.id, 'CvDataGetRequest');
  if (input.group) idIsString = UserInputValidationService.stringInput(input.group, 'CvDataGetRequest');
  if (input.form) idIsString = UserInputValidationService.stringInput(input.form, 'CvDataGetRequest');


  if (!idIsRequiredProperty || !idIsString || !groupIsString || !formIsString) return false;
  return true;
}