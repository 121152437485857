// Angular
import { Component, EventEmitter, OnInit, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as ErstState from '../../../_state';
import * as ErstSelectors from '../../../_state/erst.selectors';
// Models
import { FileDetailsModel } from 'src/app/_models/common';

type FileTypes = 'ixbrl' | 'pdf' | 'xbrl' | 'xbrlRevenue';

interface IViewModel {
  activeStep: number;
  ixbrlFile: FileDetailsModel;
  pdfFile: FileDetailsModel;
  xbrlFile: FileDetailsModel;
  xbrlRevenueFile: FileDetailsModel;
  ixbrlFilePush: Subject<FileDetailsModel[]>;
  pdfFilePush: Subject<FileDetailsModel[]>;
  xbrlFilePush: Subject<FileDetailsModel[]>;
  xbrlRevenueFilePush: Subject<FileDetailsModel[]>;
}

@Component({
  selector: 'app-erst-submission-step-regnskaber',
  templateUrl: './erst-submission-step-regnskaber.component.html',
  styleUrls: ['./erst-submission-step-regnskaber.component.scss'],
})

export class StepRegnskaberComponent implements OnInit, OnDestroy {
  @Output() stepApproved = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];
  removeFileInFileUploader: Subject<number> = new Subject<number>();
  model: IViewModel = {
    activeStep: null,
    ixbrlFile: null,
    pdfFile: null,
    xbrlFile: null,
    xbrlRevenueFile: null,
    ixbrlFilePush: new Subject<FileDetailsModel[]>(),
    pdfFilePush: new Subject<FileDetailsModel[]>(),
    xbrlFilePush: new Subject<FileDetailsModel[]>(),
    xbrlRevenueFilePush: new Subject<FileDetailsModel[]>(),
  };

  constructor(
    private store: Store,
  ) { }

  public ngOnInit(): void {
    this.store.dispatch(ErstState.ErstActions.loadSuggestedDocuments());

    this.subscriptions.push(this.store.select(ErstSelectors.getActiveStep).subscribe(
      activeStep => { this.model.activeStep = activeStep; }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstIxbrlFile).subscribe(
      ixbrlFile => {

        this.model.ixbrlFile = ixbrlFile;
        this.model.ixbrlFilePush.next([this.model.ixbrlFile].filter(e => e));
        this.validate();
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstPdfFile).subscribe(
      erstPdfFile => {
        this.model.pdfFile = erstPdfFile;
        this.model.pdfFilePush.next([this.model.pdfFile].filter(e => e));
        this.validate();
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstXbrlFile).subscribe(
      erstXbrlFile => {
        this.model.xbrlFile = erstXbrlFile;
        this.model.xbrlFilePush.next([this.model.xbrlFile].filter(e => e));
        this.validate();
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstXbrlRevenueFile).subscribe(
      erstXbrlRevenueFile => {
        this.model.xbrlRevenueFile = erstXbrlRevenueFile;
        this.model.xbrlRevenueFilePush.next([this.model.xbrlRevenueFile].filter(e => e));
        this.validate();
      }
    ));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public fileUpload(uploadedFile: FileDetailsModel, type: FileTypes): void {
    switch (type) {
      case 'ixbrl':
        this.switchIxbrlXbrl(type);
        this.store.dispatch(ErstState.ErstActions.setErstIxbrlFile({ erstIxbrlFile: uploadedFile }));
        break;
      case 'pdf':
        this.switchIxbrlXbrl(type);
        this.store.dispatch(ErstState.ErstActions.setErstPdfFile({ erstPdfFile: uploadedFile }));
        break;
      case 'xbrl':
        this.switchIxbrlXbrl(type);
        this.store.dispatch(ErstState.ErstActions.setErstXbrlFile({ erstXbrlFile: uploadedFile }));
        break;
      case 'xbrlRevenue':
        this.store.dispatch(ErstState.ErstActions.setErstXbrlRevenueFile({ erstXbrlRevenueFile: uploadedFile }));
        break;
      default:
        throw Error(`75c07eb9-da97-4882-9416-f411e71bc360 - The function [fileUpload] does not recognize the type: ${type}`);
    }
    this.validate();
  }

  public fileRemove(type: FileTypes): void {
    switch (type) {
      case 'ixbrl':
        this.store.dispatch(ErstState.ErstActions.setErstIxbrlFile({ erstIxbrlFile: null }));
        break;
      case 'pdf':
        this.store.dispatch(ErstState.ErstActions.setErstPdfFile({ erstPdfFile: null }));
        break;
      case 'xbrl':
        this.store.dispatch(ErstState.ErstActions.setErstXbrlFile({ erstXbrlFile: null }));
        break;
      case 'xbrlRevenue':
        this.store.dispatch(ErstState.ErstActions.setErstXbrlRevenueFile({ erstXbrlRevenueFile: null }));
        break;
      default:
        throw Error(`281d576f-c94b-45a7-9a1e-39c5a1caee32 - The function [fileRemove] does not recognize the type: ${type}`);
    }
    this.validate();
  }

  private validate(): void {
    this.stepApproved.emit(this.model.ixbrlFile !== null || (this.model.pdfFile !== null && this.model.xbrlFile !== null));
  }

  private switchIxbrlXbrl(type: FileTypes): void {
    if (type === 'ixbrl') {
      this.store.dispatch(ErstState.ErstActions.setErstPdfFile({ erstPdfFile: null }));
      this.store.dispatch(ErstState.ErstActions.setErstXbrlFile({ erstXbrlFile: null }));
    } else if (type === 'pdf' || type === 'xbrl') {
      this.store.dispatch(ErstState.ErstActions.setErstIxbrlFile({ erstIxbrlFile: null }));
    }
  }
}

