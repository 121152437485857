// Angular
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export abstract class UserInputValidationService {
  emptyObject(obj) {
    for (var i in obj) return false;
    return true;
  };

  public static canConvertToNumber(input: any, reference: string): boolean {
    if (isNaN(input)) {
      console.error(`Invalid input [${reference}] for ${JSON.stringify(input)} - must be able to be converted to a number`);
      return false;
    }
    return true;
  }

  public static arrayInput(input: any, allowEmpty: boolean, reference: string): boolean {
    if (!Array.isArray(input)) {
      console.error(`Invalid input [${reference}] for ${JSON.stringify(input)} - type needs to be an array`);
      return false;
    }
    if (input.length === 0 && !allowEmpty) {
      console.error(`Invalid input [${reference}] for ${JSON.stringify(input)} - array is empty`);
      return false;
    }
    return true;
  }

  public static functionInput(input: any, reference: string): boolean {
    if (typeof input !== 'function') {
      console.error(`Invalid input [${reference}] for ${JSON.stringify(input)} - type needs to be a function`);
      return false;
    }
    return true;
  }

  public static numberInput(input: any, reference: string): boolean {
    if (typeof input !== 'number') {
      console.error(`Invalid input [${reference}] for ${JSON.stringify(input)} - type needs to be a number`);
      return false;
    }
    return true;
  }

  public static objectInput(input: any, reference: string): boolean {
    const emptyObject = (obj) => { for (var i in obj) return false; return true; };

    if (typeof input !== 'object' || Array.isArray(input)) {
      console.error(`Invalid input [${reference}] for ${JSON.stringify(input)} - type needs to be an object`);
      return false;
    }
    if (emptyObject(input)) {
      console.error(`Invalid input [${reference}] for ${JSON.stringify(input)} - object is empty`);
      return false;
    }
    return true;
  }

  public static propertyRequired(input: any, property: string, reference: string): boolean {
    if (!this.objectInput(input, reference)) return false;
    if (!input.hasOwnProperty(property)) {
      console.error(`Invalid input [${reference}] for ${JSON.stringify(input)} - object needs property ${JSON.stringify(property)}`);
      return false;
    }
    return true;
  }

  public static stringInput(input: any, reference: string): boolean {
    if (typeof input !== 'string') {
      console.error(`Invalid input [${reference}] for ${JSON.stringify(input)} - type needs to be a string`);
      return false;
    }
    if (input === '') {
      console.error(`Invalid input [${reference}] for ${JSON.stringify(input)} - string must not be empty`);
      return false;
    }
    return true;
  }
}