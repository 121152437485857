import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErstStatusCodeConstantService {
    public readonly ERST_STATUS_CODE_0 = { VALUE: "0", TEXT: 'Ok' };
    public readonly ERST_STATUS_CODE_10 = { VALUE: "10", TEXT: 'XBRL dokumentet kan ikke valideres' };
    public readonly ERST_STATUS_CODE_23 = { VALUE: "23", TEXT: 'XBRL dokumentet opfylder ikke Erhvervsstyrelsens krav' };
    public readonly ERST_STATUS_CODE_24 = { VALUE: "24", TEXT: 'XBRL dokumentet er valideret med Advis' };
    public readonly ERST_STATUS_CODE_30 = { VALUE: "30", TEXT: 'Årsrapportens PDF dokument kan ikke valideres' };
    public readonly ERST_STATUS_CODE_31 = { VALUE: "31", TEXT: 'Årsrapportens PDF overstiger den maksimale størrelse på 20 Mb' };
    public readonly ERST_STATUS_CODE_40 = { VALUE: "40", TEXT: 'Det signerede XML dokuments signatur kan ikke valideres' };
    public readonly ERST_STATUS_CODE_41 = { VALUE: "41", TEXT: 'Certifikatet der har signeret XML dokumentet er ikke validt' };
    public readonly ERST_STATUS_CODE_43 = { VALUE: "43", TEXT: 'Det signerede XML dokument kan ikke valideres' };
    public readonly ERST_STATUS_CODE_44 = { VALUE: "44", TEXT: 'Der kan kun anvendes medarbejder- eller virksomhedscertifikat ved indberetning til SKAT' };
    public readonly ERST_STATUS_CODE_47 = { VALUE: "47", TEXT: 'Det anvendte certifikat er udløbet' };
    public readonly ERST_STATUS_CODE_50 = { VALUE: "50", TEXT: 'Der er allerede registreret et regnskab for dette CVR nummer i denne periode - der kan ikke registreres mere end eet regnskab for samme periode' };
    public readonly ERST_STATUS_CODE_51 = { VALUE: "51", TEXT: 'Der skal angives netop een destination' };
    public readonly ERST_STATUS_CODE_55 = { VALUE: "55", TEXT: 'XBRL dokumentet til Erhvervsstyrelsen skal være en Årsrapport eller Likvidationsregnskab' };
    public readonly ERST_STATUS_CODE_56 = { VALUE: "56", TEXT: 'XBRL dokumentet til Danmarks Statistik skal være en Regnskabsstatistik' };
    public readonly ERST_STATUS_CODE_57 = { VALUE: "57", TEXT: 'XBRL dokumentet til SKAT skal være en Selskabsselvangivelse' };
    public readonly ERST_STATUS_CODE_58 = { VALUE: "58", TEXT: 'Mangler registerXbrlEkstern i SOAP' };
    public readonly ERST_STATUS_CODE_59 = { VALUE: "59", TEXT: 'PDF dokumentet mangler' };
    public readonly ERST_STATUS_CODE_60 = { VALUE: "60", TEXT: 'PDF dokumentet overholder ikke størrelsesbegræsningen på 20 Mb' };
    public readonly ERST_STATUS_CODE_72 = { VALUE: "72", TEXT: 'CVR-nummer ikke fundet' };
    public readonly ERST_STATUS_CODE_73 = { VALUE: "73", TEXT: 'Danmarks Statistik svarer at der er fejl i den indberettede selskabsstatistik' };
    public readonly ERST_STATUS_CODE_74 = { VALUE: "74", TEXT: 'Vi kan i øjeblikket ikke få forbindelse til Danmarks Statistiks systemer' };
    public readonly ERST_STATUS_CODE_80 = { VALUE: "80", TEXT: 'REST-kaldets JSON data kunne valideres imod skemaet' };
    public readonly ERST_STATUS_CODE_81 = { VALUE: "81", TEXT: 'Kun ERST er understøttet som destination i REST-kaldet' };
    public readonly ERST_STATUS_CODE_82 = { VALUE: "82", TEXT: 'Inline XBRL (IXBRL) er ikke Base64 kodet i REST-kaldet' };
    public readonly ERST_STATUS_CODE_85 = { VALUE: "85", TEXT: 'Skjult regnskab xbrl er ikke encoded i base64' };
    public readonly ERST_STATUS_CODE_98 = { VALUE: "98", TEXT: 'Systemet er optaget - prøv venligst senere' };
    public readonly ERST_STATUS_CODE_99 = { VALUE: "99", TEXT: 'Der er opstået en fejl - kontakt venligst Erhvervs- og Selskabsstyrelsen' };

    public readonly ERST_STATUS_CODE_DICTIONARY = {
        "0": this.ERST_STATUS_CODE_0,
        "10": this.ERST_STATUS_CODE_10,
        "23": this.ERST_STATUS_CODE_23,
        "24": this.ERST_STATUS_CODE_24,
        "30": this.ERST_STATUS_CODE_30,
        "31": this.ERST_STATUS_CODE_31,
        "40": this.ERST_STATUS_CODE_40,
        "41": this.ERST_STATUS_CODE_41,
        "43": this.ERST_STATUS_CODE_43,
        "44": this.ERST_STATUS_CODE_44,
        "47": this.ERST_STATUS_CODE_47,
        "50": this.ERST_STATUS_CODE_50,
        "51": this.ERST_STATUS_CODE_51,
        "55": this.ERST_STATUS_CODE_55,
        "56": this.ERST_STATUS_CODE_56,
        "57": this.ERST_STATUS_CODE_57,
        "58": this.ERST_STATUS_CODE_58,
        "59": this.ERST_STATUS_CODE_59,
        "60": this.ERST_STATUS_CODE_60,
        "72": this.ERST_STATUS_CODE_72,
        "73": this.ERST_STATUS_CODE_73,
        "74": this.ERST_STATUS_CODE_74,
        "80": this.ERST_STATUS_CODE_80,
        "81": this.ERST_STATUS_CODE_81,
        "82": this.ERST_STATUS_CODE_82,
        "85": this.ERST_STATUS_CODE_85,
        "98": this.ERST_STATUS_CODE_98,
        "99": this.ERST_STATUS_CODE_99,
    };
}