// NgRx
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { IAuthState } from './auth.reducer';

// Selectors
const getUserFeatureState = createFeatureSelector<IAuthState>('auth');

export const getAuthUser = createSelector(
    getUserFeatureState,
    state => state.authUser
);

export const getAuthenticatingUser = createSelector(
    getUserFeatureState,
    state => state.authenticatingUser
);
