// Angular
import { Injectable, Type } from '@angular/core';
// Angular material
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// Component
import { SimpleDialogComponent } from '../components/_shared-components/simple-dialog/simple-dialog.component';
// Models
import { SimpleDialogDataModel } from '../components/_shared-components/_models';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  componentDialogRef: MatDialogRef<any>;
  simpleDialogRef: MatDialogRef<SimpleDialogComponent>;

  constructor(
    private dialog: MatDialog,
  ) { }

  openSimpleDialog(data: SimpleDialogDataModel): MatDialogRef<SimpleDialogComponent> {
    return this.simpleDialogRef = this.dialog.open(SimpleDialogComponent, {
      data: data,
      panelClass: data.type + '-dialog'
    });
  };

  openComponent(component: Type<any>, config?: any): void {
    this.componentDialogRef = this.dialog.open(component, config);
  };
}