<mat-form-field [appearance]="appearance" [color]="color">
  <mat-label>{{label}}</mat-label>
  <mat-select #selector matInput [formControl]="optionsController" disableOptionCentering="true" [required]="required"
    [multiple]="multiSelect">
    <mat-select-trigger *ngIf="multiSelect && useSelectTrigger">
      {{propertyOfValueToDisplay ? optionsController.value?.[0]?.[propertyOfValueToDisplay] : optionsController.value?.[0] || ''}}
      <span *ngIf="(optionsController.value?.length || 0) > 1" [matTooltip]="createToolTip()">
        (+{{(optionsController.value?.length || 0) - 1}} {{optionsController.value?.length === 2 ? 'anden' : 'andre'}})
      </span>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search class="select-search" [placeholderLabel]="placeholderLabel" [noEntriesFoundLabel]="noEntriesFoundLabel"
        [formControl]="filterController">
        <mat-icon ngxMatSelectSearchClear class="icon-sm" iconType="close"></mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let option of filteredOptionsController | async" [value]="option">
      {{propertyOfValueToDisplay ? option[propertyOfValueToDisplay] : option}}
    </mat-option>
  </mat-select>
</mat-form-field>