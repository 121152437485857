// Services
import { UserInputValidationService } from 'src/app/_services';
// Models
import { erstConfigFileModel } from './erstConfigFileModel';

export class erstConfigModel {
  pdfFile: erstConfigFileModel = null;
  xbrlFile: erstConfigFileModel = null;
  xbrlRevenueFile: erstConfigFileModel = null;
  ixbrlFile: erstConfigFileModel = null;

  constructor(data: any) {
    if (!validateInput(data)) return null;
    if (data.pdfFile) this.pdfFile = this.addFileModel(data.pdfFile);
    if (data.xbrlFile) this.xbrlFile = this.addFileModel(data.xbrlFile);
    if (data.xbrlRevenueFile) this.xbrlRevenueFile = this.addFileModel(data.xbrlRevenueFile);
    if (data.ixbrlFile) this.ixbrlFile = this.addFileModel(data.ixbrlFile);
  }
  addFileModel(file: any): erstConfigFileModel {
    let fileModel = new erstConfigFileModel(file);
    return Object.values(fileModel).length ? fileModel : null;
  }
}

function validateInput(input: any): boolean {
  let pdfFileIsString = input.pdfFile ? UserInputValidationService.objectInput(input.pdfFile, 'erstSuggestionModel') : true;
  let xbrlFileIsString = input.xbrlFile ? UserInputValidationService.objectInput(input.xbrlFile, 'erstSuggestionModel') : true;
  let xbrlRevenueIsString = input.xbrlRevenue ? UserInputValidationService.objectInput(input.xbrlRevenue, 'erstSuggestionModel') : true;
  let ixbrlFileIsString = input.ixbrlFile ? UserInputValidationService.objectInput(input.ixbrlFile, 'erstSuggestionModel') : true;

  if (!pdfFileIsString || !xbrlFileIsString || !xbrlRevenueIsString || !ixbrlFileIsString) return false;
  return true;
}