// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Http
import { AxiosHttpClient } from 'src/app/_http/axios-http-client';
// Services
import * as AppServices from '../../../_services';
// Models
import * as ApiModels from './api-models';
import * as CertificateModels from '../_models';

@Injectable({
  providedIn: 'root'
})
export class ErstCertificateService {
  constructor(
    private errorService: AppServices.ErrorService,
    private httpClient: AxiosHttpClient,
  ) { }

  public noCertificateUploadedErrorCode: number = 26;

  public loadCertificateFromBusinessConnect(): Observable<CertificateModels.Interfaces.ICertificateDetails> {
    return this.httpClient.get(AppServices.BusinessCoreConstantService.ENDPOINT_CERTIFICATE, true, true)
      .pipe(
        timeout(environment.timeout),
        map((certificateResponseApiModel: ApiModels.ResponseApiModels.ICertificateResponseApiModel) => {
          return this.mapToICertificateDetails(certificateResponseApiModel.certificate);
        }),
        catchError(error => {
          this.serverErrorResponse(error);
          return throwError(() => error);
        })
      );
  }

  private mapToICertificateDetails(apiModel: ApiModels.ResponseApiModels.ICertificateApiModel): CertificateModels.Interfaces.ICertificateDetails {
    let userCertificateDetails: CertificateModels.Interfaces.ICertificateDetails = null;
    if (!apiModel) return null;
    try {
      // REFACTOR : map only necessary information
      userCertificateDetails = {
        certificate: {
          id: apiModel.id,
          type: apiModel.certificateType === 1 ? 'UserCertificate' : 'CommonCertificate',
          typeDisplay: apiModel.certificateType === 1 ? 'Brugercertifikat' : 'Fælles certifikat',
          typeDescription: apiModel.certificateType === 1 ? 'Certifikat registreret på brugerniveau' : 'Certifikat registreret på virksomheds niveau (fælles certifikat)',
          name: apiModel.certificateName,
          expirationDate: apiModel.certificateExpirationDate,
          // refactor: dates
          expirationDateDisplay:
            new Intl.DateTimeFormat('da-DK', { dateStyle: 'long', timeStyle: 'medium' })
              .format(new Date(apiModel.certificateExpirationDate)).replace(' kl.', ','),
          uploadedDate: apiModel.certificateUploadedDate,
          uploadedDateDisplay:
            new Intl.DateTimeFormat('da-DK', { dateStyle: 'long', timeStyle: 'medium' })
              .format(new Date(apiModel.certificateUploadedDate)).replace(' kl.', ','),
          customName: apiModel.customName
        },
        customer: {
          id: apiModel.applicationCustomerId,
          cvr: apiModel.certificateCVR
        },
        user: apiModel.applicationUserData === null ? null : {
          id: apiModel.applicationUserData.id,
          userName: apiModel.applicationUserData.userName,
          email: apiModel.applicationUserData.email,
          phoneNumber: apiModel.applicationUserData.phoneNumber,
          firstName: apiModel.applicationUserData.firstName,
          lastName: apiModel.applicationUserData.lastName,
          middleName: apiModel.applicationUserData.middleName,
          fullName: apiModel.applicationUserData.fullName,
          createdDate: apiModel.applicationUserData.createdDate,
          createdDateDisplay: new Intl.DateTimeFormat('da-DK', { dateStyle: 'long', timeStyle: 'medium' })
            .format(new Date(apiModel.applicationUserData.createdDate)).replace(' kl.', ','),
        }
      };
    } catch (error) {
      console.error(error);
    }
    return userCertificateDetails;
  }

  private serverErrorResponse(error: ApiModels.ResponseApiModels.ICertificateResponseApiModel): void {
    if (error.errorModel?.businessErrors?.find(err => err.errorCode === this.noCertificateUploadedErrorCode)) return;
    this.errorService.handleBusinessConnectErrors(error, false);
  }
}