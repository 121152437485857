export class TokenInfo {
    public sub: string;
    public jti: string;
    public exp: number;
    public iss: string;
    public aud: string;

    public tokenType: string;
    public applicationUserId: number;
    public applicationUserApiKey: string;
    public applicationUserName: string;
    public applicationUserEmail: string;
    public applicationUserPhoneNumber: string;
    public applicationCustomerId: number;
    public applicationClientId: string;
    public applicationClientUID: string;
    public applicationUserRoles: string[];    

    public expirationDate: Date;
}