// NgRx
import { createAction, props } from '@ngrx/store';
// Models
import * as CommonModels from '../../../../_models/common';

export const clearState = createAction(
  '[DigitalSigning] clear state'
);

export const clearStateAfterSubmit = createAction(
  '[DigitalSigning] clear state after submit'
);

export const clearSubmitResponse = createAction(
  '[DigitalSigning] clear state submit response'
);

export const setActiveStep = createAction(
  '[DigitalSigning] Set active step',
  props<{ step: number, }>()
);

export const setErstCertificateInfoLoadState = createAction(
  '[DigitalSigning] Set Signing Provider Load State',
  props<{ loadState: CommonModels.LoadState, }>()
);

export const loadErstCertificateInfoFromBusinessConnect = createAction(
  '[Erst] Get Erst certificates info from Business Connect',
);

export const setErstCertificateFile = createAction(
  '[Erst] Set Erst Certificate file',
  props<{ erstCertificateFile: CommonModels.FileDetailsModel; }>()
);

export const setErstCertificatePassword = createAction(
  '[Erst] Set Erst certificates password',
  props<{ erstCertificatePassword: string; }>()
);

export const setStoreCertificate = createAction(
  '[Erst] Set Erst store certificate',
  props<{ storeCertificate: boolean; }>()
);

export const loadSuggestedDocuments = createAction(
  '[Erst] Get Erst certificates info from Business Connect and load suggested documents',
);

export const loadCertificateAndDocuments = createAction(
  '[Erst] Load suggested documents',
);

export const setErstIxbrlFile = createAction(
  '[Erst] Set Erst ixbrl file',
  props<{ erstIxbrlFile: CommonModels.FileDetailsModel; }>()
);

export const setErstPdfFile = createAction(
  '[Erst] Set Erst pdf file',
  props<{ erstPdfFile: CommonModels.FileDetailsModel; }>()
);

export const setErstXbrlFile = createAction(
  '[Erst] Set Erst xbrl file',
  props<{ erstXbrlFile: CommonModels.FileDetailsModel; }>()
);

export const setErstXbrlRevenueFile = createAction(
  '[Erst] Set Erst xbrl revenue file',
  props<{ erstXbrlRevenueFile: CommonModels.FileDetailsModel; }>()
);

export const setXbrlToken = createAction(
  '[Erst] Set xbrl token',
  props<{ token: string; }>()
);

export const submitIxbrl = createAction(
  '[Erst] Submit Ixbrl to Erst',
  props<{ onlyTest: boolean; }>()
);

export const submitXbrl = createAction(
  '[Erst] Submit Xbrl to Erst',
  props<{ onlyTest: boolean; }>()
);
