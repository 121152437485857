// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Http
import { AxiosHttpClient } from 'src/app/_http/axios-http-client';
// Services
import { CwWpConstantService } from './cw-wp-constant.service';
// Models
import * as CwWpModels from '../_models/cw-wp';
import * as ConfigModels from '../_models/config';
import { DigitalSigningDocumentCasewareRequest } from 'src/app/components/digital-signing/_models';
import { DigitalSigningSignerCasewareRequest } from 'src/app/components/digital-signing/_models';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  config: ConfigModels.ConfigModel = null;

  constructor(
    private httpClient: AxiosHttpClient,
  ) { }

  getConfig(): Observable<ConfigModels.ConfigModel> {
    const FILES_READ_REQUEST: CwWpModels.apiModels.IFilesReadRequests =
      new CwWpModels.apiModels.IFilesReadRequests([{ path: CwWpConstantService.URL_CONFIG, pathType: 'program' }]);
    return this.httpClient.post(CwWpConstantService.ENDPOINT_READ_FILE, FILES_READ_REQUEST, false, true).pipe(
      map((cwResponse: CwWpModels.apiModels.ICwWpPostResponse) => {
        let config = this.convertJsonToConfig(cwResponse.data[0].data);
        if (!config) return null;

        let configModel = new ConfigModels.ConfigModel(config);
        this.config = configModel;
      }),
      catchError(error => {
        throwError(() => error);
        return of(null);
      })
    );
  }

  getDigitalSigningDocumentsSuggestion(licensedServiceId: string, digitalSigningTemplateId: number): DigitalSigningDocumentCasewareRequest[] {
    return this.getDigitalSigningSuggestion(licensedServiceId, digitalSigningTemplateId)?.documents || null;
  }

  getDigitalSigningSignersSuggestion(licensedServiceId: string, digitalSigningTemplateId: number): DigitalSigningSignerCasewareRequest[] {
    return this.getDigitalSigningSuggestion(licensedServiceId, digitalSigningTemplateId)?.signers || null;
  }

  getErstSubmitDocumentsSuggestion(): ConfigModels.ErstConfigModels.erstConfigModel {
    return !this.config?.submissionService ? null : this.config.submissionService;
  };

  private getDigitalSigningSuggestion(licensedServiceId: string, digitalSigningTemplateId: number): ConfigModels.DigitalSigningConfigModels.SuggestionsModel {
    return this.config?.digitalSigning?.[licensedServiceId]?.suggestions?.[digitalSigningTemplateId] || null;
  }

  private convertJsonToConfig(json: string): Object {
    function reviver(key: string, value: any) {
      try {
        return value;
      } catch (e) {
        return null;
      }
    }

    var scope = null;
    try {
      scope = JSON.parse(json, reviver);
    } catch (e) {
      console.error('error in JSON format');
      return null;
    }

    function replaceReference(key: string, value: any) {
      if (typeof value === 'string' && value.includes('$'))
        return eval(value.replace("$", 'scope.'));
      if (Array.isArray(value))
        return value.flat(1);
      return value;
    }

    try {
      return JSON.parse(json, replaceReference);
    } catch (e) {
      console.error('error in JSON format');
      return null;
    }
  }

}
