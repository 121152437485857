// Services
import { UserInputValidationService } from 'src/app/_services';
// Models
import { SuggestionsModel } from './suggestionsModel';

export class SuggestionsModels {
  [templateId: number]: SuggestionsModel;

  constructor(data: any) {
    Object.entries(data).forEach(e => {
      if (validateInput(e)) this[Number(e[0])] = new SuggestionsModel(e[1]);
    });
  }
}

function validateInput(input: any) {
  let keyIsString = UserInputValidationService.stringInput(input[0], 'SuggestionsModels');
  let keyCanConvertToNumber = UserInputValidationService.canConvertToNumber(input[0], 'SuggestionsModels');
  let valueIsObject = UserInputValidationService.objectInput(input[1], 'SuggestionsModels');

  if (!keyIsString || !keyCanConvertToNumber || !valueIsObject) return false;
  return true;
}