// Services
import { UserInputValidationService } from 'src/app/_services';
// Models
import { CvDataGetRequest } from '../api-models/request-models/cvdata/CvDataGetRequest';

export class CvDataGetModel {
  cvData: CvDataGetRequest;
  splitFn?: { separator: string, index: number; } = null;
  switch?: Object = null;
  mapFn?: Function = null;

  constructor(data: any) {
    if (!validateInput(data)) return null;
    this.cvData = new CvDataGetRequest(data.cvData);
    if (data.mapFn) this.mapFn = data.mapFn;
    if (data.splitFn) this.splitFn = data.splitFn;
    if (data.switch) this.switch = data.switch;
  }
}

function validateInput(input: any): boolean {
  let cvDataIsRequiredProperty: boolean = false;
  let cvDataIsObject: boolean = false;
  let splitFnIsObject: boolean = false;
  let switchIsObject: boolean = false;
  let mapFnIsFunction: boolean = true;

  cvDataIsRequiredProperty = UserInputValidationService.propertyRequired(input, 'cvData', 'CvDataGetModel');
  if (cvDataIsRequiredProperty) cvDataIsObject = UserInputValidationService.objectInput(input.cvData, 'CvDataGetModel');
  if (input.splitFn) splitFnIsObject = UserInputValidationService.objectInput(input.splitFn, 'CvDataGetModel');
  if (input.switch) switchIsObject = UserInputValidationService.objectInput(input.switch, 'CvDataGetModel');
  if (input.mapFn) mapFnIsFunction = UserInputValidationService.functionInput(input.mapFn, 'CvDataGetModel');

  if (!cvDataIsRequiredProperty || !cvDataIsObject) return false;
  if (!cvDataIsRequiredProperty || !cvDataIsObject || !mapFnIsFunction) return false;
  return true;
}