<div class="erst-submission-step-indsend-container">
  <div class="submission-summary">
    <div class="submission-files">
      <mat-list>
        <div mat-subheader>Certifikat</div>
        <mat-list-item *ngIf="!model.certificateFile && model.certificateFromBc">
          <mat-icon mat-list-icon>workspace_premium</mat-icon>
          <div mat-line>{{model.certificateFromBc?.certificate.name}}</div>
          <div mat-line> {{model.certificateFromBc?.certificate.typeDisplay}} </div>
        </mat-list-item>
        <mat-list-item *ngIf="model.certificateFile && model.certificatePassword">
          <mat-icon mat-list-icon>attach_file</mat-icon>
          <div mat-line EllipsisToolTip [matTooltip]="model.certificateFile?.name">{{model.certificateFile?.name}}</div>
          <div mat-line>{{displayDate(model.certificateFile?.lastModifiedDate)}}</div>
        </mat-list-item>
        <mat-list-item *ngIf="!model.certificateFile && !model.certificateFromBc">
          <mat-icon mat-list-icon class="icon-waring">warning</mat-icon>
          <div mat-line>Der er ikke valgt et certifikat</div>
        </mat-list-item>
        <mat-list-item *ngIf="model.certificateFile && !model.certificatePassword">
          <mat-icon mat-list-icon class="icon-waring">warning</mat-icon>
          <div mat-line>Der er ikke angivet adgangskode til certifikat</div>
        </mat-list-item>
        <div *ngIf="model.isIxbrl">
          <mat-divider></mat-divider>
          <div mat-subheader>IXbrl-fil</div>
          <mat-list-item *ngIf="model.ixbrlFile">
            <button mat-list-icon mat-icon-button matTooltip="Åben" (click)="openFileInNewWindow(model.ixbrlFile)">
              <mat-icon>attach_file</mat-icon>
            </button>
            <div mat-line EllipsisToolTip [matTooltip]="model.ixbrlFile?.name" class="pointer-cursor"
              (click)="openFileInNewWindow(model.ixbrlFile)">
              {{model.ixbrlFile?.name}}</div>
            <div mat-line>{{displayDate(model.ixbrlFile?.lastModifiedDate)}}</div>
          </mat-list-item>
          <mat-list-item *ngIf="!model.ixbrlFile">
            <mat-icon mat-list-icon class="icon-waring">warning</mat-icon>
            <div mat-line>Der er ikke valgt en ixbrl-fil</div>
          </mat-list-item>
        </div>
        <div *ngIf="!model.isIxbrl">
          <mat-divider></mat-divider>
          <div mat-subheader>Pdf-fil</div>
          <mat-list-item *ngIf="model.pdfFile">
            <button mat-list-icon mat-icon-button matTooltip="Åben" (click)="openFileInNewWindow(model.pdfFile)">
              <mat-icon>attach_file</mat-icon>
            </button>
            <div mat-line EllipsisToolTip [matTooltip]="model.pdfFile?.name" class="pointer-cursor"
              (click)="openFileInNewWindow(model.pdfFile)">
              {{model.pdfFile?.name}}</div>
            <div mat-line>{{displayDate(model.pdfFile?.lastModifiedDate)}}</div>
          </mat-list-item>
          <mat-list-item *ngIf="!model.pdfFile">
            <mat-icon mat-list-icon class="icon-waring">warning</mat-icon>
            <div mat-line>Der er ikke valgt en pdf-fil</div>
          </mat-list-item>
        </div>
        <div *ngIf="!model.isIxbrl">
          <mat-divider></mat-divider>
          <div mat-subheader>XBRL-fil</div>
          <mat-list-item *ngIf="model.xbrlFile">
            <button mat-list-icon mat-icon-button matTooltip="Åben" (click)="openFileInNewWindow(model.xbrlFile)">
              <mat-icon>attach_file</mat-icon>
            </button>
            <div mat-line EllipsisToolTip [matTooltip]="model.xbrlFile?.name" class="pointer-cursor"
              (click)="openFileInNewWindow(model.xbrlFile)">
              {{model.xbrlFile?.name}}</div>
            <div mat-line>{{displayDate(model.xbrlFile?.lastModifiedDate)}}</div>
          </mat-list-item>
          <mat-list-item *ngIf="!model.xbrlFile">
            <mat-icon mat-list-icon class="icon-waring">warning</mat-icon>
            <div mat-line>Der er ikke valgt en XBRL-fil</div>
          </mat-list-item>
        </div>
        <div *ngIf="model.xbrlRevenueFile">
          <mat-divider></mat-divider>
          <div mat-subheader>XBRL-nettoomsætningsfil</div>
          <mat-list-item>
            <button mat-list-icon mat-icon-button matTooltip="Åben" (click)="openFileInNewWindow(model.xbrlRevenueFile)">
              <mat-icon>attach_file</mat-icon>
            </button>
            <div mat-line EllipsisToolTip [matTooltip]="model.xbrlRevenueFile?.name" class="pointer-cursor"
              (click)="openFileInNewWindow(model.xbrlRevenueFile)">
              {{model.xbrlRevenueFile?.name}}</div>
            <div mat-line>{{displayDate(model.xbrlRevenueFile?.lastModifiedDate)}}</div>
          </mat-list-item>
        </div>
        <mat-list-item>
          <button mat-raised-button color="accent" [disabled]="!this.model.ixbrlFile && (!this.model.pdfFile || !this.model.xbrlFile)"
            (click)="testSubmit()">Test indsendelsen hos Erhvervsstyrelsen</button>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>