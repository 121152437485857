// Angular
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export abstract class LocalRegistryConstantService {
    public static readonly REGISTRY_KEY_PATH = 'HKCU\\SOFTWARE\\BusinessConnectWP';
    public static readonly REGISTRY_API_KEY_PATH = LocalRegistryConstantService.REGISTRY_KEY_PATH + '\\apiKey';
}