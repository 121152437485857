// Angular
import { Component, ElementRef, EventEmitter, Input, Output, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
// Services
import { FileService } from 'src/app/_services';
//Models
import { FileDetailsModel } from 'src/app/_models/common';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})

export class FileUploaderComponent implements OnInit, OnDestroy {
  @Input() instructions: string;
  @Input() acceptedTypes: string[];
  @Input() multipleSelection: boolean;
  @Input() showFilesUnder: boolean;
  @Input() removeFileIndex: Observable<number>;
  @Input() resetComponent: Observable<any>;
  @Input() files: Observable<FileDetailsModel[]>;

  @Output() fileUpload = new EventEmitter<FileDetailsModel>();
  @Output() fileRemove = new EventEmitter<number>();

  @ViewChild("fileSelector", { static: false }) fileSelector: ElementRef;

  fileSelectionForm: FormGroup;
  selectedFiles: FileDetailsModel[] = [];
  subscriptions: Subscription[] = [];
  errors: string[] = [];
  warnings: string[] = [];

  constructor(
    private fileService: FileService,
  ) {
    this.fileSelectionForm = new FormGroup({
      file_selection: new FormControl()
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(this.fileSelectionForm.get('file_selection')?.valueChanges.subscribe(() => {
      const file_selection = this.fileSelector.nativeElement;
      this.selectFiles(file_selection.files);
    }));

    if (this.removeFileIndex) this.subscriptions.push(this.removeFileIndex.subscribe((index) => this.removeFile(index)));

    if (this.resetComponent) this.subscriptions.push(this.resetComponent.subscribe(() => {
      this.selectedFiles = [];
      this.removeErrors();
      this.removeWarning();
    }));

    if (this.files) this.subscriptions.push(this.files.subscribe((files) => {
      this.pushedFiles(files);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  openFileSelector(): void {
    const file_selection = this.fileSelector.nativeElement;
    file_selection.value = '';
    file_selection.click();
  }

  pushedFiles(files: FileDetailsModel[]): void {
    if (JSON.stringify(files) === JSON.stringify(this.selectedFiles)) return;

    if (!files || files.length === 0) {
      this.selectedFiles = [];
      return;
    }

    try {
      this.removeErrors();
      this.removeWarning();

      if (this.preventMultipleUpload(files.length)) return;

      for (let i = 0; i < files.length; i++) {
        try {
          const file = files[i];

          if (this.rejectFile(file)) continue;

          this.clearFilesAtReUpload();

          this.selectedFiles.push(file);

        } catch (error) {
          this.errors.push(`Der skete en fejl ved uploading af filen: ${files[i].name}`);
        }
      }

    } catch (error: any) {
      this.errors.push(`Der skete en fejl`);
    }
  }

  async selectFiles(files: FileList): Promise<void> {
    try {
      this.removeErrors();
      this.removeWarning();

      if (this.preventMultipleUpload(files.length)) return;

      for (let i = 0; i < files.length; i++) {
        try {
          const file = await this.fileService.getFileDetail(files[i]);
          if (this.rejectFile(file)) continue;

          this.clearFilesAtReUpload();

          this.selectedFiles.push(file);
          this.fileUpload.emit(file);

        } catch (error) {
          this.errors.push(`Der skete en fejl ved uploading af filen: ${files[i].name}`);
        }
      }

    } catch (error: any) {
      this.errors.push(`Der skete en fejl`);
    }
  }

  private preventMultipleUpload(numberOfFiles): boolean {
    if (!this.multipleSelection && numberOfFiles > 1) {
      this.warnings.push('Der kan kun uploades en fil');
      return true;
    }
    return false;
  }

  private rejectFile(file: FileDetailsModel): boolean {
    if (this.selectedFiles.find(f => f.name === file.name && f.lastModified === file.lastModified && f.size === file.size)) {
      this.warnings.push(`${file.name} er allerede uploaded`);
      return true;
    }

    if (this.acceptedTypes.length > 0 && !this.acceptedTypes.includes(file.type)) {
      this.warnings.push(`Filtypen for ${file.name} er ikke accepteret`);
      return true;
    }

    return false;
  }

  private clearFilesAtReUpload(): void {
    if (!this.multipleSelection && this.selectedFiles.length > 0) {
      this.selectedFiles = [];
    }
  }

  removeFile(index: number): void {
    if (index >= 0) this.selectedFiles.splice(index, 1);
    this.fileRemove.emit(index);
  };

  removeErrors(): void {
    this.errors = [];
  };

  removeWarning(): void {
    this.warnings = [];
  }

  openFileInNewWindow(file: FileDetailsModel): void {
    this.fileService.openBase64FileInNewWindow(file.base64, file.name, file.type);
  }

}
