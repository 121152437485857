// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, timeout } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Http
import { AxiosHttpClient } from 'src/app/_http/axios-http-client';
// Services
import * as AppServices from '../../../_services';
// Models
import * as ApiModels from './api-models';
import { SimpleDialogDataModel } from '../../_shared-components/_models';
import * as CwWpModels from '../../../_models/cw-wp';

@Injectable({
  providedIn: 'root'
})

export class DigitalSigningSubmitService {
  constructor(
    private dialogService: AppServices.DialogService,
    private httpClient: AxiosHttpClient
  ) { }

  public sendToSigning(
    cvr: string,
    signingPackageTemplateTypeId: number,
    signingPackageTitle: string,
    emailSubject: string,
    emailText: string,
    signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[],
    documents: ApiModels.RequestModels.DigitalSigningProviderDocumentModel[],
    endAction: ApiModels.RequestModels.Enums.EndActionEnum = ApiModels.RequestModels.Enums.EndActionEnum.send,
  ): Observable<ApiModels.ResponseModels.IDigitalSigningProviderResponseModel> {
    const digitalSigningRequest = new ApiModels.RequestModels.IDigitalSigningProviderRequestApiModel(
      cvr, signingPackageTemplateTypeId, signingPackageTitle, emailSubject, emailText, signers, documents, endAction
    );
    return this.httpClient.post(AppServices.BusinessCoreConstantService.ENDPOINT_DIGITAL_SIGNING, digitalSigningRequest, true, true).pipe(
      timeout(environment.timeout),
      map((digitalSigningResponse: ApiModels.ResponseModels.IDigitalSigningProviderResponseModel) => {
        this.storeSigningCaseIdInCvData(digitalSigningResponse.data.digitalSigningCaseFileId);
        return digitalSigningResponse;
      }),
      catchError(error => {
        this.serverErrorResponse(error);
        return throwError(() => error);
      })
    );
  }

  private storeSigningCaseIdInCvData(caseId: number): void {
    const cvDataRequest: CwWpModels.apiModels.ICvDataGetRequests =
      new CwWpModels.apiModels.ICvDataGetRequests([AppServices.CwWpCvDataConstantService.SIGNING_PACKAGE_IDS]);
    this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_GET_CV_DATA, cvDataRequest).pipe(
      timeout(environment.timeout),
      mergeMap((wpResponse: CwWpModels.apiModels.ICwWpPostResponse) => {
        // todo : errorhandler
        let existingPackageIds = wpResponse.data[0].data;
        let packageIds = existingPackageIds + caseId + '|';
        return this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_STORE_CV_DATA,
          { requests: [{ ...AppServices.CwWpCvDataConstantService.SIGNING_PACKAGE_IDS, ...{ value: packageIds } }] }).pipe(
            timeout(environment.timeout),
            catchError(error => {
              return throwError(() => error);
            })
          );
      }),
      catchError(error => {
        return throwError(() => error);
      })
    ).subscribe();
  }

  private serverErrorResponse(responseApiModel: any): void {
    if (responseApiModel?.errorModel?.businessErrors.length > 0 || responseApiModel?.errorModel?.systemError) {
      if (responseApiModel?.errorModel?.businessErrors.length > 0) {
        let errorMessage = responseApiModel.errorModel.businessErrors.map(error => '<li>' + error.errorMessage + '</li>').join('');
        errorMessage += '<div><br>Du kan ikke sende til underskrift, før ovenstående er udfyldt korrekt</br></div>';
        this.dialogService.openSimpleDialog(new SimpleDialogDataModel(errorMessage, null, 'Advarsel', 'warning'));
      } else if (responseApiModel?.errorModel?.systemError) {
        let errorMessage =
          `<div>Der er opstået en teknisk fejl - prøv igen. Ved gentagen fejlmeddelelse bedes du kontakt vores support 4738 4484 eller support.dk@caseware.com.</div>
            <div>Vær opmærksom på at hvis systemet lukkes, gemmes indtastede/uploadede data ikke.</div>
            <br>Fejlmeddelelse: </br><div>${responseApiModel.errorModel.systemError.message}</div>`;
        this.dialogService.openSimpleDialog(new SimpleDialogDataModel(errorMessage, null, 'Fejl', 'error'));
      }
    }
  }
}