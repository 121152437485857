// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
// Http
import { AxiosHttpClient } from 'src/app/_http/axios-http-client';
// Environment
import { environment } from 'src/environments/environment';
// Services
import { BusinessCoreConstantService } from 'src/app/_services';
// Models
import * as LicenseApiModels from './api_models';
import { LicensedServicesModel } from '../_models/LicensedServicesModel';

@Injectable({
  providedIn: 'root'
})

export class BusinessCoreLicenseService {
  constructor(
    private httpClient: AxiosHttpClient,
  ) { }

  getAvailableLicensedServices(): Observable<LicensedServicesModel[]> {
    return this.httpClient.get(BusinessCoreConstantService.ENDPOINT_LICENSED_SERVICES, true).pipe(
      timeout(environment.timeout),
      map((licenseResponse: LicenseApiModels.ResponseModels.ILicensedServicesResponseApiModel) => {
        let licenseServiceGroups = licenseResponse.data.licensedServices.flatMap(
          e => e.customerLicense || e.licensedServiceGroup === 0 ?
            new LicensedServicesModel(
              e.licensedServiceId,
              e.licensedServiceName,
              e.licensedServiceDescription,
              e.licensedServiceImageUrl,
              e.licensedServiceGroup,
              e.licensedServiceGroupImageUrl,
              null,
            ) :
            []
        );
        return licenseServiceGroups;
      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  validateCredentials(licensedServiceId: string): Observable<boolean> {
    return this.httpClient.get(BusinessCoreConstantService.ENDPOINT_VALIDATE_CREDENTIALS + '/' + licensedServiceId, true, true).pipe(
      timeout(environment.timeout),
      map((validateResponse: LicenseApiModels.ResponseModels.IValidateCredentialsResponseApiModel) => {
        return validateResponse.data.allProviderCredentialValuesValid;
      }),
      catchError(error => {
        throwError(() => error);
        return of(false);
      })
    );
  }
}