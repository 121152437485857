// NgRx
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { IErstState } from './erst.reducer';

// Selectors
const getErstFeatureState = createFeatureSelector<IErstState>('erst');

export const getActiveStep = createSelector(
  getErstFeatureState,
  state => state.activeStep
);

export const getErstCertificateInfoLoadState = createSelector(
  getErstFeatureState,
  state => state.erstCertificateInfoLoadState
);

export const isErstCertificateNotUploaded = createSelector(
  getErstFeatureState,
  state => state.erstCertificateNotUploadedInBusinessConnect
);

export const getErstCertificateFile = createSelector(
  getErstFeatureState,
  state => state.erstCertificateFile
);

export const getErstCertificateBase64 = createSelector(
  getErstFeatureState,
  state => state.erstCertificateBase64
);

export const getErstCertificatePassword = createSelector(
  getErstFeatureState,
  state => state.erstCertificatePassword,
);

export const getStoreCertificate = createSelector(
  getErstFeatureState,
  state => state.storeCertificate,
);

export const getErstIxbrlFile = createSelector(
  getErstFeatureState,
  state => state.erstIxbrlFile,
);

export const getErstIxbrlBase64 = createSelector(
  getErstFeatureState,
  state => state.erstIxbrlBase64,
);

export const getErstPdfFile = createSelector(
  getErstFeatureState,
  state => state.erstPdfFile,
);

export const getErstPdfBase64 = createSelector(
  getErstFeatureState,
  state => state.erstPdfBase64,
);

export const getErstXbrlFile = createSelector(
  getErstFeatureState,
  state => state.erstXbrlFile,
);

export const getErstXbrlBase64 = createSelector(
  getErstFeatureState,
  state => state.erstXbrlBase64,
);

export const getErstXbrlRevenueFile = createSelector(
  getErstFeatureState,
  state => state.erstXbrlRevenueFile,
);

export const getErstXbrlRevenueBase64 = createSelector(
  getErstFeatureState,
  state => state.erstXbrlRevenueBase64,
);

export const isIxbrl = createSelector(
  getErstFeatureState,
  state => state.isIxbrl,
);

export const getErstCertificateInfoFromBusinessConnect = createSelector(
  getErstFeatureState,
  state => state.erstCertificateInfoFromBusinessConnect,
);

export const getErstSubmitToken = createSelector(
  getErstFeatureState,
  state => state.erstSubmitToken,
);

export const getErstSubmitResponse = createSelector(
  getErstFeatureState,
  state => state.erstSubmitResponse,
);

export const isLoading = createSelector(
  getErstFeatureState,
  state => state.isLoading,
);

export const isLoadingCertificateFromBusinessConnect = createSelector(
  getErstFeatureState,
  state => state.isLoadingCertificateFromBusinessConnect,
);

export const isLoadingSuggestedDocuments = createSelector(
  getErstFeatureState,
  state => state.isLoadingSuggestedDocuments,
);