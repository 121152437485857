export class DashBoardCardModel {
  constructor(
    public imagePath: string,
    public title: string,
    public description: string,
    public buttonFunction: Function,
    public buttonLabel: string,
    public validated: boolean,
    public validateDescription: string,
  ) { }
}