<simple-message [content]="warnings" type="warning" (remove)="removeWarning()"></simple-message>
<simple-message [content]="errors" type="error" (remove)="removeErrors()"></simple-message>

<div class="file-uploader cwi-mat-accent" dragAndDropFile (onFileDropped)="selectFiles($event)" (click)="openFileSelector()">
    <div class="file-uploader-instructions">
        {{instructions}}
    </div>
    <div class="file-upload-browse">
        <form class="file-upload-native-selection" [formGroup]="fileSelectionForm">
            <input type="file" #fileSelector formControlName="file_selection" [accept]="acceptedTypes" multiple="{{multipleSelection}}">
        </form>
    </div>
</div>
<div class="files-for-upload cwi-mat-accent" *ngIf="showFilesUnder && selectedFiles.length > 0">
    <mat-expansion-panel *ngFor="let file of selectedFiles; index as i" class="selected-files" hideToggle disabled>
        <mat-expansion-panel-header>
            <div class="selected-file-header">
                <button mat-list-icon mat-icon-button disableRipple="true" class="selected-file-icon">
                    <mat-icon iconType="file"></mat-icon>
                </button>
                <div class="selected-file-name" EllipsisToolTip [matTooltip]="file.name">
                    <a class="pointer-cursor" (click)="openFileInNewWindow(file)">{{file.name}}</a>
                </div>
                <div class="selected-file-actions">
                    <mat-icon iconType="close" matTooltipPosition="above" matTooltip="Fjern" (click)="removeFile(i)"></mat-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
    </mat-expansion-panel>
</div>