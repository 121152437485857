// Angular
import { Injectable } from '@angular/core';
// Models
import { FileDetailsModel } from '../_models/common';

@Injectable({
    providedIn: 'root'
})

export class FileService {
    async getFileDetail(file: File): Promise<FileDetailsModel> {
        let base64 = await this.readAsBase64Async(file);
        return {
            name: file.name,
            lastModified: file.lastModified - file.lastModified % 1000, // remove milliseconds
            lastModifiedDate: new Date(file.lastModified),
            base64: base64,
            size: file.size,
            type: file.type,
        };
    }

    async readAsBase64Async(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!file) resolve(null);
            const fileReader = new FileReader();
            fileReader.onload = () => {
                let base64: string = fileReader.result.toString().split(',')[1];
                resolve(base64);
            };
            fileReader.onerror = (error) => reject(error);
            fileReader.readAsDataURL(file);
        });
    }

    conventFileExtensionToFileType(extension: string): string {
        let fileType: string;
        switch (extension) {
            case 'pdf':
                fileType = 'application/pdf';
                break;
            case 'xml':
                fileType = 'text/xml';
                break;
            case 'xhtml':
                fileType = 'application/xhtml+xml';
                break;
            default:
                fileType = extension;
                console.error(`4df68dd5-52be-4ca1-9bec-0ef32fd2de9a - The function [conventFileExtensionToFileType] does not recognize the extension: ${extension}`);
        }
        return fileType;
    }

    openBase64FileInNewWindow(base64: string, title: string, scrData?: string): void {
        if (!scrData) scrData = 'application/pdf';
        let fileWindow = window.open("", "_blank");
        fileWindow.document.title = title;
        fileWindow.document.write("<iframe width='100%' height='100%' src='data:" + scrData + ";base64, " + encodeURI(base64) + "'></iframe>");
    }
}