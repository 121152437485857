// Angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Http
import { AxiosHttpClient } from '../_http/axios-http-client';
// Services
import { CwWpConstantService } from './cw-wp-constant.service';
// Models
import * as CwWpModels from '../_models/cw-wp';

@Injectable({
  providedIn: 'root'
})

export class LocalRegistryService {
  constructor(
    private httpClient: AxiosHttpClient,
  ) { }

  read(requests: CwWpModels.apiModels.IRegistryGetRequests): Observable<CwWpModels.apiModels.ICwWpPostResponse> {
    return this.httpClient.post(CwWpConstantService.ENDPOINT_GET_REGISTRY_DATA, requests);
  }

  store(requests: CwWpModels.apiModels.IRegistryStoreRequests): Observable<CwWpModels.apiModels.ICwWpPostResponse> {
    return this.httpClient.post(CwWpConstantService.ENDPOINT_STORE_REGISTRY_DATA, requests);
  }

  delete(requests: CwWpModels.apiModels.IRegistryDeleteRequests): Observable<CwWpModels.apiModels.ICwWpPostResponse> {
    return this.httpClient.post(CwWpConstantService.ENDPOINT_DELETE_REGISTRY_DATA, requests);
  }
}