// Angular
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as DigitalSigningState from '../_state';
// Services
import { BusinessCoreConstantService } from 'src/app/_services';
import { CwWpCvDataConstantService } from 'src/app/_services';
import { WpDataRetrieverService } from 'src/app/_services';
import { ConfigService } from 'src/app/_services';
// Models
import * as ApiModels from './api-models';
import * as DigitalSigningModels from '../_models';
import { CvDataPersonModel } from 'src/app/_models/cw-wp/models';

@Injectable({
  providedIn: 'root'
})

export class DigitalSigningSuggestionService {
  constructor(
    private wpDataRetrieverService: WpDataRetrieverService,
    private configService: ConfigService,
    private store: Store,
  ) { }

  public getSuggestion(licensedServiceId: string, digitalSigningTemplate: DigitalSigningModels.DigitalSigningTemplateInformation)
    : Observable<DigitalSigningModels.DigitalSigningSuggestionModel> {
    if (!licensedServiceId) return null;
    return forkJoin({
      documents: this.getDocumentSuggestion(licensedServiceId, digitalSigningTemplate.id),
      signers: this.getSignerSuggestion(licensedServiceId, digitalSigningTemplate.id).pipe(map(signers => {
        signers = this.convertSignersTitleToRoleId(signers, digitalSigningTemplate.signerTypes);
        return this.mergeDuplicateSigners(signers);
      }))
    });
  };

  public addSuggestion(documents: ApiModels.RequestModels.DigitalSigningProviderDocumentModel[], signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[]) {
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setDocuments({ documents: documents }));
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setSigners({ signers: signers }));
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.clearEmptySigners());
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setShowErrors({ showErrors: true }));
  }

  public getAllSignerSuggestions(): Observable<ApiModels.RequestModels.DigitalSigningProviderSignerModel[]> {
    let observable$: Observable<ApiModels.RequestModels.DigitalSigningProviderSignerModel[]> = of(null);
    let signers = [
      { signers: this.getManagementSignersCvRequest(), mapFn: (v) => { v.title = null; return v; } },
      { signers: this.getBoardSignersCvRequest(), mapFn: (v) => { v.title = null; return v; } },
      { signers: this.getAuditorsSignersCvRequest(), mapFn: (v) => { v.title = null; return v; } },
      { signers: this.getChairmanSignersCvRequest(), mapFn: (v) => { v.title = null; return v; } },
    ];
    observable$ = forkJoin(this.getSigners(signers).flat(1)).pipe(map(e => e.filter(e => e! && e.name)));
    return observable$;
  }

  private getDocumentSuggestion(licensedServiceId: string, digitalSigningTemplateId: number):
    Observable<ApiModels.RequestModels.DigitalSigningProviderDocumentModel[]> {
    let observable$: Observable<ApiModels.RequestModels.DigitalSigningProviderDocumentModel[]> = of(null);
    let configSuggestionDocument = this.configService.getDigitalSigningDocumentsSuggestion(licensedServiceId, digitalSigningTemplateId);

    if (configSuggestionDocument) {
      if (configSuggestionDocument.length > 0) observable$ = forkJoin([this.getDocuments(configSuggestionDocument)].flat(1)).pipe(map(e => e.filter(e => e)));
      return observable$;
    }

    switch (licensedServiceId) {
      case BusinessCoreConstantService.PENNEO_LICENSED_SERVICE_ID:
        let documents = [];
        switch (digitalSigningTemplateId) {
          case 6:
            documents = [
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.FINANCIAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 5 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 6 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.TAX_SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 6 }
              ),
            ];
            break;
          case 256:
            documents = [
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.FINANCIAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 1106 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 1110 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.TAX_SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 1109 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.INTERNAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 1177 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.LETTER_OF_AGREEMENT_PLACEHOLDER_ID, documentTypeId: 1114 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.PROTOCOL_PLACEHOLDER_ID, documentTypeId: 1112 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.MANAGEMENT_LETTER_PLACEHOLDER_ID, documentTypeId: 1107 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SUMMARY_GENERAL_MEETING_PLACEHOLDER_ID, documentTypeId: 1108 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.COMPANY_TAX_RETURN_PLACEHOLDER_ID, documentTypeId: 1111 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.APPENDIX_PLACEHOLDER_ID, documentTypeId: 1113 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.AUDIT_PROTOCOL_PLACEHOLDER_ID, documentTypeId: 1112 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_D_PLACEHOLDER_ID, documentTypeId: 1114 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_B_PLACEHOLDER_ID, documentTypeId: 1115 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DT_PLACEHOLDER_ID, documentTypeId: 1116 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DB_PLACEHOLDER_ID, documentTypeId: 1117 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DBR_PLACEHOLDER_ID, documentTypeId: 1118 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DR_PLACEHOLDER_ID, documentTypeId: 1119 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_BR_PLACEHOLDER_ID, documentTypeId: 1120 }
              ),
            ];
            break;
          case 265:
            documents = [
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.FINANCIAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 1178 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 1182 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.TAX_SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 1181 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.INTERNAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 1185 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.LETTER_OF_AGREEMENT_PLACEHOLDER_ID, documentTypeId: 1186 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.PROTOCOL_PLACEHOLDER_ID, documentTypeId: 1184 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.MANAGEMENT_LETTER_PLACEHOLDER_ID, documentTypeId: 1179 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SUMMARY_GENERAL_MEETING_PLACEHOLDER_ID, documentTypeId: 1180 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.COMPANY_TAX_RETURN_PLACEHOLDER_ID, documentTypeId: 1183 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.APPENDIX_PLACEHOLDER_ID, documentTypeId: 1189 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.AUDIT_PROTOCOL_PLACEHOLDER_ID, documentTypeId: 1184 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_D_PLACEHOLDER_ID, documentTypeId: 1186 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DT_PLACEHOLDER_ID, documentTypeId: 1187 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DR_PLACEHOLDER_ID, documentTypeId: 1188 }
              ),
            ];
            break;
          case 2375:
            documents = [
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.FINANCIAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 10980 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 10984 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.TAX_SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 10983 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.INTERNAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 11505 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.LETTER_OF_AGREEMENT_PLACEHOLDER_ID, documentTypeId: 11875 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.PROTOCOL_PLACEHOLDER_ID, documentTypeId: 10986 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.MANAGEMENT_LETTER_PLACEHOLDER_ID, documentTypeId: 10981 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SUMMARY_GENERAL_MEETING_PLACEHOLDER_ID, documentTypeId: 10982 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.COMPANY_TAX_RETURN_PLACEHOLDER_ID, documentTypeId: 10985 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.APPENDIX_PLACEHOLDER_ID, documentTypeId: 10995 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.AUDIT_PROTOCOL_PLACEHOLDER_ID, documentTypeId: 10986 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_D_PLACEHOLDER_ID, documentTypeId: 10987 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_B_PLACEHOLDER_ID, documentTypeId: 10988 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DT_PLACEHOLDER_ID, documentTypeId: 10989 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DB_PLACEHOLDER_ID, documentTypeId: 10990 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DBR_PLACEHOLDER_ID, documentTypeId: 10991 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DR_PLACEHOLDER_ID, documentTypeId: 10992 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_BR_PLACEHOLDER_ID, documentTypeId: 10993 }
              ),
            ];
            break;
          case 2468:
            documents = [
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.FINANCIAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 11506 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 11510 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.TAX_SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 11509 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.INTERNAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 11513 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.LETTER_OF_AGREEMENT_PLACEHOLDER_ID, documentTypeId: 11876 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.PROTOCOL_PLACEHOLDER_ID, documentTypeId: 11512 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.MANAGEMENT_LETTER_PLACEHOLDER_ID, documentTypeId: 11507 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SUMMARY_GENERAL_MEETING_PLACEHOLDER_ID, documentTypeId: 11508 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.COMPANY_TAX_RETURN_PLACEHOLDER_ID, documentTypeId: 11511 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.APPENDIX_PLACEHOLDER_ID, documentTypeId: 11517 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.AUDIT_PROTOCOL_PLACEHOLDER_ID, documentTypeId: 11512 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_D_PLACEHOLDER_ID, documentTypeId: 11514 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DT_PLACEHOLDER_ID, documentTypeId: 11515 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DR_PLACEHOLDER_ID, documentTypeId: 11516 }
              ),
            ];
            break;
          case 273:
            documents = [
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.FINANCIAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 1228 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 1232 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.TAX_SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 1231 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.INTERNAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 1235 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.LETTER_OF_AGREEMENT_PLACEHOLDER_ID, documentTypeId: 1239 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.PROTOCOL_PLACEHOLDER_ID, documentTypeId: 1234 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.MANAGEMENT_LETTER_PLACEHOLDER_ID, documentTypeId: 1229 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SUMMARY_GENERAL_MEETING_PLACEHOLDER_ID, documentTypeId: 1230 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.COMPANY_TAX_RETURN_PLACEHOLDER_ID, documentTypeId: 1233 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.AUDIT_PROTOCOL_PLACEHOLDER_ID, documentTypeId: 1234 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_D_PLACEHOLDER_ID, documentTypeId: 1236 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DT_PLACEHOLDER_ID, documentTypeId: 1237 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DR_PLACEHOLDER_ID, documentTypeId: 1238 }
              ),
            ];
            break;
          case 2503:
            documents = [
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.FINANCIAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 11797 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 11801 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.TAX_SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 11800 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.INTERNAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 11804 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.LETTER_OF_AGREEMENT_PLACEHOLDER_ID, documentTypeId: 11808 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.PROTOCOL_PLACEHOLDER_ID, documentTypeId: 11803 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.MANAGEMENT_LETTER_PLACEHOLDER_ID, documentTypeId: 11798 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SUMMARY_GENERAL_MEETING_PLACEHOLDER_ID, documentTypeId: 11799 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.COMPANY_TAX_RETURN_PLACEHOLDER_ID, documentTypeId: 11802 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.APPENDIX_PLACEHOLDER_ID, documentTypeId: 11809 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.AUDIT_PROTOCOL_PLACEHOLDER_ID, documentTypeId: 11803 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_D_PLACEHOLDER_ID, documentTypeId: 11805 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DT_PLACEHOLDER_ID, documentTypeId: 11806 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DR_PLACEHOLDER_ID, documentTypeId: 11807 }
              ),
            ];
            break;
          case 274:
            documents = [
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.FINANCIAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 1240 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 1244 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.TAX_SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 1243 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.INTERNAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 1254 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.LETTER_OF_AGREEMENT_PLACEHOLDER_ID, documentTypeId: 1255 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.PROTOCOL_PLACEHOLDER_ID, documentTypeId: 1246 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.MANAGEMENT_LETTER_PLACEHOLDER_ID, documentTypeId: 1241 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SUMMARY_GENERAL_MEETING_PLACEHOLDER_ID, documentTypeId: 1242 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.COMPANY_TAX_RETURN_PLACEHOLDER_ID, documentTypeId: 1245 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.AUDIT_PROTOCOL_PLACEHOLDER_ID, documentTypeId: 1246 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_D_PLACEHOLDER_ID, documentTypeId: 1247 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_B_PLACEHOLDER_ID, documentTypeId: 1248 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DT_PLACEHOLDER_ID, documentTypeId: 1249 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DB_PLACEHOLDER_ID, documentTypeId: 1250 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DBR_PLACEHOLDER_ID, documentTypeId: 1251 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DR_PLACEHOLDER_ID, documentTypeId: 1252 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_BR_PLACEHOLDER_ID, documentTypeId: 1253 }
              ),
            ];
            break;
          case 2502:
            documents = [
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.FINANCIAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 11781 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 11785 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.TAX_SPECIFICATIONS_PLACEHOLDER_ID, documentTypeId: 11784 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.INTERNAL_STATEMENT_PLACEHOLDER_ID, documentTypeId: 11795 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.LETTER_OF_AGREEMENT_PLACEHOLDER_ID, documentTypeId: 11796 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.PROTOCOL_PLACEHOLDER_ID, documentTypeId: 11787 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.MANAGEMENT_LETTER_PLACEHOLDER_ID, documentTypeId: 11782 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.SUMMARY_GENERAL_MEETING_PLACEHOLDER_ID, documentTypeId: 11783 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.COMPANY_TAX_RETURN_PLACEHOLDER_ID, documentTypeId: 11786 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.AUDIT_PROTOCOL_PLACEHOLDER_ID, documentTypeId: 11787 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_D_PLACEHOLDER_ID, documentTypeId: 11788 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_B_PLACEHOLDER_ID, documentTypeId: 11789 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DT_PLACEHOLDER_ID, documentTypeId: 11790 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DB_PLACEHOLDER_ID, documentTypeId: 11791 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DBR_PLACEHOLDER_ID, documentTypeId: 11792 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_DR_PLACEHOLDER_ID, documentTypeId: 11793 }
              ),
              new DigitalSigningModels.DigitalSigningDocumentCasewareRequest(
                { casewareDocumentId: CwWpCvDataConstantService.OTHER_BR_PLACEHOLDER_ID, documentTypeId: 11794 }
              ),
            ];
            break;
        };
        if (documents.length > 0) observable$ = forkJoin([this.getDocuments(documents)].flat(1)).pipe(map(e => e.filter(e => e)));
        break;
    }
    return observable$;
  }

  private getDocuments(documents: DigitalSigningModels.DigitalSigningDocumentCasewareRequest[]):
    Observable<ApiModels.RequestModels.DigitalSigningProviderDocumentModel>[] {
    let observable$ = documents.map(e => {
      return this.wpDataRetrieverService.getDocumentObservable(e.casewareDocumentId).pipe(
        map(document => {
          if (!document || document.type !== 'application/pdf') return null;
          return new ApiModels.RequestModels.DigitalSigningProviderDocumentModel(
            document.name,
            null,
            e.documentTypeId,
            document.base64,
          );
        }),
      );
    });
    return observable$;
  }

  private convertSignersTitleToRoleId(
    signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[],
    signerTypes: DigitalSigningModels.DigitalSigningSignerTypeModel[]
  ): ApiModels.RequestModels.DigitalSigningProviderSignerModel[] {
    if (signers === null) return signers;
    signers.forEach(function (signer) {
      if (!Array.isArray(signer.signerTypeIds)) signer.signerTypeIds = [signer.signerTypeIds];
      signer.signerTypeIds = signer.signerTypeIds.map(typeId => {
        if (typeof typeId === 'string') {
          let signerType = signerTypes.find(e => e.role === typeId);
          return signerType?.id || typeId;
        } else {
          return typeId;
        }
      });
    });
    return signers;
  }

  private mergeDuplicateSigners(signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[]):
    ApiModels.RequestModels.DigitalSigningProviderSignerModel[] {
    if (signers === null) return signers;
    var mergeSigners: ApiModels.RequestModels.DigitalSigningProviderSignerModel[] = [];

    signers.forEach(function (item) {
      var existing = mergeSigners.filter(function (signer) {
        return (signer.name == item.name && signer.email == item.email);
      });
      if (existing.length) {
        var existingIndex = mergeSigners.indexOf(existing[0]);
        mergeSigners[existingIndex].signerTypeIds = mergeSigners[existingIndex].signerTypeIds.concat(item.signerTypeIds);
      } else {
        mergeSigners.push(item);
      }
    });
    return mergeSigners;
  }

  private getSignerSuggestion(licensedServiceId: string, digitalSigningTemplateId: number):
    Observable<ApiModels.RequestModels.DigitalSigningProviderSignerModel[]> {
    let observable$: Observable<ApiModels.RequestModels.DigitalSigningProviderSignerModel[]> = of(null);

    let configSuggestionSigners = this.configService.getDigitalSigningSignersSuggestion(licensedServiceId, digitalSigningTemplateId);
    if (configSuggestionSigners) {
      if (configSuggestionSigners.length > 0) observable$ = forkJoin(this.getSigners(configSuggestionSigners).flat(1)).pipe(map(e => e.filter(e => e.name)));
      return observable$;
    }

    switch (licensedServiceId) {
      case BusinessCoreConstantService.PENNEO_LICENSED_SERVICE_ID:
        let signers = [];
        switch (digitalSigningTemplateId) {
          case 6:
            // mangement
            signers.push({
              signers: this.getManagementSignersCvRequest(),
              mapFn: (v) => { v.title = [this.convertCasewareManagementMemberTitleToRoleId(v.title)]; return v; }
            });
            // board
            signers.push({
              signers: this.getBoardSignersCvRequest(),
              mapFn: (v) => { v.title = [this.convertCasewareBoardMemberTitleToRoleId(v.title)]; return v; }
            });
            // auditors
            signers.push({
              signers: this.getAuditorsSignersCvRequest(),
              mapFn: (v) => { v.title = [this.convertCasewareAuditorTitleToRoleId(v.title)]; return v; }
            });
            // chairman
            signers.push({
              signers: this.getChairmanSignersCvRequest(),
              mapFn: (v) => { v.title = [64]; return v; }
            });
            break;
          case 256:
            // mangement
            signers.push({ signers: this.getManagementSignersCvRequest(), mapFn: (v) => { v.title = [934]; return v; } });
            // board
            signers.push(
              { signers: this.getBoardSignersCvRequest(), mapFn: (v) => { v.title = [935]; return v; } }
            );
            // auditors
            signers.push({ signers: this.getAuditorsSignersCvRequest(), mapFn: (v) => { v.title = [936]; return v; } });
            // chairman
            signers.push({ signers: this.getChairmanSignersCvRequest(), mapFn: (v) => { v.title = [937]; return v; } });
            break;
          case 265:
            // mangement
            signers.push({ signers: this.getManagementSignersCvRequest(), mapFn: (v) => { v.title = [992]; return v; } });
            // auditors
            signers.push({ signers: this.getAuditorsSignersCvRequest(), mapFn: (v) => { v.title = [993]; return v; } });
            // chairman
            signers.push({ signers: this.getChairmanSignersCvRequest(), mapFn: (v) => { v.title = [994]; return v; } });
            break;
          case 2375:
            // mangement
            signers.push({ signers: this.getManagementSignersCvRequest(), mapFn: (v) => { v.title = [10516]; return v; } });
            // board
            signers.push(
              { signers: this.getBoardSignersCvRequest(), mapFn: (v) => { v.title = [10517]; return v; } }
            );
            // auditors
            signers.push({ signers: this.getAuditorsSignersCvRequest(), mapFn: (v) => { v.title = [10518]; return v; } });
            // chairman
            signers.push({ signers: this.getChairmanSignersCvRequest(), mapFn: (v) => { v.title = [10519]; return v; } });
            break;
          case 2468:
            // mangement
            signers.push({ signers: this.getManagementSignersCvRequest(), mapFn: (v) => { v.title = [11098]; return v; } });
            // auditors
            signers.push({ signers: this.getAuditorsSignersCvRequest(), mapFn: (v) => { v.title = [11099]; return v; } });
            // chairman
            signers.push({ signers: this.getChairmanSignersCvRequest(), mapFn: (v) => { v.title = [11100]; return v; } });
            break;
          case 273:
            // mangement
            signers.push({ signers: this.getManagementSignersCvRequest(), mapFn: (v) => { v.title = [1017]; return v; } });
            // auditors
            signers.push({ signers: this.getAuditorsSignersCvRequest(), mapFn: (v, index) => { v.title = index == 1 ? [1020] : [1018]; return v; } });
            // chairman
            signers.push({ signers: this.getChairmanSignersCvRequest(), mapFn: (v) => { v.title = [1019]; return v; } });
            break;
          case 274:
            // mangement
            signers.push({ signers: this.getManagementSignersCvRequest(), mapFn: (v) => { v.title = [1022]; return v; } });
            // board
            signers.push(
              { signers: this.getBoardSignersCvRequest(), mapFn: (v) => { v.title = [1021]; return v; } }
            );
            // auditors
            signers.push({ signers: this.getAuditorsSignersCvRequest(), mapFn: (v, index) => { v.title = index == 1 ? [1025] : [1024]; return v; } });
            // chairman
            signers.push({ signers: this.getChairmanSignersCvRequest(), mapFn: (v) => { v.title = [1023]; return v; } });
            break;
          case 2503:
            // mangement
            signers.push({ signers: this.getManagementSignersCvRequest(), mapFn: (v) => { v.title = [11280]; return v; } });
            // auditors
            signers.push({ signers: this.getAuditorsSignersCvRequest(), mapFn: (v, index) => { v.title = index == 1 ? [11283] : [11281]; return v; } });
            // chairman
            signers.push({ signers: this.getChairmanSignersCvRequest(), mapFn: (v) => { v.title = [11282]; return v; } });
            break;
          case 2502:
            // mangement
            signers.push({ signers: this.getManagementSignersCvRequest(), mapFn: (v) => { v.title = [11275]; return v; } });
            // board
            signers.push(
              { signers: this.getBoardSignersCvRequest(), mapFn: (v) => { v.title = [11276]; return v; } }
            );
            // auditors
            signers.push({ signers: this.getAuditorsSignersCvRequest(), mapFn: (v, index) => { v.title = index == 1 ? [11279] : [11277]; return v; } });
            // chairman
            signers.push({ signers: this.getChairmanSignersCvRequest(), mapFn: (v) => { v.title = [11278]; return v; } });
            break;
        }
        if (signers.length > 0) observable$ = forkJoin(this.getSigners(signers).flat(1)).pipe(map(e => e.filter(e => e! && e.name)));
        break;
    }
    return observable$;
  }

  private getManagementSignersCvRequest(): CvDataPersonModel[] {
    let managementSigners = [];
    // todo : hardcoded value 10 (it's only possible to have 9 management members in CWI dk)
    for (let i = 1; i < 10; i++) { managementSigners.push(CwWpCvDataConstantService.getManagementMembersCvRequest(i)); }
    return managementSigners;
  }

  private getBoardSignersCvRequest(): CvDataPersonModel[] {
    let boardSigners = [];
    // todo : hardcoded value 16 (it's only possible to have 15 board members in CWI dk)
    for (let i = 1; i < 16; i++) { boardSigners.push(CwWpCvDataConstantService.getBoardMembersCvRequest(i)); }
    return boardSigners;
  }

  private getAuditorsSignersCvRequest(): CvDataPersonModel[] {
    let auditorsSigners = [];
    // todo : hardcoded value 3 (it's only possible to have 2 auditors in CWI dk)
    for (let i = 1; i < 3; i++) { auditorsSigners.push(CwWpCvDataConstantService.getAuditorCvRequest(i)); }
    return auditorsSigners;
  }

  private getChairmanSignersCvRequest(): CvDataPersonModel[] {
    return [CwWpCvDataConstantService.getChairmanCvRequest()];
  }

  private getSigners(signers: DigitalSigningModels.DigitalSigningSignerCasewareRequest[]):
    Observable<ApiModels.RequestModels.DigitalSigningProviderSignerModel>[][] {
    let observable$: Observable<ApiModels.RequestModels.DigitalSigningProviderSignerModel>[][];
    observable$ = signers.map(e => {
      return e.signers.map((signer: CvDataPersonModel, index: number) => this.wpDataRetrieverService.getPersonInformationObservable(signer).pipe(
        map(member => {
          if (!member) return null;
          if (e.switchTitle) member['title'] = e.switchTitle[member['title']?.toLowerCase()] || e.switchTitle['default'] || member['title'];
          if (e.mapFn) member = e.mapFn(member, index);
          return new ApiModels.RequestModels.DigitalSigningProviderSignerModel(
            member['name'],
            member['email'],
            member['title'],
            null,
          );
        })
      ));
    });
    return observable$;
  }

  private convertCasewareManagementMemberTitleToRoleId(title: string) {
    let roleId: number;
    if (!title) title = '';
    switch (title.toLowerCase()) {
      case 'adm. direktør':
        roleId = 61;
        break;
      case 'administrerende direktør':
        roleId = 61;
        break;
      case 'direktør':
        roleId = 63;
        break;
      case 'økonomichef':
        roleId = 71;
        break;
      default:
        roleId = 62;
    }
    return roleId;
  }

  private convertCasewareBoardMemberTitleToRoleId(title: string) {
    let roleId: number;
    if (!title) title = '';
    switch (title.toLowerCase()) {
      case 'formand':
        roleId = 65;
        break;
      default:
        roleId = 66;
    }
    return roleId;
  }

  private convertCasewareAuditorTitleToRoleId(title: string) {
    let roleId: number;
    if (!title) title = '';
    switch (title.toLowerCase()) {
      case 'statsautoriseret revisor':
        roleId = 68;
        break;
      case 'registreret revisor':
        roleId = 69;
        break;
      case 'godkendt revisor':
        roleId = 70;
        break;
      default:
        roleId = 67;
    }
    return roleId;
  }
}