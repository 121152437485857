<div class="digital-signing-step-signer-chooser-container">
  <div #signersWrapper class="signers-container">
    <div *ngFor="let signer of model.signers; index as i">
      <autocomplete label="Navn" inputType="text" placeholder="Hanne/Hans Hansen" [required]="showFormErrors" [value]="model.signers[i]"
        propertyOfValue="name" [autoCompleteOptions]="model.autoCompleteSigners" (valueChange)="setSigner($event, i)">
      </autocomplete>
      <mat-form-field>
        <mat-label>E-mailadresse</mat-label>
        <input matInput #email="ngModel" type="email" placeholder="navn@mail.dk" [(ngModel)]="model.signers[i].email"
          (change)="updateSigner(i)" [required]="showFormErrors"
          [email]="((email.touched && email.dirty) || this.model.signersInState[i].email)">
        <mat-error>E-mailadresse ikke valid</mat-error>
      </mat-form-field>
      <mat-select-search [autoSelectOnlyOption]="true" label="Vælg rolle" [options]="model.signersTypes" propertyOfValueToDisplay="role"
        (selectionChange)="setSignerTypeId($event, i)" [required]="showFormErrors" propertyOfValue="id"
        [value]="model.signers[i].signerTypeIds" [multiSelect]="true" [useSelectTrigger]="true">
      </mat-select-search>
      <mat-form-field class="hidden">
        <mat-label>Aktiveringsdato</mat-label>
        <input matInput type="text" [matDatepicker]="datepicker" [min]="model.tomorrow" [(ngModel)]="model.signers[i].activeAt">
      </mat-form-field>
      <mat-datepicker-toggle matSuffix [for]="datepicker" [ngClass]="model.signers[i].activeAt ? 'primary' : ''"
        [matTooltip]="formatDate(model.signers[i].activeAt)"></mat-datepicker-toggle>
      <mat-datepicker #datepicker color="accent">
        <mat-datepicker-actions>
          <div class="datepicker-action-container" style="width: 100%;">
            <div class="datepicker-action-time">
              <span>Tidspunkt:</span><time-input #timeInput></time-input>
            </div>
            <div class="datepicker-action-buttons">
              <button mat-button matDatepickerCancel (click)="removeActiveAt(i)">Fjern</button>
              <button mat-raised-button color="accent" matDatepickerApply (click)="setActiveAt(i, timeInput)">Tilføj</button>
            </div>
          </div>
        </mat-datepicker-actions>
      </mat-datepicker>
      <button mat-icon-button color="accent" (click)="addSigner(i)">
        <mat-icon fontIcon="person_add"></mat-icon>
      </button>
      <button mat-icon-button color="accent" (click)="removeSigner(i)">
        <mat-icon fontIcon="person_remove"></mat-icon>
      </button>
    </div>
  </div>
</div>