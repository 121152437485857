// Angular
import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { Subscription, defaultIfEmpty } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// Services
import { DialogService } from 'src/app/_services';
import { DigitalSigningService } from '../../../_service/digital-signing.service';
import { DigitalSigningSuggestionService } from '../../../_service/digital-signing-suggestion.service';
// State
import * as DigitalSigningModels from '../../../_models';
import * as DigitalSigningSelectors from '../../../_state/digital-signing.selectors';
import * as DigitalSigningState from '../../../_state';
// Components
import { DigitalSigningSuggestionViewComponent } from '../../suggestion-view/suggestion-view.component';

interface IViewModel {
  activeStep: number,
  activeTemplate: DigitalSigningModels.DigitalSigningTemplateInformation;
  licensedServiceId: string;
  signingTemplates: DigitalSigningModels.DigitalSigningTemplateInformation[];
}

@Component({
  selector: 'app-digital-signing-step-template-picker',
  templateUrl: './digital-signing-step-template-picker.component.html',
  styleUrls: ['./digital-signing-step-template-picker.component.scss'],
})

export class StepDigitalSigningTemplatePickerComponent implements OnInit, OnDestroy {
  @Output() stepApproved = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];

  model: IViewModel = {
    activeStep: null,
    activeTemplate: null,
    licensedServiceId: null,
    signingTemplates: null,
  };

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private digitalSigningService: DigitalSigningService,
    private suggestionService: DigitalSigningSuggestionService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getLicensedServiceMetaData).subscribe(
      licensedServiceMetaData => this.model.licensedServiceId = licensedServiceMetaData ? licensedServiceMetaData.licensedServiceId : null
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getDigitalSigningTemplates).subscribe(
      signingTemplates => {
        let templates = JSON.parse(JSON.stringify(signingTemplates));
        let order = {
          2375: 1,
          2468: 2,
          256: 1,
          265: 2,
        };
        templates.sort((a, b) => {
          return (order[a.id] || 999) - (order[b.id] || 999);
        });
        if (templates) {
          if (templates.length === 4) {
            templates.splice(2, 0, null);
          }
        }
        this.model.signingTemplates = templates ? templates : null;
      }
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getActiveStep).subscribe(
      activeStep => this.model.activeStep = activeStep
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getActiveDigitalSigningTemplate).subscribe(
      activeSigningTemplate => {
        this.model.activeTemplate = activeSigningTemplate;
        this.stepApproved.emit(activeSigningTemplate ? true : false);
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setActiveTemplate(template: DigitalSigningModels.DigitalSigningTemplateInformation): void {
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setActiveTemplateById({ templateId: template.id }));
    this.suggestionService.getSuggestion(this.model.licensedServiceId, template).pipe(defaultIfEmpty({ documents: [], signers: [] })).subscribe(
      suggestion => {
        if ((suggestion.documents && suggestion.documents.length > 0) || (suggestion.signers && suggestion.signers.length > 0)) {
          this.dialogService.openComponent(DigitalSigningSuggestionViewComponent, {
            data: { documents: suggestion.documents, signers: suggestion.signers }, panelClass: 'pad-none'
          });
        } else {
          this.store.dispatch(DigitalSigningState.DigitalSigningActions.setActiveStep({ step: this.model.activeStep + 1 }));
        }
      }
    );
  }

  getTemplateDescription(templateId: number): string {
    return this.digitalSigningService.getTemplateDescription(this.model.licensedServiceId, templateId);
  }
}
