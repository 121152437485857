// NgRx
import { createReducer, on } from '@ngrx/store';
// Other
import * as ErrorState from './';
import * as ErrorModels from '../_models';


export interface IErrorState {
    businessErrors: ErrorModels.ErrorClasses.BusinessError[],
    clientErrors: ErrorModels.ErrorInterfaces.IClientError[];
    cwWpErrors: ErrorModels.ErrorClasses.CwWpError[],
    httpErrors: ErrorModels.ErrorClasses.HttpError[],
    systemErrors: ErrorModels.ErrorClasses.SystemError[],
}

// Create initial state object
export const initialState: IErrorState = {
    businessErrors: [],
    clientErrors: [],
    cwWpErrors: [],
    httpErrors: [],
    systemErrors: [],
};

export const ErrorReducer = createReducer<IErrorState>(
    initialState,

    // ErrorActions
    on(ErrorState.ErrorActions.clearAllErrors, (state): IErrorState => {
        return {
            ...state,
            businessErrors: [],
            clientErrors: [],
            cwWpErrors: [],
            httpErrors: [],
            systemErrors: [],
        };
    }),

    on(ErrorState.ErrorActions.addBusinessErrors, (state, actionArgs): IErrorState => {
        let updatedBusinessErrors: ErrorModels.ErrorClasses.BusinessError[] = [];

        if (state.businessErrors?.length > 0) updatedBusinessErrors = [...state.businessErrors];

        actionArgs.businessErrors.forEach(error => {
            if (!updatedBusinessErrors.find(err => JSON.stringify(err) === JSON.stringify(error))) {
                updatedBusinessErrors.push(error);
            }
        });

        return {
            ...state,
            businessErrors: updatedBusinessErrors
        };
    }),

    on(ErrorState.ErrorActions.clearBusinessErrors, (state): IErrorState => {
        return {
            ...state,
            businessErrors: []
        };
    }),

    on(ErrorState.ErrorActions.addClientError, (state, actionArgs): IErrorState => {
        let updatedClientErrors: ErrorModels.ErrorInterfaces.IClientError[] = [];

        if (state.clientErrors?.length > 0) updatedClientErrors = [...state.clientErrors];

        if (!updatedClientErrors.find(err => JSON.stringify(err) === JSON.stringify(actionArgs.clientError))) {
            updatedClientErrors.push(actionArgs.clientError);
        }

        return {
            ...state,
            clientErrors: updatedClientErrors
        };
    }),

    on(ErrorState.ErrorActions.clearClientErrors, (state): IErrorState => {
        return {
            ...state,
            clientErrors: []
        };
    }),

    on(ErrorState.ErrorActions.addCwWpError, (state, actionArgs): IErrorState => {
        let updatedCwWpErrors: ErrorModels.ErrorClasses.CwWpError[] = [];

        if (state.cwWpErrors?.length > 0) updatedCwWpErrors = [...state.cwWpErrors];

        if (!updatedCwWpErrors.find(err => JSON.stringify(err) === JSON.stringify(actionArgs.cwWpError))) {
            updatedCwWpErrors.push(actionArgs.cwWpError);
        }

        return {
            ...state,
            cwWpErrors: updatedCwWpErrors
        };
    }),

    on(ErrorState.ErrorActions.clearCwWpErrors, (state): IErrorState => {
        return {
            ...state,
            cwWpErrors: []
        };
    }),

    on(ErrorState.ErrorActions.addHttpError, (state, actionArgs): IErrorState => {
        let updatedHttpErrors: ErrorModels.ErrorClasses.HttpError[] = [];

        if (state.httpErrors?.length > 0) updatedHttpErrors = [...state.httpErrors];

        if (!updatedHttpErrors.find(err => JSON.stringify(err) === JSON.stringify(actionArgs.httpError))) {
            updatedHttpErrors.push(actionArgs.httpError);
        }

        return {
            ...state,
            httpErrors: updatedHttpErrors
        };
    }),

    on(ErrorState.ErrorActions.clearHttpErrors, (state): IErrorState => {
        return {
            ...state,
            httpErrors: []
        };
    }),

    on(ErrorState.ErrorActions.addSystemError, (state, actionArgs): IErrorState => {
        let updatedSystemErrors: ErrorModels.ErrorClasses.SystemError[] = [];

        if (state.systemErrors?.length > 0) updatedSystemErrors = [...state.systemErrors];

        if (!updatedSystemErrors.find(err => JSON.stringify(err) === JSON.stringify(actionArgs.systemError))) {
            updatedSystemErrors.push(actionArgs.systemError);
        }

        return {
            ...state,
            systemErrors: updatedSystemErrors
        };
    }),

    on(ErrorState.ErrorActions.clearSystemErrors, (state): IErrorState => {
        return {
            ...state,
            systemErrors: []
        };
    }),
);