// NgRx
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Other
import { IDigitalSigningState } from './digital-signing.reducer';

// Selectors
const getDigitalSigningFeatureState = createFeatureSelector<IDigitalSigningState>('digitalSigning');

export const getDigitalSigningProviderLoadState = createSelector(
  getDigitalSigningFeatureState,
  state => state.digitalSigningProviderLoadState
);

export const getLicensedServiceMetaData = createSelector(
  getDigitalSigningFeatureState,
  state => state.licensedServiceMetaData
);

export const getDigitalSigningTemplates = createSelector(
  getDigitalSigningFeatureState,
  state => state.digitalSigningTemplates
);

export const getActiveStep = createSelector(
  getDigitalSigningFeatureState,
  state => state.activeStep
);

export const getShowErrors = createSelector(
  getDigitalSigningFeatureState,
  state => state.showErrors
);

export const getActiveDigitalSigningTemplate = createSelector(
  getDigitalSigningFeatureState,
  state => state.activeTemplate
);

export const getSigningPackageTitle = createSelector(
  getDigitalSigningFeatureState,
  state => state.signingPackageTitle
);

export const getEmailSubject = createSelector(
  getDigitalSigningFeatureState,
  state => state.emailSubject
);

export const getEmailText = createSelector(
  getDigitalSigningFeatureState,
  state => state.emailText
);

export const getSigners = createSelector(
  getDigitalSigningFeatureState,
  state => state.signers
);

export const getDocuments = createSelector(
  getDigitalSigningFeatureState,
  state => state.documents
);

export const getSigningResponse = createSelector(
  getDigitalSigningFeatureState,
  state => state.signingResponse
);

export const getCaseFiles = createSelector(
  getDigitalSigningFeatureState,
  state => state.caseFiles
);

export const isLoadingSubmission = createSelector(
  getDigitalSigningFeatureState,
  state => state.loadingSubmission,
);

export const isLoadingProvider = createSelector(
  getDigitalSigningFeatureState,
  state => state.loadingProvider,
);

export const isCaseFileLoading = createSelector(
  getDigitalSigningFeatureState,
  state => state.loadingCaseFiles,
);
