import * as AppState from 'src/app/_state/app.state';
import * as AuthActions from './_actions/auth.actions';
import * as AuthApiActions from './_actions/auth-api.actions';

export {
    AuthActions,
    AuthApiActions,
};

export interface State extends AppState.State {
}