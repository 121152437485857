// Angular
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
// rxjs
import { Subject, Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as DigitalSigningSelectors from '../../../_state/digital-signing.selectors';
import * as DigitalSigningState from '../../../_state';
// Services
import { FileService } from 'src/app/_services';
// Models
import { FileDetailsModel } from 'src/app/_models/common';
import * as ApiModels from '../../../_service/api-models';
import * as DigitalSigningModels from '../../../_models';

interface IViewModel {
  documents: ApiModels.RequestModels.DigitalSigningProviderDocumentModel[];
  documentTypes: DigitalSigningModels.DigitalSigningDocumentTypeModel[];
  numberOfPushedFiles: number;
}

@Component({
  selector: 'app-digital-signing-step-files-uploader',
  templateUrl: './digital-signing-step-files-uploader.component.html',
  styleUrls: ['./digital-signing-step-files-uploader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class StepDigitalSigningFilesUploaderComponent implements OnInit, OnDestroy {
  @Input() showFormErrors: boolean = false;
  @Output() stepApproved = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];
  removeFileInFileUploader: Subject<number> = new Subject<number>();
  resetComponent = new EventEmitter();

  model: IViewModel = {
    documents: [],
    documentTypes: [],
    numberOfPushedFiles: 0,
  };

  constructor(
    private store: Store,
    private fileService: FileService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getActiveDigitalSigningTemplate).subscribe(
      activeSigningTemplate => {
        this.model.documentTypes = activeSigningTemplate?.documentTypes;
      }
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getDocuments).subscribe(
      documents => {
        if (!documents || JSON.stringify(documents) === JSON.stringify(this.model.documents)) return;
        this.model.documents = JSON.parse(JSON.stringify(documents));
        this.model.numberOfPushedFiles = documents ? documents.length : 0;
        this.resetComponent.emit();
        if (this.model.documents) this.validate();
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  fileUpload(uploadedFile: FileDetailsModel): void {
    let document = new ApiModels.RequestModels.DigitalSigningProviderDocumentModel(
      uploadedFile.name,
      null,
      null,
      uploadedFile.base64,
    );
    this.model.documents.push(document);
    this.dispatch();
    this.validate();
  }

  fileRemove(index: number): void {
    this.model.documents.splice(index + this.model.numberOfPushedFiles, 1);
    this.dispatch();
    this.validate();
  };

  removeDocument(index: number): void {
    this.removeFileInFileUploader.next(index - this.model.numberOfPushedFiles);
    if (index - this.model.numberOfPushedFiles < 0) this.model.numberOfPushedFiles--;
    this.dispatch();
    this.validate();
  };

  setDocumentType(event: DigitalSigningModels.DigitalSigningDocumentTypeModel, index: number): void {
    let documentTypeId = event ? event.id : null;
    this.model.documents[index].documentTypeId = documentTypeId;
    this.dispatch();
    this.validate();
  }

  dispatch(): void {
    // todo : 0beed3c2-c136-461f-80a9-e9f503369af0 find another solution or different solution to deep clone
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setDocuments({ documents: JSON.parse(JSON.stringify(this.model.documents)) }));
  }

  validate(): void {
    this.stepApproved.emit(
      this.model.documents
      && this.model.documents.length > 0
      && this.model.documents.find(document => document.documentTypeId === null) === undefined
    );
  }

  openFileInNewWindow(document: ApiModels.RequestModels.DigitalSigningProviderDocumentModel): void {
    this.fileService.openBase64FileInNewWindow(document.pdfFile, document.title);
  }
}

