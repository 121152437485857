// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Angular Material
import { MaterialModule } from 'src/app/_material/material.module';
// Directives
import { IconDirective } from 'src/app/_directive/icon.directive';
import { EllipsisDirective } from 'src/app/_directive/ellipsis-tooltip.directive';
// Modules
import { SharedComponentsModule } from 'src/app/components/_shared-components/shared-components.module';
// Components
import { StepDigitalSigningFilesUploaderComponent } from './digital-signing-step-files-uploader/digital-signing-step-files-uploader.component';
import { StepDigitalSigningSignerChooser } from './digital-signing-step-signer-chooser/digital-signing-step-signer-chooser.component';
import { StepDigitalSigningEmailInfoComponent } from './digital-signing-step-email-info/digital-signing-step-email-info.component';
import { StepDigitalSigningTemplatePickerComponent } from './digital-signing-step-template-chooser/digital-signing-step-template-picker.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    IconDirective,
    EllipsisDirective,
    SharedComponentsModule,
  ],
  declarations: [
    StepDigitalSigningFilesUploaderComponent,
    StepDigitalSigningSignerChooser,
    StepDigitalSigningEmailInfoComponent,
    StepDigitalSigningTemplatePickerComponent,
  ],
  exports: [
    StepDigitalSigningFilesUploaderComponent,
    StepDigitalSigningSignerChooser,
    StepDigitalSigningEmailInfoComponent,
    StepDigitalSigningTemplatePickerComponent,
  ]
})
export class DigitalSigningStepModule { }