// NgRx
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Other
import { IUpdateState } from './update.reducer';

// Selectors
const getUpdateFeatureState = createFeatureSelector<IUpdateState>('update');

export const isCspUpdateRequired = createSelector(
  getUpdateFeatureState,
  state => state.isCspUpdateRequired
);
export const isCheckingForUpdate = createSelector(
  getUpdateFeatureState,
  state => state.checkingForUpdate
);

