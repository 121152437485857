// Angular
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeMap, timeout } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Http
import { AxiosHttpClient } from 'src/app/_http/axios-http-client';
// Services
import * as AppServices from '../../../_services';
// Models
import * as ApiModels from './api-models';
import * as CwWpModels from '../../../_models/cw-wp';
import { CwWpError } from '../../error/_models/classes';
import { DigitalSigningCaseFileModel } from '../_models';
import { DigitalSigningCaseFileDocumentModel } from '../_models';

@Injectable({
  providedIn: 'root'
})

export class DigitalSigningCaseFilesService {
  constructor(
    private errorDispatchService: AppServices.ErrorDispatchService,
    private errorService: AppServices.ErrorService,
    private httpClient: AxiosHttpClient
  ) { }

  getAllLinkedCaseFiles(): Observable<DigitalSigningCaseFileModel[]> {
    return this.getLinkedCaseFilesIds().pipe(
      timeout(environment.timeout),
      mergeMap(caseFilesId => {
        if (caseFilesId.length === 0) return of(null);
        return forkJoin(caseFilesId.map(e => this.getCaseFile(e)));
      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  getLinkedCaseFilesIds(): Observable<string[]> {
    const CV_DATA_REQUEST: CwWpModels.apiModels.ICvDataGetRequests =
      new CwWpModels.apiModels.ICvDataGetRequests([AppServices.CwWpCvDataConstantService.SIGNING_PACKAGE_IDS]);
    return this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_GET_CV_DATA, CV_DATA_REQUEST).pipe(
      timeout(environment.timeout),
      map((wpResponse: CwWpModels.apiModels.ICwWpPostResponse) => {
        if (wpResponse.data[0].error) throw wpResponse;
        return wpResponse.data[0].data.split('|').filter(e => e !== '');
      }),
      catchError(error => {
        if (error.data && error.data[0].error) {
          var cwWpError: CwWpError = new CwWpError(0, 'Kan ikke hente tilknyttede casefiles ids');
          this.errorDispatchService.dispatchCwWpErrors(cwWpError);
        }
        return throwError(() => error);
      })
    );
  }

  private getCaseFile(caseFileId: string): Observable<DigitalSigningCaseFileModel> {
    return this.httpClient.get(AppServices.BusinessCoreConstantService.ENDPOINT_DIGITAL_SIGNING_CASEFILE + '/' + caseFileId, true, true).pipe(
      timeout(environment.timeout),
      map((digitalSigningCaseFileResponse: ApiModels.ResponseModels.IDigitalSigningCaseFileResponseModel) =>
        this.mapResponseToCaseFileModel(digitalSigningCaseFileResponse.data, caseFileId)
      ),
      catchError(error => {
        if (error.errorModel?.businessErrors[0]?.errorMessage) {
          return of(this.createErrorCaseFile(Number(caseFileId), error.errorModel?.businessErrors[0]?.errorMessage, error.errorModel?.businessErrors[0]?.errorCode));
        }
        this.errorService.handleBusinessConnectErrors(error, false);
        return throwError(() => error);
      })
    );
  }

  getCaseFileDocuments(caseFile: ApiModels.ResponseModels.DigitalSigningCaseFileResponseData): Observable<CwWpModels.apiModels.ICwWpPostResponse[]> {
    return forkJoin(caseFile.documents.flatMap(doc => doc.status === 2 ? [this.getCaseFileDocument(doc)] : []));
  }

  getCaseFileDocument(document: ApiModels.ResponseModels.DigitalSigningCaseFileResponseDocument): Observable<CwWpModels.apiModels.ICwWpPostResponse> {
    return this.httpClient.get(AppServices.BusinessCoreConstantService.ENDPOINT_DIGITAL_SIGNING_DOCUMENT + '/' + document.documentId, true).pipe(
      timeout(environment.timeout),
      mergeMap((digitalSigningDocumentResponse) => {
        const FILES_STORE_REQUESTS = new CwWpModels.apiModels.IFilesStoreRequests([{
          base64: digitalSigningDocumentResponse.data.documentPdfBase64,
          name: document.title.replace('.pdf', '') + '_' + document.documentSigningKey + '.pdf',
          path: '',
          pathType: 'client',
          overrideIfExist: true,
        }]);
        return this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_STORE_FILE, FILES_STORE_REQUESTS);
      }),
      mergeMap((fileStoreResult: CwWpModels.apiModels.ICwWpPostResponse) => {
        if (fileStoreResult.data[0].error) throw fileStoreResult;
        const DOCUMENT_STORE_REQUESTS = new CwWpModels.apiModels.IDocumentStoreRequests([{
          id: String(document.documentId),
          name: document.title.replace('.pdf', ''),
          type: 5,
          folders: 'Signerede dokumenter',
          url: document.title.replace('.pdf', '') + '_' + document.documentSigningKey + '.pdf',
        }]);
        return this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_STORE_DOCUMENT, DOCUMENT_STORE_REQUESTS).pipe(
          map(documentStoreResult => {
            if (documentStoreResult.data[0].error) throw documentStoreResult;
            return documentStoreResult;
          }),
          catchError(error => {
            if (error.data && error.data[0].error) {
              var cwWpError: CwWpError = new CwWpError(0, 'Kunne ikke oprette document: ' + document.title.replace('.pdf', ''));
              this.errorDispatchService.dispatchCwWpErrors(cwWpError);
              return of(null);
            }
            return throwError(() => error);
          })
        );
      }),
      catchError(error => {
        if (error.data && error.data[0].error) {
          var cwWpError: CwWpError = new CwWpError(0, 'Kunne ikke gemme filen: ' + document.title);
          this.errorDispatchService.dispatchCwWpErrors(cwWpError);
          return of(null);
        }
        return throwError(() => error);
      })
    );
  }

  private mapResponseToCaseFileModel(digitalSigningCaseFileResponse: ApiModels.ResponseModels.DigitalSigningCaseFileResponseData, caseFileId: string): DigitalSigningCaseFileModel {
    var documents = digitalSigningCaseFileResponse.documents.map(doc => new DigitalSigningCaseFileDocumentModel(doc.title, doc.vendorStatus?.description));
    if (digitalSigningCaseFileResponse.status === 0) return this.createErrorCaseFile(Number(caseFileId), 'Kunne ikke finde forsendelse', 0);

    return new DigitalSigningCaseFileModel(
      digitalSigningCaseFileResponse.caseFileId,
      digitalSigningCaseFileResponse.caseFileUrl,
      digitalSigningCaseFileResponse.statusName,
      digitalSigningCaseFileResponse.status,
      digitalSigningCaseFileResponse.vendorStatus?.description,
      digitalSigningCaseFileResponse.title,
      digitalSigningCaseFileResponse,
      documents,
      false,
      false,
      null,
    );
  }

  private createErrorCaseFile(caseFileId: number, errorTitle: string, errorCode: number): DigitalSigningCaseFileModel {
    return new DigitalSigningCaseFileModel(
      caseFileId,
      null,
      null,
      3,
      null,
      errorTitle,
      null,
      null,
      false,
      true,
      errorCode,
    );
  }
}