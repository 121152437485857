<mat-card [ngClass]="type" *ngIf="content.length > 0">
  <mat-card-content>
    <div class="simple-message-line" *ngFor="let content of content; index as i">
      <div class="icon-container">
        <mat-icon *ngIf="i == 0" [iconType]="type"></mat-icon>
      </div>
      <div class="messages">
        <div [innerHTML]="content"></div>
        <!-- <div>{{content}}</div> -->
      </div>
      <div class="button-container">
        <button *ngIf="i == 0 && !hideRemove" mat-icon-button>
          <mat-icon iconType="close" (click)="removeContent()"></mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>