// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError, forkJoin } from 'rxjs';
import { catchError, map, } from 'rxjs/operators';

// Services
import * as AppServices from 'src/app/_services';
import { AxiosHttpClient } from 'src/app/_http/axios-http-client';

// Models
import * as CwWpModels from '../../../_models/cw-wp';
import { CwWpError } from '../../error/_models/classes';
import { ICwWpGetResponse } from 'src/app/_models/cw-wp/api-models';

@Injectable({
  providedIn: 'root'
})

export class UpdateService {
  constructor(
    private errorDispatchService: AppServices.ErrorDispatchService,
    private httpClient: AxiosHttpClient,
  ) {
    this.updateEngagementGuid();
  }

  cspFilesUpdateRequired(): Observable<boolean> {
    return this.isCspFilesOutDated();
  }

  downloadCspFilesPackage(): void {
    window.location.href = AppServices.BusinessCoreConstantService.URL_TO_CWP_PACKAGE;
  }

  private isCspFilesOutDated(): Observable<boolean> {
    return this.getVersion().pipe(
      map((localVersion: string) => {
        if (this.incorrectVersionNumberFormat(localVersion)) throw 'wrong_format';
        return localVersion.localeCompare(AppServices.CwWpConstantService.CSP_VERSION) < 0;
      }),
      catchError(error => {
        if (error === 'wrong_format') {
          var cwWpError: CwWpError = new CwWpError(0, AppServices.CwWpErrorConstantService.VersionWrongFormat);
          this.errorDispatchService.dispatchCwWpErrors(cwWpError);
        }
        return throwError(() => error);
      })
    );
  }

  private getVersion(): Observable<string> {
    return this.httpClient.get(AppServices.CwWpConstantService.ENDPOINT_CSP_VERSION_FILE).pipe(
      map((cwResponse: ICwWpGetResponse) => {
        return cwResponse.data;
      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  private incorrectVersionNumberFormat(versionNumber: any) {
    if (typeof versionNumber !== 'string') return true;
    let array = versionNumber.split('.').flatMap(e => e !== '' ? Number(e) : []).filter(e => !Number.isNaN(e));
    if (array.length !== 3) return true;
    return false;
  }

  private getActualEngagementGUID(): Observable<string> {
    const CLIENT_REQUEST: CwWpModels.apiModels.IClientGetRequests = new CwWpModels.apiModels.IClientGetRequests([
      { property: 'EngagementGUID', subProperty: null }
    ]);
    return this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_GET_CLIENT_DATA, CLIENT_REQUEST).pipe(
      map((cwResponse: CwWpModels.apiModels.ICwWpPostResponse) => {
        if (cwResponse.error || cwResponse.data[0].error) return null;
        return cwResponse.data[0].data;
      }),
      catchError(error => {
        throwError(() => error);
        return null;
      })
    );
  }

  private getStoredEngagementGUID(): Observable<string> {
    const CV_DATA_REQUEST: CwWpModels.apiModels.ICvDataGetRequests =
      new CwWpModels.apiModels.ICvDataGetRequests([AppServices.CwWpCvDataConstantService.ENGAGEMENT_GUID]);
    return this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_GET_CV_DATA, CV_DATA_REQUEST).pipe(
      map((cwResponse: CwWpModels.apiModels.ICwWpPostResponse) => {
        if (cwResponse.error || cwResponse.data[0].error || cwResponse.data[0].data === undefined) return null;
        return cwResponse.data[0].data;
      }),
      catchError(error => {
        throwError(() => error);
        return null;
      })
    );
  }

  private updateEngagementGuid(): void {
    forkJoin([this.getActualEngagementGUID(), this.getStoredEngagementGUID()]).subscribe((guidArray) => {
      if (guidArray[0] !== guidArray[1]) {
        this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_STORE_CV_DATA,
          { requests: [{ ...AppServices.CwWpCvDataConstantService.ENGAGEMENT_GUID, ...{ value: guidArray[0] } }] }).subscribe();
        this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_STORE_CV_DATA,
          { requests: [{ ...AppServices.CwWpCvDataConstantService.SIGNING_PACKAGE_IDS, ...{ value: '' } }] }).subscribe();
      }
    }
    );
  }
};