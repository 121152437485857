<div class="erst-submission-step-certifikat-container">
  <simple-message *ngIf="model.certificateFromBc && !model.certificateFile" [content]="model.certificateFromBcInfo"
    [hideRemove]="true"></simple-message>
  <simple-message *ngIf="model.certificateNotUploadedInBusinessConnect"
    [content]="['Du har ikke gemt et certifikat i Business Connect']"></simple-message>
  <div class="file-uploader-container">
    <file-uploader instructions="Drop certifikatet her eller klik for at vælge et certifikat" [acceptedTypes]="['application/x-pkcs12']"
      [multipleSelection]="false" [showFilesUnder]="false" [removeFileIndex]="removeFileInFileUploader.asObservable()"
      (fileUpload)="fileUpload($event)" (fileRemove)="fileRemove()">
    </file-uploader>
  </div>
  <div class="uploaded-file" *ngIf="model.certificateFile">
    <mat-icon iconType="file" class="file-icon"></mat-icon>
    <span class="file-name" EllipsisToolTip [matTooltip]="model.certificateFile.name"
      matTooltipDisabled="true">{{model.certificateFile.name}}</span>
    <mat-form-field>
      <input matInput #password="ngModel" type="password" [type]="model.hidePassword ? 'password' : 'text'"
        placeholder="Adgangskode for certifikat:" [(ngModel)]="model.certificatePassword" (change)="updatePassword()"
        [required]="showFormErrors">
      <button mat-icon-button matSuffix (click)="model.hidePassword = !model.hidePassword"
        [matTooltip]="model.hidePassword ? 'Vis adgangskode' : 'Skjul adgangskode'" matTooltipPosition="above">
        <mat-icon>{{ model.hidePassword ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>
    <span class="file-remove">
      <button mat-icon-button (click)="removeFile()">
        <mat-icon iconType="remove"></mat-icon>
      </button>
    </span>
    <div class="storeCertificate" align="end" *ngIf="model.certificateFile">
      <mat-checkbox labelPosition="before" [(ngModel)]="model.storeCertificate" (change)="storeCertificate()">
        Gem certifikat
      </mat-checkbox>
    </div>
  </div>
</div>