import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

// Settings import
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

// Expansions
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const modules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSelectModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  NgxMatSelectSearchModule,
];

// Default settings
const MatDialogDefaults: MatDialogConfig = {
  disableClose: true,
  enterAnimationDuration: '250ms',
  exitAnimationDuration: '250ms',
  hasBackdrop: true,
};

const MatFormFieldDefault: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  color: 'accent',
  hideRequiredMarker: true,
};

const TooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 250,
  hideDelay: 250,
  touchendHideDelay: 1500,
  position: 'below',
};

@NgModule({
  imports: modules,
  exports: modules,
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: MatDialogDefaults },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MatFormFieldDefault },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipDefaults },
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' }
  ]
})

export class MaterialModule { }