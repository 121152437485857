// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// Angular Material
import { MaterialModule } from '../../_material/material.module';
// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Components
import { ErrorModule } from '../error/error.module';
import { ErstSubmissionComponent } from './erst-submission/erst-submission.component';
import { ErhvervsstyrelsenStepModule } from './erst-submission/steps/erst-submission-steps.module';
// Directives
import { SpinnerDirective } from 'src/app/_directive/spinner.directive';
// Other
import { ErstEffects } from './_state/erst.effects';
import { ErstReducer } from './_state/erst.reducer';

@NgModule({
  imports: [
    BrowserModule,
    ErrorModule,
    ErhvervsstyrelsenStepModule,
    MaterialModule,
    SpinnerDirective,
    StoreModule.forFeature('erst', ErstReducer),
    EffectsModule.forFeature([ErstEffects]),
  ],
  declarations: [
    ErstSubmissionComponent,
  ],
  exports: [
    ErstSubmissionComponent,
  ]
})
export class ErhvervsstyrelsenModule { }