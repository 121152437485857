// Angular modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Angular Material
import { MaterialModule } from '../../_material/material.module';
// Directives
import { IconDirective } from 'src/app/_directive/icon.directive';
// Component
import { ErrorPanelComponent } from './error-panel-component/error-panel.component';
// Other
import { ErrorReducer } from './_state/error.reducer';

@NgModule({
  imports: [
    BrowserModule,
    MaterialModule,
    IconDirective,
    StoreModule.forFeature('error', ErrorReducer),
  ],
  declarations: [
    ErrorPanelComponent,
  ],
  exports: [
    ErrorPanelComponent
  ]
})
export class ErrorModule { }