// Angular
import { Component, EventEmitter, OnDestroy, OnInit, Input, Output, ViewChildren, ViewEncapsulation, QueryList } from '@angular/core';
import { NgModel } from '@angular/forms';
// rxjs
import { Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// Services
import { DigitalSigningSuggestionService } from '../../../_service/digital-signing-suggestion.service';
// models
import * as ApiModels from '../../../_service/api-models';
import * as DigitalSigningModels from '../../../_models';
import * as DigitalSigningSelectors from '../../../_state/digital-signing.selectors';
import * as DigitalSigningState from '../../../_state';
import { TimeInputComponent } from 'src/app/components/_shared-components/time-input/time-input.component';

interface IViewModel {
  signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[];
  signersTypes: DigitalSigningModels.DigitalSigningSignerTypeModel[];
  signersInState: ApiModels.RequestModels.DigitalSigningProviderSignerModel[];
  tomorrow: Date;
  autoCompleteSigners: ApiModels.RequestModels.DigitalSigningProviderSignerModel[];
}

@Component({
  selector: 'app-digital-signing-step-signer-chooser',
  templateUrl: './digital-signing-step-signer-chooser.component.html',
  styleUrls: ['./digital-signing-step-signer-chooser.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class StepDigitalSigningSignerChooser implements OnInit, OnDestroy {
  @Input() showFormErrors: boolean = false;
  @Output() stepApproved = new EventEmitter<boolean>();
  @ViewChildren('email') emails: QueryList<NgModel>;

  subscriptions: Subscription[] = [];

  model: IViewModel = {
    signers: [],
    signersTypes: null,
    signersInState: null,
    tomorrow: this.setTomorrow(),
    autoCompleteSigners: [],
  };

  constructor(
    private store: Store,
    private suggestionService: DigitalSigningSuggestionService,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getActiveDigitalSigningTemplate).subscribe(
      activeSigningTemplate => {
        this.model.signersTypes = activeSigningTemplate?.signerTypes;
      }
    ));

    this.subscriptions.push(this.suggestionService.getAllSignerSuggestions().pipe().subscribe(
      suggestion => { this.model.autoCompleteSigners = suggestion; }
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getSigners).subscribe(
      signers => {
        if (!signers || signers?.length === 0) {
          this.model.signers = [];
          this.addSigner(0);
        } else {
          this.model.signersInState = signers;
          this.model.signers = this.model.signers.slice(0, signers.length);
          for (let i = 0; i < this.model.signersInState.length; i++) {
            // todo : 0beed3c2-c136-461f-80a9-e9f503369af0 find another solution or different solution to deep clone
            if (JSON.stringify(this.model.signersInState[i]) !== JSON.stringify(this.model.signers[i])) {
              this.model.signers[i] = JSON.parse(JSON.stringify(signers[i]));
            }
          }
        }
        setTimeout(() => this.validate(), 0);
      }
    ));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public addSigner(index: number): void {
    this.model.signers.splice(index, 1);
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.addSigner(
      { signer: new ApiModels.RequestModels.DigitalSigningProviderSignerModel(null, null, null, null), index: index + 1 }
    ));
  }

  public removeSigner(index: number): void {
    this.model.signers.splice(index, 1);
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.removeSigner(
      { index: index }
    ));
  };

  public setSigner(signer: ApiModels.RequestModels.DigitalSigningProviderSignerModel, index: number): void {
    // todo : 0beed3c2-c136-461f-80a9-e9f503369af0 find another solution or different solution to deep clone
    // using name and email separately, or else the hole line will refresh and tab to next input wont work
    this.model.signers[index].name = JSON.parse(JSON.stringify(signer.name));
    this.model.signers[index].email = JSON.parse(JSON.stringify(signer.email));
    this.updateSigner(index);
  }

  public updateSigner(index: number): void {
    if (JSON.stringify(this.model.signersInState[index]) === JSON.stringify(this.model.signers[index])) return;
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.updateSigner(
      // todo : 0beed3c2-c136-461f-80a9-e9f503369af0 find another solution or different solution to deep clone
      { updatedSigner: JSON.parse(JSON.stringify(this.model.signers[index])), index: index }
    ));
  }

  public setSignerTypeId(event: DigitalSigningModels.DigitalSigningSignerTypeModel[], index: number): void {
    var value = event && event.length > 0 ? event.map(e => e.id) : null;
    this.model.signers[index].signerTypeIds = value;
    this.updateSigner(index);
  }

  public formatDate(date: Date): string {
    if (!date) return null;
    if (typeof date === 'string') date = new Date(date);
    return new Intl.DateTimeFormat('da-DK', { dateStyle: 'long', timeStyle: 'short' }).format(date);
  }

  public setActiveAt(index: number, timeInput: TimeInputComponent): void {
    if (this.model.signers[index].activeAt) this.model.signers[index].activeAt.setHours(Number(timeInput.hours.value), Number(timeInput.minutes.value));
    this.updateSigner(index);
  }

  public removeActiveAt(index: number): void {
    this.model.signers[index].activeAt = null;
  }

  private validate(): void {
    if (this.emails && this.emails.find(email => !email.valid)) {
      this.stepApproved.emit(false);
      return;
    }
    this.stepApproved.emit(this.model.signers.find(signer => !signer.name || !signer.email || !signer.signerTypeIds) === undefined);
  }

  private setTomorrow(): Date {
    let tomorrow = new Date();
    tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
    return tomorrow;
  }
}

