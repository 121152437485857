// Angular
import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as ErstSelectors from '../_state/erst.selectors';
import * as ErstState from '../_state';
import * as ErrorState from '../../error/_state';
import * as LayoutState from '../../layout/_state';
//  Services
import { DialogService } from 'src/app/_services';
// Models
import * as CommonModels from '../../../_models/common';
import * as ErstModels from "../_models";
import { SimpleDialogDataModel } from '../../_shared-components/_models';

interface IViewModel {
  isAuthenticated: any,
  certificateBase64: string;
  certificatePassword: string;
  storeCertificate: boolean;
  ixbrlBase64: string;
  pdfBase64: string;
  xbrlBase64: string;
  xbrlRevenueBase64: string;
  isIxbrl: boolean;
  erstSubmitResponse: ErstModels.Interfaces.ISubmitDisplayResponseModel;
  isLoading: boolean;
  isLoadingCertificateFromBusinessConnect: boolean;
  isLoadingSuggestedDocuments: boolean;
  activeStep: number;
  numberOfSteps: number;
  steps: CommonModels.Step[];
  showFormErrors: boolean;
}

@Component({
  selector: 'app-erst-submission',
  templateUrl: './erst-submission.component.html',
  styleUrls: ['./erst-submission.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: false,
        showError: true,
      },
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ErstSubmissionComponent implements OnInit, OnDestroy {

  model: IViewModel = {
    isAuthenticated: null,
    certificateBase64: null,
    certificatePassword: null,
    storeCertificate: null,
    ixbrlBase64: null,
    pdfBase64: null,
    xbrlBase64: null,
    xbrlRevenueBase64: null,
    isIxbrl: false,
    erstSubmitResponse: null,
    isLoading: false,
    isLoadingCertificateFromBusinessConnect: false,
    isLoadingSuggestedDocuments: false,
    activeStep: 0,
    numberOfSteps: 0,
    steps: [
      { name: 'certificateUploader', label: 'Vælg certifikat', completed: false, icon: 'workspace_premium' },
      { name: 'filesUploader', label: 'Vælg filer', completed: false, icon: 'attach_file' },
      { name: 'submitReview', label: 'Indsend', completed: false, icon: 'send' },
    ],
    showFormErrors: false,
  };

  subscriptions: Subscription[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private store: Store,
  ) { }

  public ngOnInit(): void {
    this.model.numberOfSteps = this.model.steps.length;

    this.store.dispatch(LayoutState.LayoutActions.setNavigationRoutes(
      {
        routes: []
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.isLoading).subscribe(
      loading => this.model.isLoading = loading
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.isLoadingCertificateFromBusinessConnect).subscribe(
      loadingCertificateFromBusinessConnect => this.model.isLoadingCertificateFromBusinessConnect = loadingCertificateFromBusinessConnect
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.isLoadingSuggestedDocuments).subscribe(
      loadingDocuments => this.model.isLoadingSuggestedDocuments = loadingDocuments
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getActiveStep).subscribe(
      activeStep => this.model.activeStep = activeStep
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstCertificateBase64).subscribe(
      erstCertificateBase64 => this.model.certificateBase64 = erstCertificateBase64
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstCertificatePassword).subscribe(
      erstCertificatePassword => this.model.certificatePassword = erstCertificatePassword
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getStoreCertificate).subscribe(
      storeCertificate => this.model.storeCertificate = storeCertificate
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstIxbrlBase64).subscribe(
      erstIxbrlBase64 => this.model.ixbrlBase64 = erstIxbrlBase64
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstPdfBase64).subscribe(
      erstPdfBase64 => this.model.pdfBase64 = erstPdfBase64
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstXbrlBase64).subscribe(
      erstXbrlBase64 => this.model.xbrlBase64 = erstXbrlBase64
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstXbrlRevenueBase64).subscribe(
      erstXbrlRevenueBase64 => this.model.xbrlRevenueBase64 = erstXbrlRevenueBase64
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.isIxbrl).subscribe(
      isIxbrl => this.model.isIxbrl = isIxbrl
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstSubmitResponse).subscribe(
      erstSubmitResponse => {
        this.model.erstSubmitResponse = erstSubmitResponse;
        if (erstSubmitResponse) {
          let content = erstSubmitResponse.message.map(e => `<div>${e}</div>`).join('');
          this.dialogService.openSimpleDialog(
            new SimpleDialogDataModel(content, erstSubmitResponse.buttonLabel, erstSubmitResponse.statusMessage, erstSubmitResponse.status)
          ).afterClosed().subscribe((submitWithToken) => {
            this.store.dispatch(ErstState.ErstActions.clearSubmitResponse());
            if (erstSubmitResponse.status === 'succes' || erstSubmitResponse.status === 'info')
              this.store.dispatch(ErstState.ErstActions.clearStateAfterSubmit());
            if (erstSubmitResponse.token && submitWithToken) {
              this.submit();
            } else {
              this.store.dispatch(ErstState.ErstActions.setXbrlToken({ token: null }));
            }
          });
        }
      }
    ));

    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(ErstState.ErstActions.clearState());
  };

  public navigatePrevious(): void {
    this.navigateToStep(this.model.activeStep - 1);
  }

  public navigateNext(): void {
    this.navigateToStep(this.model.activeStep + 1);
  }

  public navigateToStep(step: number): void {
    if (step !== this.model.activeStep) this.store.dispatch(ErstState.ErstActions.setActiveStep({ step: step }));
  }

  public stepApproved(bool: boolean, index: number): void {
    setTimeout(() => this.model.steps[index].completed = bool);
  }

  public submit(): void {
    this.store.dispatch(ErrorState.ErrorActions.clearAllErrors());
    if (this.model.isIxbrl) {
      this.store.dispatch(ErstState.ErstActions.submitIxbrl({ onlyTest: false }));
    } else {
      this.store.dispatch(ErstState.ErstActions.submitXbrl({ onlyTest: false }));
    }
  }
}
