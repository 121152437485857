// NgRx
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { ILayoutState } from './layout.reducer';

// Selectors
const getLayoutFeatureState = createFeatureSelector<ILayoutState>('layout');

export const getNavigationRoutes = createSelector(
  getLayoutFeatureState,
  state => state.navigationRoutes
);
