// Angular
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
// NgRx
import { Store } from '@ngrx/store';
// Services
import { BusinessCoreRouteConstantService } from './../_services/business-core-route-constant.service';
// State
import * as AuthModels from '../components/authentication/_models';
import * as AuthSelectors from '../components/authentication/_state/auth.selectors';
import * as AuthState from '../components/authentication/_state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  authUser: AuthModels.AuthenticationUser = null;

  constructor(
    private router: Router,
    private store: Store<AuthState.State>,
  ) {
    this.store.select(AuthSelectors.getAuthUser).subscribe(
      authUser => {
        this.authUser = authUser;
      }
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Routes not requiring authorization
    if (state.url.substring(1, BusinessCoreRouteConstantService.LOGIN.length + 1) === BusinessCoreRouteConstantService.LOGIN) return true;

    // logged in
    if (this.authUser) return true;

    // Not logged in
    this.router.navigate([BusinessCoreRouteConstantService.LOGIN], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
