// Angular
import { Injectable } from '@angular/core';
// Environment
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class BusinessCoreConstantService {
  public static readonly LOCALHOST = window.location.host.indexOf('localhost') > -1;
  public static readonly BUSINESS_CONNECT_WP_CLIENT_UID = 'c430daca-e9b2-45cf-9aa2-4a49735afede';
  public static readonly PENNEO_LICENSED_SERVICE_ID = '175f4878-91b2-4660-bea7-15c0dde61604';

  private static readonly HOST = environment.host;
  private static readonly URL_API = '/api';

  private static readonly URL_APP = environment.url;

  private static readonly URL_API_KEY_TOKEN = '/auth/apiKeyToken';
  private static readonly URL_CERTIFICATE = '/certificate';
  private static readonly URL_COMPANY = '/company';
  private static readonly URL_COMPANY_CALCULATED_SOURCE = '/company/CalculatedSource';
  private static readonly URL_CONTACTPERSON = '/contactperson';
  private static readonly URL_CONTACTPERSON_CALCULATED_SOURCE = '/contactperson/CalculatedSource';
  private static readonly URL_DIGITAL_SIGNING = '/digitalSigning';
  private static readonly URL_DIGITAL_SIGNING_CASEFILE = '/digitalSigning/casefile';
  private static readonly URL_DIGITAL_SIGNING_DOCUMENT = '/digitalSigning/document';
  private static readonly URL_DIGITAL_SIGNING_TEMPLATE = '/digitalSigning/template';
  private static readonly URL_DIGITAL_SIGNING_TEMPLATE_EMAIL = '/digitalSigning/emailValues';
  private static readonly URL_ERRORCODES = '/system/errorcodes';
  private static readonly URL_INDSEND_IXBRL = '/es/indsendIXbrl';
  private static readonly URL_INDSEND_XBRL = '/es/indsendXbrl';
  private static readonly URL_LICENSED_SERVICES = '/license/getLicensedServices';
  private static readonly URL_USER_CREDENTIALS_TOKEN = '/auth/userCredentialsToken';
  private static readonly URL_VALIDATE_CREDENTIALS = '/providerCredentials/validateCredentials';

  public static readonly ENDPOINT_API_KEY_TOKEN =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_API_KEY_TOKEN;
  public static readonly ENDPOINT_CERTIFICATE =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_CERTIFICATE;
  public static readonly ENDPOINT_COMPANY = BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_COMPANY;
  public static readonly ENDPOINT_COMPANY_CALCULATED_SOURCE =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_COMPANY_CALCULATED_SOURCE;
  public static readonly ENDPOINT_COMPANY_CONTACTPERSON =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_CONTACTPERSON;
  public static readonly ENDPOINT_CONTACTPERSON_CALCULATED_SOURCE =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_CONTACTPERSON_CALCULATED_SOURCE;
  public static readonly ENDPOINT_DIGITAL_SIGNING =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_DIGITAL_SIGNING;
  public static readonly ENDPOINT_DIGITAL_SIGNING_CASEFILE =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_DIGITAL_SIGNING_CASEFILE;
  public static readonly ENDPOINT_DIGITAL_SIGNING_DOCUMENT =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_DIGITAL_SIGNING_DOCUMENT;
  public static readonly ENDPOINT_DIGITAL_SIGNING_TEMPLATE =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_DIGITAL_SIGNING_TEMPLATE;
  public static readonly ENDPOINT_DIGITAL_SIGNING_TEMPLATE_EMAIL =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_DIGITAL_SIGNING_TEMPLATE_EMAIL;
  public static readonly ENDPOINT_ERRORCODES =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_ERRORCODES;
  public static readonly ENDPOINT_INDSEND_IXBRL =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_INDSEND_IXBRL;
  public static readonly ENDPOINT_INDSEND_XBRL =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_INDSEND_XBRL;
  public static readonly ENDPOINT_LICENSED_SERVICES =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_LICENSED_SERVICES;
  public static readonly ENDPOINT_USER_CREDENTIALS_TOKEN =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_USER_CREDENTIALS_TOKEN;
  public static readonly ENDPOINT_VALIDATE_CREDENTIALS =
    BusinessCoreConstantService.HOST + BusinessCoreConstantService.URL_API + BusinessCoreConstantService.URL_VALIDATE_CREDENTIALS;

  public static readonly URL_TO_APP_SETTINGS_FILE =
    BusinessCoreConstantService.LOCALHOST ? '../../assets/data/appsettings.json' : BusinessCoreConstantService.URL_APP + '/assets/data/appsettings.json';
  public static readonly URL_TO_CWP_PACKAGE =
    BusinessCoreConstantService.LOCALHOST ? '../../assets/cwp/bcwp.cwp' : BusinessCoreConstantService.URL_APP + '/assets/cwp/bcwp.cwp';
}
