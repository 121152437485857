<div class="digital-signing-submit-container" *ngIf="model.licensedServiceMetaData"
  [ngClass]="{'disabled-container': model.loadingProvider || model.loadingSubmission}">

  <div class="stepper-container">
    <mat-stepper labelPosition="bottom" #stepper [selectedIndex]="model.activeStep"
      (selectionChange)="navigateToStep($event.selectedIndex)">

      <ng-template matStepperIcon="edit" let-index="index">
        <mat-icon [style]="model.steps[index].iconStyle">{{model.steps[index].icon}}</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="error" let-index="index">
        <mat-icon [style]="model.steps[index].iconStyle">{{model.steps[index].icon}}</mat-icon>
      </ng-template>

      <ng-template [matStepperIcon]="model.steps[0].name">
        <mat-icon [style]="model.steps[0].iconStyle">{{model.steps[0].icon}}</mat-icon>
      </ng-template>
      <mat-step [state]="model.steps[0].name" [label]="this.model.steps[0].label" [completed]="this.model.steps[0].completed"
        [hasError]="model.showFormErrors && !this.model.steps[0].completed" errorMessage="Området er ikke udfyldt korrekt">
        <app-digital-signing-step-template-picker (stepApproved)="stepApproved($event, 0)">
        </app-digital-signing-step-template-picker>
      </mat-step>

      <ng-template [matStepperIcon]="model.steps[1].name">
        <mat-icon [style]="model.steps[1].iconStyle">{{model.steps[1].icon}}</mat-icon>
      </ng-template>
      <mat-step [state]="model.steps[1].name" [label]="this.model.steps[1].label" [completed]="this.model.steps[1].completed"
        [hasError]="model.showFormErrors && !this.model.steps[1].completed" errorMessage="Området er ikke udfyldt korrekt">
        <app-digital-signing-step-files-uploader [showFormErrors]="model.showFormErrors" (stepApproved)="stepApproved($event, 1)">
        </app-digital-signing-step-files-uploader>
      </mat-step>

      <ng-template [matStepperIcon]="model.steps[2].name">
        <mat-icon [style]="model.steps[2].iconStyle">{{model.steps[2].icon}}</mat-icon>
      </ng-template>
      <mat-step [state]="model.steps[2].name" [label]="this.model.steps[2].label" [completed]="this.model.steps[2].completed"
        [hasError]="model.showFormErrors && !this.model.steps[2].completed" errorMessage="Området er ikke udfyldt korrekt">
        <app-digital-signing-step-signer-chooser [showFormErrors]="model.showFormErrors" (stepApproved)="stepApproved($event, 2)">
        </app-digital-signing-step-signer-chooser>
      </mat-step>

      <ng-template [matStepperIcon]="model.steps[3].name">
        <mat-icon [style]="model.steps[3].iconStyle">{{model.steps[3].icon}}</mat-icon>
      </ng-template>
      <mat-step [state]="model.steps[3].name" [label]="this.model.steps[3].label" [completed]="this.model.steps[3].completed"
        [hasError]="model.showFormErrors && !this.model.steps[3].completed" errorMessage="Området er ikke udfyldt korrekt">
        <app-digital-signing-step-email-info [showFormErrors]="model.showFormErrors" (stepApproved)="stepApproved($event, 3)"
          [stepActivated]="model.steps[3].stepActivated.asObservable()">
        </app-digital-signing-step-email-info>
      </mat-step>

    </mat-stepper>
  </div>

  <div class="navigate-container">
    <span class="previousStep">
      <button mat-raised-button color="accent" *ngIf="model.activeStep > 0" (click)="navigatePrevious()">
        <mat-icon fontIcon="navigate_before"></mat-icon>
        Forrige
      </button>
    </span>
    <span class="nextStep">
      <button mat-raised-button color="accent" *ngIf="model.activeStep < model.numberOfSteps - 1" (click)="navigateNext()">
        Næste
        <mat-icon fontIcon="navigate_next"></mat-icon>
      </button>
      <button mat-raised-button color="accent" *ngIf="model.activeStep === model.numberOfSteps-1" (click)="submit(2)">
        Send til udkast
        <mat-icon class="icon-sm" fontIcon="send_and_archive"></mat-icon>
      </button>
      <button mat-raised-button color="primary" *ngIf="model.activeStep === model.numberOfSteps-1" (click)="submit(1)">
        Send til underskrift
        <mat-icon class="icon-sm" fontIcon="send"></mat-icon>
      </button>
    </span>
  </div>
</div>
<mat-spinner spinnerPosition="center" *ngIf="model.loadingProvider || model.loadingSubmission"></mat-spinner>