// Angular
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export abstract class BusinessCoreRouteConstantService {
    public static readonly COMPANY = 'company';
    public static readonly CONTACTPERSON = 'contacts';
    public static readonly DASHBOARD = 'dashboard';
    public static readonly ERST_SUBMISSION = 'erst-submit';
    public static readonly LOGIN = 'login';
    public static readonly SIGNING_SUBMISSION = 'signing-submit';
    public static readonly SIGNING_RETRIEVE = 'signing-retrieve';
}