<div class="suggestion-view-container">
  <mat-toolbar class="cwi-mat-primary">
    <span>Foreslåede dokumenter og underskrivere</span>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dontAddAndClose(1)" matTooltip="Luk" matTooltipPosition="below">
      <mat-icon iconType="close"></mat-icon>
    </button>
  </mat-toolbar>
  <div class="content">
    <div class="text-container">Caseware har fundet følgende dokument(er) og underskriver(e) til det valgte underskriftsflow.
    </div>
    <mat-divider></mat-divider>
    <mat-grid-list cols="2" rowHeight="480px">
      <mat-grid-tile>
        <span class="mat-subheading-1">
          <mat-icon fontIcon="attach_file"></mat-icon>Dokumenter
        </span>
        <div *ngFor="let document of dialogData.documents; index as i" class="content-line">
          <mat-icon iconType="warning" class="icon-text warning" *ngIf="!document.documentTypeId || !document.title"></mat-icon>
          {{getDocumentNameFromId(document.documentTypeId) || 'Typen er ikke angivet'}}: {{document.title}}
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <span class="mat-subheading-1">
          <mat-icon fontIcon="group"></mat-icon> Underskrivere
        </span>
        <div *ngFor="let signer of dialogData.signers; index as i" class="content-line">
          <mat-icon iconType="warning" class="icon-text warning" *ngIf="!signer.signerTypeIds || !signer.name || !signer.email"></mat-icon>
          <span>
            {{getSignerRolesFromIds(signer.signerTypeIds) || 'Rollen er ikke angivet'}}:
            {{signer.name || 'Der er ikke fundet et navn'}},
            {{signer.email || 'Email mangler, indtast i vælg underskrivere'}}
          </span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div class="button-container">
    <span class="edit">
      <button mat-raised-button color="accent" (click)="addAndClose(1)">
        Rediger
      </button>
    </span>
    <span class="accept">
      <button mat-raised-button color="accent" (click)="addAndClose(3)">
        Godkend og gå til send
      </button>
    </span>
  </div>
</div>