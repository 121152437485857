<div class="error-panel-container" *ngIf="model.errorPanelVisible">

  <div class="client-errors-container" *ngIf="model.clientErrors?.length > 0">
    <mat-card class="error-content-box">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon class="icon-lg" iconType="error"></mat-icon>
        </div>
        <mat-card-title>Fejl</mat-card-title>
        <button mat-icon-button (click)="clearClientErrors()">
          <mat-icon iconType="close"></mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <div *ngFor="let clientError of model.clientErrors">
          <div><b>Klient-fejl navn:&nbsp;</b>{{clientError.name}}</div>
          <div><b>Klient-fejl besked:&nbsp;</b>{{clientError.message}}</div>
          <div *ngIf="clientError.stack"><b>Klient-fejl stack:&nbsp;</b>{{clientError.stack}}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="cwwp-errors-container" *ngIf="model.cwWpErrors?.length > 0">
    <mat-card class="error-content-box">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon class="icon-lg" iconType="error"></mat-icon>
        </div>
        <mat-card-title>Fejl i kommunikation med Caseware</mat-card-title>
        <button mat-icon-button (click)="clearCwWpErrors()">
          <mat-icon iconType="close"></mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <ul *ngFor="let cwWpError of model.cwWpErrors">
          <li>{{cwWpError.errorMessage}}</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="http-errors-container" *ngIf="model.httpErrors?.length > 0">
    <mat-card class="error-content-box">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon class="icon-lg" iconType="error"></mat-icon>
        </div>
        <mat-card-title>Fejl</mat-card-title>
        <button mat-icon-button (click)="clearHttpErrors()">
          <mat-icon iconType="close"></mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <ul *ngFor="let httpError of model.httpErrors">
          <li>{{httpError.errorMessage}}</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="system-errors-container" *ngIf="model.systemErrors?.length > 0">
    <mat-card class="error-content-box">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon class="icon-lg" iconType="error"></mat-icon>
        </div>
        <mat-card-title>Fejl</mat-card-title>
        <button mat-icon-button (click)="clearSystemErrors()">
          <mat-icon iconType="close"></mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <ul *ngFor="let systemError of model.systemErrors">
          <li>{{systemError.message}}</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="business-errors-container" *ngIf="model.businessErrors?.length > 0">
    <mat-card class="warning-content-box">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon class="icon-lg" iconType="warning"></mat-icon>
        </div>
        <mat-card-title>Advarsel</mat-card-title>
        <button mat-icon-button (click)="clearBusinessErrors()">
          <mat-icon iconType="close"></mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <ul *ngFor="let businessError of model.businessErrors">
          <li>{{businessError.errorMessage}}</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</div>