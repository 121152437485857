// NgRx
import { createAction, props } from '@ngrx/store';
// Other
import * as ApiModels from '../../_service/api-models';
import { LoadState } from 'src/app/_models/common/loadState';

export const clearState = createAction(
    '[DigitalSigning] clear state'
);

export const setDigitalSigningProviderLoadState = createAction(
    '[DigitalSigning] Set Signing Provider Load State',
    props<{ loadState: LoadState, }>()
);

export const loadDigitalSigningProvider = createAction(
    '[DigitalSigning] Load Signing Provider'
);

export const getDigitalSigningEmailTemplate = createAction(
    '[DigitalSigning] Get email template from signing provider'
);

export const setActiveStep = createAction(
    '[DigitalSigning] Set active step',
    props<{ step: number, }>()
);

export const setShowErrors = createAction(
    '[DigitalSigning] Set show errors',
    props<{ showErrors: boolean, }>()
);

export const setActiveTemplateById = createAction(
    '[DigitalSigning] Set active template by id',
    props<{ templateId: number, }>()
);

export const setDocuments = createAction(
    '[DigitalSigning] Set documents to be signed',
    props<{ documents: ApiModels.RequestModels.DigitalSigningProviderDocumentModel[], }>()
);

export const setSigners = createAction(
    '[DigitalSigning] Set signers',
    props<{ signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[], }>()
);

export const addSigner = createAction(
    '[DigitalSigning] Add signer',
    props<{ signer: ApiModels.RequestModels.DigitalSigningProviderSignerModel | ApiModels.RequestModels.DigitalSigningProviderSignerModel[], index?: number; }>()
);

export const updateSigner = createAction(
    '[DigitalSigning] Update signer',
    props<{ updatedSigner: ApiModels.RequestModels.DigitalSigningProviderSignerModel, index: number, }>()
);

export const removeSigner = createAction(
    '[DigitalSigning] Remove signer',
    props<{ index: number, }>()
);

export const clearEmptySigners = createAction(
    '[DigitalSigning] Remove all empty signers',
);

export const setSigningPackageTitle = createAction(
    '[DigitalSigning] Set signing package title',
    props<{ signingPackageTitle: string, }>()
);

export const setEmailSubject = createAction(
    '[DigitalSigning] Set email subject line',
    props<{ emailSubject: string, }>()
);

export const setEmailText = createAction(
    '[DigitalSigning] Set email body text',
    props<{ emailText: string, }>()
);

export const submitDigitalSigningRequest = createAction(
    '[DigitalSigning] Submit digital signing request',
    props<{
        cvr: string,
        signingPackageTemplateTypeId: number,
        signingPackageTitle: string,
        emailSubject: string,
        emailText: string,
        signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[],
        documents: ApiModels.RequestModels.DigitalSigningProviderDocumentModel[];
        endAction?: ApiModels.RequestModels.Enums.EndActionEnum;
    }>()
);

export const clearUploadedData = createAction(
    '[DigitalSigning] Clear user uploaded data',
);

export const loadCaseFiles = createAction(
    '[DigitalSigning] Load linked case files',
);