// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';
// Models
import * as SharedComponentsModels from '../_models';

@Component({
  selector: 'shared-card',
  templateUrl: './shared-card.component.html',
  styleUrls: ['./shared-card.component.scss']
})

export class SharedCardComponent {
  @Input() sharedCardInfo: SharedComponentsModels.SharedCardModel;
  @Output() buttonPressed = new EventEmitter();

  constructor() { }

  buttonPressedEmit(): void {
    this.buttonPressed.emit();
  }
}
