// Angular
import { Injectable } from "@angular/core";
import { EMPTY } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
// NgRx
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
// State
import * as ErstState from ".";
import * as ErstSelectors from './erst.selectors';
// Services
import { ErstCertificateService } from "../_service/erst-certificate.service";
import { ErstService } from "../_service/erst.service";
import { ErstSuggestionService } from '../_service/erst-suggestion.service';
@Injectable()
export class ErstEffects {

  constructor(
    private actions$: Actions,
    private erstCertificateService: ErstCertificateService,
    private erstService: ErstService,
    private suggestionService: ErstSuggestionService,
    private store: Store<ErstState.State>
  ) { }

  loadErstCertificateInfoFromBusinessConnect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ErstState.ErstActions.loadErstCertificateInfoFromBusinessConnect),
      concatLatestFrom(() => this.store.select(ErstSelectors.getErstCertificateInfoLoadState)),
      filter(([, loadState]) => loadState === 'NOT_LOADED'),
      tap(() => this.store.dispatch(ErstState.ErstActions.setErstCertificateInfoLoadState({ loadState: 'LOADING' }))),
      mergeMap(() =>
        this.erstCertificateService.loadCertificateFromBusinessConnect().pipe(
          withLatestFrom(
            this.store.select(ErstSelectors.getErstIxbrlFile),
            this.store.select(ErstSelectors.getErstPdfFile),
            this.store.select(ErstSelectors.getErstXbrlFile),
          ),
          tap(([certificateDetails, erstIxbrl, erstPdf, erstXbrl]) => {
            if (certificateDetails) {
              let step = 1;
              if (erstIxbrl || (erstPdf && erstXbrl)) step += 1;
              this.store.dispatch(ErstState.ErstActions.setActiveStep({ step: step }));
            }
          }),
          map(([certificateDetails]) => {
            return ErstState.ErstApiActions.loadErstCertificateInfoFromBusinessConnectSuccess(
              { erstCertificateInfoFromBusinessConnect: certificateDetails }
            );
          }),
          catchError(error => {
            let noCertificateUploaded =
              error.errorModel?.businessErrors?.find(err => err.errorCode === this.erstCertificateService.noCertificateUploadedErrorCode) !== undefined;
            if (noCertificateUploaded)
              this.store.dispatch(ErstState.ErstApiActions.loadErstCertificateInfoFromBusinessConnectFailure({ noCertificateUploaded: noCertificateUploaded }));
            return EMPTY;
          })
        )
      ),
    );
  });

  loadSuggestedDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ErstState.ErstActions.loadSuggestedDocuments),
      mergeMap(() =>
        this.suggestionService.getSuggestion().pipe(
          withLatestFrom(
            this.store.select(ErstSelectors.getErstCertificateInfoFromBusinessConnect),
          ),
          tap(([documents, certificateInfoFromBusinessConnect]) => {
            if (certificateInfoFromBusinessConnect && (documents.ixbrl || (documents.pdf && documents.xbrl)))
              this.store.dispatch(ErstState.ErstActions.setActiveStep({ step: 2 }));
          }),
          map(([documents]) => {
            this.store.dispatch(ErstState.ErstActions.setErstIxbrlFile({ erstIxbrlFile: documents.ixbrl }));
            this.store.dispatch(ErstState.ErstActions.setErstPdfFile({ erstPdfFile: documents.pdf }));
            this.store.dispatch(ErstState.ErstActions.setErstXbrlFile({ erstXbrlFile: documents.xbrl }));
            this.store.dispatch(ErstState.ErstActions.setErstXbrlRevenueFile({ erstXbrlRevenueFile: documents.xbrlRevenue }));
            return ErstState.ErstApiActions.loadSuggestedDocumentsSuccess();
          }),
          catchError(error => {
            this.store.dispatch(ErstState.ErstApiActions.loadSuggestedDocumentsFailure());
            return EMPTY;
          })
        )
      )
    );
  });

  submitXbrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ErstState.ErstActions.submitXbrl),
      withLatestFrom(
        this.store.select(ErstSelectors.getErstCertificatePassword),
        this.store.select(ErstSelectors.getErstCertificateBase64),
        this.store.select(ErstSelectors.getStoreCertificate),
        this.store.select(ErstSelectors.getErstSubmitToken),
        this.store.select(ErstSelectors.getErstXbrlBase64),
        this.store.select(ErstSelectors.getErstXbrlRevenueBase64),
        this.store.select(ErstSelectors.getErstPdfBase64),
        this.store.select(ErstSelectors.getErstXbrlFile),
        this.store.select(ErstSelectors.getErstXbrlRevenueFile),
        this.store.select(ErstSelectors.getErstPdfFile),
      ),
      mergeMap(([actionArgs, certPwd, certBase64, storeCert, token, xbrl, xbrlRev, pdf, erstXbrlFile, erstXbrlRevenueFile, erstPdfFile]) => {
        let fileNames = [erstXbrlFile, erstXbrlRevenueFile, erstPdfFile].flatMap(file => file ? file.name : []);
        return this.erstService.submitXbrl(certPwd, certBase64, storeCert, token, fileNames, xbrl, xbrlRev, pdf, actionArgs.onlyTest).pipe(
          map((submitDisplayResponseModel) => {
            if (submitDisplayResponseModel.token) this.store.dispatch(ErstState.ErstActions.setXbrlToken({ token: submitDisplayResponseModel.token }));
            return ErstState.ErstApiActions.submitXbrlSuccess({ erstSubmitResponse: submitDisplayResponseModel });
          }),
          catchError(error => {
            this.store.dispatch(ErstState.ErstApiActions.submitXbrlFailure({ erstSubmitResponse: error }));
            return EMPTY;
          })
        );
      }),
      catchError(error => {
        console.error(error);
        return EMPTY;
      })
    );
  });

  submitIxbrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ErstState.ErstActions.submitIxbrl),
      withLatestFrom(
        this.store.select(ErstSelectors.getErstCertificatePassword),
        this.store.select(ErstSelectors.getErstCertificateBase64),
        this.store.select(ErstSelectors.getStoreCertificate),
        this.store.select(ErstSelectors.getErstSubmitToken),
        this.store.select(ErstSelectors.getErstIxbrlBase64),
        this.store.select(ErstSelectors.getErstXbrlRevenueBase64),
        this.store.select(ErstSelectors.getErstIxbrlFile),
        this.store.select(ErstSelectors.getErstXbrlRevenueFile),
      ),
      mergeMap(([actionArgs, certPwd, certBase64, storeCert, token, ixbrl, xbrlRev, erstIxbrlFile, erstXbrlRevenueFile]) => {
        let fileNames = [erstIxbrlFile, erstXbrlRevenueFile].flatMap(file => file ? file.name : []);
        return this.erstService.submitIxbrl(certPwd, certBase64, storeCert, token, fileNames, ixbrl, xbrlRev, actionArgs.onlyTest).pipe(
          map((submitDisplayResponseModel) => {
            if (submitDisplayResponseModel.token) this.store.dispatch(ErstState.ErstActions.setXbrlToken({ token: submitDisplayResponseModel.token }));
            return ErstState.ErstApiActions.submitIxbrlSuccess({ erstSubmitResponse: submitDisplayResponseModel });
          }),
          catchError(error => {
            this.store.dispatch(ErstState.ErstApiActions.submitIxbrlFailure({ erstSubmitResponse: error }));
            return EMPTY;
          })
        );
      }),
      catchError(error => {
        console.error(error);
        return EMPTY;
      })
    );
  });
}