// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';
// Models
import { MessageTypes } from '../_models/types/MessageTypes';

@Component({
  selector: 'simple-message',
  templateUrl: './simple-message.component.html',
  styleUrls: ['./simple-message.component.scss']
})

export class SimpleMessageComponent {
  @Input() content: string[];
  @Input() type: MessageTypes = 'info';
  @Input() hideRemove: boolean = false;
  @Output() remove = new EventEmitter();

  constructor() { }

  removeContent(): void {
    this.content = [];
    this.remove.emit();
  }
}
