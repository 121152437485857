// Angular modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
// Angular Material
import { MaterialModule } from '../../_material/material.module';
// NgRx
import { StoreModule } from '@ngrx/store';
// Modules
import { ErrorModule } from '../error/error.module';
import { UpdateModule } from '../update/update.module';
// Components
import { LayoutComponent } from './layout.component';
// Other
import { LayoutReducer } from './_state/layout.reducer';


@NgModule({
    imports: [
        BrowserModule,
        ErrorModule,
        MaterialModule,
        UpdateModule,
        RouterModule,
        StoreModule.forFeature('layout', LayoutReducer),
    ],
    declarations: [
        LayoutComponent,
    ],
    exports: [
        LayoutComponent,
    ]
})
export class LayoutModule { }