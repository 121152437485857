import { NgModule, AfterViewInit, Directive, ElementRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[matTooltip][EllipsisToolTip]'
})
export class EllipsisToolTipDirective implements AfterViewInit {
  constructor(
    private matTooltip: MatTooltip,
    private elementRef: ElementRef<HTMLElement>
  ) { }

  // Disable tooltip when text is not ellipsis
  public ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      this.matTooltip.disabled = element.scrollWidth <= element.clientWidth;
    });
  }
}

@NgModule({
  declarations: [EllipsisToolTipDirective],
  exports: [EllipsisToolTipDirective]
})
export class EllipsisDirective { }