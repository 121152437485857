// Angular
import { Injectable } from '@angular/core';
// Services
import { CwWpErrorConstantService } from './cw-wp-error-constant.service';
import { ErrorDispatchService } from './error-dispatch.service';
// Models
import { HttpError } from '../components/error/_models/classes';
import { CwWpError } from '../components/error/_models/classes';

@Injectable({
  providedIn: 'root'
})

export class ErrorService {
  constructor(
    private errorDispatchService: ErrorDispatchService,
  ) { }

  handleBusinessConnectErrors(responseApiModel: any, skipDispatchOfErrors: boolean): boolean {
    if (responseApiModel?.errorModel?.businessErrors.length > 0 || responseApiModel?.errorModel?.systemError) {
      if (responseApiModel?.errorModel?.businessErrors.length > 0) {
        if (!skipDispatchOfErrors) this.errorDispatchService.dispatchBusinessErrors(responseApiModel.errorModel.businessErrors);
      } else if (responseApiModel?.errorModel?.systemError) {
        if (!skipDispatchOfErrors) this.errorDispatchService.dispatchSystemErrors(responseApiModel?.errorModel?.systemError);
      }
      return true;
    }
    return false;
  }

  handleCwWpErrors(cwResponse: any, skipDispatchOfErrors: boolean): boolean {
    if (cwResponse?.error) {
      var cwWpError: CwWpError = new CwWpError(cwResponse.errorCode, CwWpErrorConstantService[cwResponse.errorCodeName]);
      if (!skipDispatchOfErrors) this.errorDispatchService.dispatchCwWpErrors(cwWpError);
      return true;
    }
    return false;
  }

  handleHttpErrors(error: any): void {
    var httpError: HttpError = new HttpError(error.code, error.message);
    // todo : test this
    if (!window.navigator.onLine) {
      httpError.errorMessage = 'Kontroller venligst din internetforbindelse, og prøv igen';
    } else if (error.config?.url.includes('cw-wp')) {
      httpError.errorMessage = 'Fejl i kommunikation med Caseware';
    }
    this.errorDispatchService.dispatchHttpErrors(httpError);
  }
}