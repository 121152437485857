import { DigitalSigningDocumentTypeModel } from './DigitalSigningDocumentTypeModel';
import { DigitalSigningSignerTypeModel } from './DigitalSigningSignerTypeModel';

export class DigitalSigningTemplateInformation {
  constructor(
    public id: number,
    public name: string,
    public documentTypes: DigitalSigningDocumentTypeModel[],
    public signerTypes: DigitalSigningSignerTypeModel[],
  ) { }
}