// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
// NgRx
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// State
import * as DigitalSigningState from '.';
import * as DigitalSigningSelectors from './digital-signing.selectors';
// Services
import { BusinessCoreRouteConstantService } from 'src/app/_services/business-core-route-constant.service';
import { DigitalSigningService } from '../_service/digital-signing.service';
import { DigitalSigningSubmitService } from '../_service/digital-signing-submit.service';
import { DigitalSigningCaseFilesService } from '../_service/digital-signing-casefiles.service';
// Models
import * as ApiModels from '../_service/api-models';
import * as DigitalSigningModels from '../_models';


@Injectable()
export class DigitalSigningEffects {

    constructor(
        private actions$: Actions,
        private caseFileService: DigitalSigningCaseFilesService,
        private digitalSigningService: DigitalSigningService,
        private digitalSigningSubmitService: DigitalSigningSubmitService,
        private router: Router,
        private store: Store<DigitalSigningState.State>,
    ) { }

    getDigitalSigningTemplates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DigitalSigningState.DigitalSigningActions.loadDigitalSigningProvider),
            concatLatestFrom(() => this.store.select(DigitalSigningSelectors.getDigitalSigningProviderLoadState)),
            filter(([, loadState]) => loadState === 'NOT_LOADED'),
            tap(() => this.store.dispatch(DigitalSigningState.DigitalSigningActions.setDigitalSigningProviderLoadState({ loadState: 'LOADING' }))),
            mergeMap(() =>
                this.digitalSigningService.getSigningProvider().pipe(
                    map((digitalSigningTemplateResponse: DigitalSigningModels.DigitalSigningProviderModel) =>
                        DigitalSigningState.DigitalSigningApiActions.loadDigitalSigningProviderSuccess(
                            { digitalSigningProvider: digitalSigningTemplateResponse }
                        )),
                    catchError(error => {
                        this.store.dispatch(DigitalSigningState.DigitalSigningApiActions.loadDigitalSigningProviderFailure());
                        return EMPTY;
                    })
                ),
            ),
        );
    });

    submitDigitalSigningRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DigitalSigningState.DigitalSigningActions.submitDigitalSigningRequest),
            mergeMap((actionArgs) =>
                this.digitalSigningSubmitService.sendToSigning(
                    actionArgs.cvr,
                    actionArgs.signingPackageTemplateTypeId,
                    actionArgs.signingPackageTitle,
                    actionArgs.emailSubject,
                    actionArgs.emailText,
                    actionArgs.signers,
                    actionArgs.documents,
                    actionArgs.endAction,
                ).pipe(
                    map((DigitalSigningRequestResponse: ApiModels.ResponseModels.IDigitalSigningProviderResponseModel) => DigitalSigningState.DigitalSigningApiActions.submitDigitalSigningRequestSuccess(
                        { signingResponse: DigitalSigningRequestResponse.data }
                    )),
                    catchError(error => {
                        this.store.dispatch(DigitalSigningState.DigitalSigningApiActions.submitDigitalSigningRequestFailure());
                        return EMPTY;
                    })
                )
            ),
        );
    });

    getDigitalSigningEmailTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DigitalSigningState.DigitalSigningActions.getDigitalSigningEmailTemplate),
            mergeMap(() => this.digitalSigningService.getSigningProviderEmailTemplate().pipe(
                map((digitalSigningProviderEmailTemplate: ApiModels.ResponseModels.IDigitalSigningProviderEmailValuesApiModel) => {
                    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setEmailSubject(
                        { emailSubject: digitalSigningProviderEmailTemplate.defaultSubject }
                    ));
                    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setEmailText(
                        { emailText: digitalSigningProviderEmailTemplate.defaultBody }
                    ));
                    return DigitalSigningState.DigitalSigningApiActions.getDigitalSigningEmailTemplateSuccess();
                }),
                catchError(error => {
                    this.store.dispatch(DigitalSigningState.DigitalSigningApiActions.getDigitalSigningEmailTemplateFailure());
                    return EMPTY;
                })
            )),
        );
    });

    loadCaseFiles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DigitalSigningState.DigitalSigningActions.loadCaseFiles),
            mergeMap(() => this.caseFileService.getAllLinkedCaseFiles().pipe(
                map((caseFiles: DigitalSigningModels.DigitalSigningCaseFileModel[]) => {
                    if (!caseFiles) {
                        this.router.navigate([BusinessCoreRouteConstantService.SIGNING_SUBMISSION]);
                        return DigitalSigningState.DigitalSigningApiActions.loadCaseFilesSuccess({ caseFiles: [] });
                    }
                    return DigitalSigningState.DigitalSigningApiActions.loadCaseFilesSuccess({ caseFiles: caseFiles.filter(e => e !== null) });
                }),
                catchError(error => {
                    this.store.dispatch(DigitalSigningState.DigitalSigningApiActions.loadCaseFilesFailure());
                    return EMPTY;
                })
            ))
        );
    });
}