// NgRx
import { createAction, props } from '@ngrx/store';
// Models
import { LicensedServicesModel } from '../../_models/LicensedServicesModel';

export const getAvailableLicensedServicesSuccess = createAction(
    '[License Api] get available LicensedServices success',
    props<{ availableLicensedServices: LicensedServicesModel[]; }>()
);

export const getAvailableLicensedServicesFailure = createAction(
    '[License Api] get available LicensedServices failure'
);

export const licenseValidatedSuccess = createAction(
    '[License Api] license validated success',
    props<{ licensedServiceId: string; }>()
);

export const licenseValidatedFailure = createAction(
    '[License Api] license validated failure',
    props<{ licensedServiceId: string; }>()
);
