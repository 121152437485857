// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
// NgRx
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
// State
import * as AuthState from '.';
// Services
import { AuthenticationService } from '../_services/authentication.service';
import { BusinessCoreRouteConstantService } from 'src/app/_services/business-core-route-constant.service';
// Models
import * as AuthModels from '../_models';

@Injectable()
export class AuthEffects {
    authUser: AuthModels.AuthenticationUser = null;

    constructor(
        private actions$: Actions,
        private router: Router,
        private authenticationService: AuthenticationService,
        private store: Store<AuthState.State>
    ) { }

    loginUserCredentials$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(AuthState.AuthActions.loginUserCredentials),
                mergeMap((actionArgs) =>
                    this.authenticationService.loginUserCredentials(actionArgs.userCredentials?.username, actionArgs.userCredentials?.password).pipe(
                        map((authUser: AuthModels.AuthenticationUser) => AuthState.AuthApiActions.loginSuccess({ authUser: authUser })),
                        catchError(error => {
                            this.store.dispatch(AuthState.AuthApiActions.loginFailure());
                            return EMPTY;
                        })
                    )
                ),
            );
    });

    loginApiKey$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(AuthState.AuthActions.loginApiKey),
                mergeMap((actionArgs) =>
                    this.authenticationService.loginApiKey(actionArgs.apiKey).pipe(
                        map((authUser: AuthModels.AuthenticationUser) => AuthState.AuthApiActions.loginSuccess({ authUser: authUser })),
                        catchError(error => {
                            this.store.dispatch(AuthState.AuthApiActions.loginFailure());
                            return EMPTY;
                        })
                    )
                ),
            );
    });

    autoLogin$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(AuthState.AuthActions.autoLogin),
                mergeMap(() => this.authenticationService.retrieveRememberedApiKey()),
                mergeMap((apiKey: string) => {
                    if (apiKey === null || apiKey === '') {
                        this.store.dispatch(AuthState.AuthApiActions.loginFailure());
                        return EMPTY;
                    }
                    return this.authenticationService.loginApiKey(apiKey, true).pipe(
                        map((authUser: AuthModels.AuthenticationUser) => AuthState.AuthApiActions.loginSuccess({ authUser: authUser })),
                    );
                }),
                catchError(error => {
                    this.store.dispatch(AuthState.AuthApiActions.loginFailure());
                    return EMPTY;
                })
            );
    });

    logout$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(AuthState.AuthActions.logout),
                tap(() => this.authenticationService.removeApiKeyFromLocalRegistry()),
                tap(() => {
                    let navigationReturnUrl = this.router.url;
                    this.router.navigate([BusinessCoreRouteConstantService.LOGIN], { queryParams: { returnUrl: navigationReturnUrl } });
                }),
                map(() => {
                    return { type: 'NO_ACTION' };
                })
            );
    });
}