// Angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as ErrorState from '../../../../error/_state';
import * as ErstState from '../../../_state';
import * as ErstSelectors from '../../../_state/erst.selectors';
// Services
import { FileService } from 'src/app/_services';
// Models
import * as CertificateModels from '../../../_models';
import { FileDetailsModel } from 'src/app/_models/common';

interface IViewModel {
  certificateFromBc: CertificateModels.Interfaces.ICertificateDetails;
  certificateFile: FileDetailsModel;
  certificatePassword: string,
  ixbrlFile: FileDetailsModel;
  pdfFile: FileDetailsModel;
  xbrlFile: FileDetailsModel;
  xbrlRevenueFile: FileDetailsModel;
  isIxbrl: boolean;
}

@Component({
  selector: 'app-erst-submission-step-indsend',
  templateUrl: './erst-submission-step-indsend.component.html',
  styleUrls: ['./erst-submission-step-indsend.component.scss'],
})

export class StepIndsendComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  model: IViewModel = {
    certificateFromBc: null,
    certificateFile: null,
    certificatePassword: null,
    ixbrlFile: null,
    pdfFile: null,
    xbrlFile: null,
    xbrlRevenueFile: null,
    isIxbrl: false,
  };

  constructor(
    private fileService: FileService,
    private store: Store,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.push(this.store.select(ErstSelectors.getErstCertificateInfoFromBusinessConnect).subscribe(
      erstCertificateInfoFromBusinessConnect => {
        this.model.certificateFromBc = erstCertificateInfoFromBusinessConnect;
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstCertificateFile).subscribe(
      erstCertificateFile => {
        this.model.certificateFile = erstCertificateFile;
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstCertificatePassword).subscribe(
      erstCertificatePassword => {
        this.model.certificatePassword = erstCertificatePassword;
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstIxbrlFile).subscribe(
      erstIxbrlFile => {
        this.model.ixbrlFile = erstIxbrlFile;
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstPdfFile).subscribe(
      erstPdfFile => {
        this.model.pdfFile = erstPdfFile;
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstXbrlFile).subscribe(
      erstXbrlFile => {
        this.model.xbrlFile = erstXbrlFile;
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstXbrlRevenueFile).subscribe(
      erstXbrlRevenueFile => {
        this.model.xbrlRevenueFile = erstXbrlRevenueFile;
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.isIxbrl).subscribe(
      isIxbrl => {
        this.model.isIxbrl = isIxbrl;
      }
    ));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public openFileInNewWindow(file: FileDetailsModel): void {
    this.fileService.openBase64FileInNewWindow(file.base64, file.name, file.type);
  }

  public displayDate(date: Date): String {
    return new Intl.DateTimeFormat('da-DK', { dateStyle: 'long', timeStyle: 'medium' }).format(date);
  }

  public testSubmit(): void {
    this.store.dispatch(ErrorState.ErrorActions.clearAllErrors());
    if (this.model.isIxbrl) {
      this.store.dispatch(ErstState.ErstActions.submitIxbrl({ onlyTest: true }));
    } else {
      this.store.dispatch(ErstState.ErstActions.submitXbrl({ onlyTest: true }));
    }
  }
}

