// NgRx
import { createAction, props } from '@ngrx/store';
// Other
import { UserCredentials } from 'src/app/_models/common/userCredentials';

export const loginUserCredentials = createAction(
    '[Auth] Login with user credentials',
    props<{ userCredentials: UserCredentials; }>()
);

export const loginApiKey = createAction(
    '[Auth] Login with api key',
    props<{ apiKey: string, }>()
);

export const autoLogin = createAction(
    '[Auth] Automatic login'
);

export const logout = createAction(
    '[Auth] Logout'
);
