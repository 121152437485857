// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../_material/material.module';
// Directives
import { DragAndDropDirective } from '../../_directive/drag-and-drop.directive';
import { EllipsisDirective } from 'src/app/_directive/ellipsis-tooltip.directive';
import { IconDirective } from 'src/app/_directive/icon.directive';
// Components
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SharedCardComponent } from './shared-card/shared-card.component';
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';
import { SimpleMessageComponent } from './simple-message/simple-message.component';
import { TimeInputComponent } from './time-input/time-input.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DragAndDropDirective,
    IconDirective,
    EllipsisDirective,
  ],
  declarations: [
    AutocompleteComponent,
    FileUploaderComponent,
    SelectSearchComponent,
    SharedCardComponent,
    SimpleDialogComponent,
    SimpleMessageComponent,
    TimeInputComponent,
  ],
  exports: [
    AutocompleteComponent,
    FileUploaderComponent,
    SelectSearchComponent,
    SharedCardComponent,
    SimpleDialogComponent,
    SimpleMessageComponent,
    TimeInputComponent,
  ]
})
export class SharedComponentsModule { }