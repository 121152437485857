// NgRx
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { delay } from 'rxjs/operators';
// Other
import { ICompanyState } from './company.reducer';

// Selectors
const getCompanyFeatureState = createFeatureSelector<ICompanyState>('company');

export const getUpdatingCompanyInfo = createSelector(
  getCompanyFeatureState,
  state => state.updatingCompanyInfo,
);

export const getCompanySourceProvider = createSelector(
  getCompanyFeatureState,
  state => state.companySourceProvider,
);