// NgRx
import { createAction } from '@ngrx/store';

export const GetContactpersonSourceProvider = createAction(
  '[Contactperson] Get contactperson source provider'
);

export const updateContactpersonInformation = createAction(
  '[Contactperson] Update contactperson information'
);

export const getContactpersonInformationFromBusinessConnect = createAction(
  '[Contactperson] Request contactperson information From Business Connect'
);