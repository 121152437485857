import { DigitalSigningProviderDocumentModel } from "./DigitalSigningProviderDocumentModel";
import { DigitalSigningProviderSignerModel } from "./DigitalSigningProviderSignerModel";
import { EndActionEnum } from './enums';

export class IDigitalSigningProviderRequestApiModel {
  constructor(
    public companyId: string,
    public signingPackageTemplateTypeId: number,
    public signingPackageTitle: string,
    public emailSubject: string,
    public emailText: string,
    public signers: DigitalSigningProviderSignerModel[],
    public documents: DigitalSigningProviderDocumentModel[],
    public endAction?: EndActionEnum,
  ) { }
}
