// Other
import * as AppState from 'src/app/_state/app.state';
import * as DigitalSigningActions from './_actions/digital-signing.actions';
import * as DigitalSigningApiActions from './_actions/digital-signing-api.actions';


// Barrels export
export {
    DigitalSigningActions,
    DigitalSigningApiActions,
};

export interface State extends AppState.State {
}