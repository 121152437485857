// Angular
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as ErstState from '../../../_state';
import * as ErstSelectors from '../../../_state/erst.selectors';
// Models
import * as CertificateModels from '../../../_models';
import { FileDetailsModel } from 'src/app/_models/common';

interface IViewModel {
  activeStep: number;
  certificateFromBc: CertificateModels.Interfaces.ICertificateDetails;
  certificateFromBcInfo: string[];
  certificateFile: FileDetailsModel;
  certificatePassword: string;
  certificateNotUploadedInBusinessConnect: boolean;
  hidePassword: boolean;
  storeCertificate: boolean;
}

@Component({
  selector: 'app-erst-submission-step-certifikat',
  templateUrl: './erst-submission-step-certifikat.component.html',
  styleUrls: ['./erst-submission-step-certifikat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class StepCertifikatComponent implements OnInit, OnDestroy {
  @Input() showFormErrors: boolean = false;
  @Output() stepApproved = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];
  removeFileInFileUploader: Subject<number> = new Subject<number>();
  model: IViewModel = {
    activeStep: null,
    certificateFromBc: null,
    certificateFromBcInfo: [],
    certificateFile: null,
    certificatePassword: null,
    certificateNotUploadedInBusinessConnect: null,
    hidePassword: true,
    storeCertificate: false,
  };

  constructor(
    private store: Store,
  ) { }

  public ngOnInit(): void {
    this.store.dispatch(ErstState.ErstActions.loadErstCertificateInfoFromBusinessConnect());

    this.subscriptions.push(this.store.select(ErstSelectors.getActiveStep).subscribe(
      activeStep => { this.model.activeStep = activeStep; }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstCertificateInfoFromBusinessConnect).subscribe(
      erstCertificateInfoFromBusinessConnect => {
        this.model.certificateFromBc = erstCertificateInfoFromBusinessConnect;
        this.model.certificateFromBcInfo = [
          'Dette registrerede certifikat vil blive benyttet ved indsendelse, såfremt du ikke vælger et andet certifikat',
          `<li><strong>Certifikatnavn:</strong> ${this.model.certificateFromBc?.certificate.name}</li>` +
          `<li><strong>Certifikat alias:</strong> ${this.model.certificateFromBc?.certificate.customName}</li>` +
          `<li><strong>Certifikattype:</strong> ${this.model.certificateFromBc?.certificate.typeDisplay}</li>` +
          `<li><strong>Certifikat beskrivelse:</strong> ${this.model.certificateFromBc?.certificate.typeDescription}</li>` +
          `<li><strong>Certifikat CVR-nr.:</strong> ${this.model.certificateFromBc?.customer.cvr}</li>` +
          `<li><strong>Certifikat udløbsdato:</strong> ${this.model.certificateFromBc?.certificate.expirationDateDisplay}</li>` +
          `<li><strong>Certifikat upload dato:</strong> ${this.model.certificateFromBc?.certificate.uploadedDateDisplay}</li>`,
        ];
        this.validate();
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.isErstCertificateNotUploaded).subscribe(
      certificateNotUploaded => { this.model.certificateNotUploadedInBusinessConnect = certificateNotUploaded; }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstCertificateFile).subscribe(
      certificateFile => {
        this.model.certificateFile = certificateFile;
        if (!certificateFile) this.removeFile();
        this.validate();
      }
    ));

    this.subscriptions.push(this.store.select(ErstSelectors.getErstCertificatePassword).subscribe(
      certificatePassword => {
        this.model.certificatePassword = certificatePassword;
        this.validate();
      }
    ));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public fileUpload(uploadedFile: FileDetailsModel): void {
    this.dispatchUploadedCertificate(uploadedFile);
    this.validate();
  }

  public fileRemove(): void {
    this.model.certificatePassword = null;
    this.dispatchUploadedCertificate(null);
    this.validate();
  };

  public updatePassword(): void {
    let password = this.model.certificatePassword ? this.model.certificatePassword : null;
    this.store.dispatch(ErstState.ErstActions.setErstCertificatePassword({ erstCertificatePassword: password }));
    this.validate();
  }

  public storeCertificate(): void {
    this.store.dispatch(ErstState.ErstActions.setStoreCertificate({ storeCertificate: this.model.storeCertificate }));
  }

  public removeFile(): void {
    this.removeFileInFileUploader.next(0);
  };

  private dispatchUploadedCertificate(uploadedFile: FileDetailsModel): void {
    this.store.dispatch(ErstState.ErstActions.setErstCertificateFile({ erstCertificateFile: uploadedFile }));
  }

  private validate(): void {
    this.stepApproved.emit((this.model.certificateFromBc !== null && !this.model.certificateFile) || (this.model.certificateFile && this.model.certificatePassword !== null));
  }
}

