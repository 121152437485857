// NgRx
import { createAction, props } from '@ngrx/store';

export const isCspUpdateRequiredSuccess = createAction(
    '[Update Api] is csp files update required success',
    props<{ updateRequired: boolean; }>()
);

export const isCspUpdateRequiredFailure = createAction(
    '[Update Api] is csp files update required failure'
);
