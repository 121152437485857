// NgRx
import { createAction, props } from '@ngrx/store';
// Models
import { LicensedServicesModel } from '../../_models/LicensedServicesModel';
import { LoadState } from 'src/app/_models/common';

export const clearState = createAction(
    '[License] clear state'
);

export const getAvailableLicensedServices = createAction(
    '[License] get available LicensedServices',
);

export const setAvailableLicensedServicesLoadState = createAction(
    '[License] set available LicensedServices state',
    props<{ state: LoadState; }>()
);

export const setDigitalSigningLicensedService = createAction(
    '[License] set digitalSigningLicensedService',
    props<{ licensedService: LicensedServicesModel; }>()
);

export const setErstLicensedService = createAction(
    '[License] set erstLicensedService',
    props<{ licensedService: LicensedServicesModel; }>()
);
