// NgRx
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Other
import { IContactpersonState } from './contactperson.reducer';

// Selectors
const getContactpersonFeatureState = createFeatureSelector<IContactpersonState>('contactperson');

export const getContactpersonSourceProvider = createSelector(
  getContactpersonFeatureState,
  state => state.contactpersonSourceProvider,
);

export const getUpdatingContactpersonInfo = createSelector(
  getContactpersonFeatureState,
  state => state.updatingContactpersonInfo,
);

export const getContactPerson = createSelector(
  getContactpersonFeatureState,
  state => state.contactperson,
);