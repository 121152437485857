// Angular
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class CwWpConstantService {
  public static readonly CSP_VERSION = "1.0.0";

  private static readonly PROGRAM_PATH = 'http://cw-wp/.current/program';
  private static readonly FOLDER_PATH = '/scripts/bcwp/csp';

  private static readonly URL_GET_CLIENT_DATA = '/client-request.csp';
  private static readonly URL_STORE_CLIENT_DATA = '/client-store.csp';

  private static readonly URL_GET_CV_DATA = '/cv-request.csp';
  private static readonly URL_STORE_CV_DATA = '/cv-store.csp';

  private static readonly URL_GET_DOCUMENT = '/documents-request.csp';
  private static readonly URL_STORE_DOCUMENT = '/documents-store.csp';

  private static readonly URL_DELETE_FILE = '/files-delete.csp';
  // todo build together with document request
  private static readonly URL_GET_FILE = '/files-request.csp';
  private static readonly URL_READ_FILE = '/files-read.csp';
  private static readonly URL_STORE_FILE = '/files-store.csp';

  private static readonly URL_DELETE_REGISTRY_DATA = '/registry-delete.csp';
  private static readonly URL_GET_REGISTRY_DATA = '/registry-request.csp';
  private static readonly URL_STORE_REGISTRY_DATA = '/registry-store.csp';

  private static readonly URL_VERSION = '/version.csp';

  private static readonly URL_SYSTEM_CHECK = '/system-check.csp';
  private static readonly URL_EVAL = '/eval.csp';

  public static readonly URL_CONFIG = "scripts/bcwp/config.json";
  public static readonly URL_FOLDER_PATH = CwWpConstantService.FOLDER_PATH;

  public static readonly ENDPOINT_GET_CLIENT_DATA =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_GET_CLIENT_DATA;
  public static readonly ENDPOINT_STORE_CLIENT_DATA =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_STORE_CLIENT_DATA;

  public static readonly ENDPOINT_GET_CV_DATA =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_GET_CV_DATA;
  public static readonly ENDPOINT_STORE_CV_DATA =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_STORE_CV_DATA;

  public static readonly ENDPOINT_GET_DOCUMENT =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_GET_DOCUMENT;
  public static readonly ENDPOINT_STORE_DOCUMENT =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_STORE_DOCUMENT;

  public static readonly ENDPOINT_DELETE_FILE =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_DELETE_FILE;
  public static readonly ENDPOINT_GET_FILE =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_GET_FILE;
  public static readonly ENDPOINT_READ_FILE =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_READ_FILE;
  public static readonly ENDPOINT_STORE_FILE =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_STORE_FILE;

  public static readonly ENDPOINT_DELETE_REGISTRY_DATA =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_DELETE_REGISTRY_DATA;
  public static readonly ENDPOINT_GET_REGISTRY_DATA =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_GET_REGISTRY_DATA;
  public static readonly ENDPOINT_STORE_REGISTRY_DATA =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_STORE_REGISTRY_DATA;

  public static readonly ENDPOINT_CSP_VERSION_FILE =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_VERSION;

  public static readonly ENDPOINT_SYSTEM_CHECK =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_SYSTEM_CHECK;

  public static readonly ENDPOINT_EVAL =
    CwWpConstantService.PROGRAM_PATH + CwWpConstantService.FOLDER_PATH + CwWpConstantService.URL_EVAL;

}
