<div [class]="'simple-dialog-container ' + data.type +'-scrollbar' ">
  <div mat-dialog-title>
    <mat-icon [iconType]="data.type"></mat-icon>
    <div class="mat-dialog-title-text">
      {{data.title}}
    </div>
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon iconType="close"></mat-icon>
    </button>
  </div>
  <mat-dialog-content #content [innerHTML]="bypassSecurityTrustHtml(data.content)">
  </mat-dialog-content>
  <mat-dialog-actions align="end" *ngIf="data.buttonLabel">
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>{{data.buttonLabel}}</button>
  </mat-dialog-actions>
</div>