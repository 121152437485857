import { NgModule, AfterViewInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[spinnerPosition]'
})
export class SpinnerPositionDirective implements AfterViewInit {
  @HostBinding('style.display') private display: string;
  @HostBinding('style.position') private position: string;
  @HostBinding('style.margin') private margin: string;
  @HostBinding('style.left') private left: string;
  @HostBinding('style.top') private top: string;
  @Input() spinnerPosition: string;

  constructor(
    private elementRef: ElementRef<HTMLElement>
  ) { }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      const diameter = element.getAttribute('diameter') ? Number(element.getAttribute('diameter')) : 50;
      const radius = diameter / 2;
      switch (this.spinnerPosition) {
        case 'x-center':
          this.position = 'absolute';
          this.margin = `0 -${radius}px`;
          this.left = '50%';
          break;
        // todo: test if this is correct
        case 'y-center':
          this.position = 'absolute';
          this.margin = `${radius / 2}px 0 0 0`;
          this.top = '50%';
          break;
        case 'center':
          this.position = 'absolute';
          this.left = '50%';
          this.top = '50%';
          this.margin = `-${diameter}px -${diameter}px`;
          break;
        case 'element-x-center':
          this.display = 'inline-block';
          break;

        default:
          throw Error(`7633d3b5-0a67-4932-8654-a3a468049aa2 - The Directive [spinnerPosition] does not recognize the type: ${this.spinnerPosition}`);
      };
    });
  }
}

@NgModule({
  declarations: [SpinnerPositionDirective],
  exports: [SpinnerPositionDirective]
})
export class SpinnerDirective { }