// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, timeout } from 'rxjs/operators';

import * as ApiModels from './api-models';
import * as AppServices from '../../../_services/';
import * as CwWpModels from '../../../_models/cw-wp';

import { environment } from 'src/environments/environment';

import { AxiosHttpClient } from 'src/app/_http/axios-http-client';

@Injectable({
  providedIn: 'root'
})
export class ContactpersonService {
  constructor(
    private errorDispatchService: AppServices.ErrorDispatchService,
    private httpClient: AxiosHttpClient
  ) { }

  getBusinessRegistrationNumberFromCaseware(): Observable<string> {
    const CVR_REQUEST: CwWpModels.apiModels.IClientGetRequests = new CwWpModels.apiModels.IClientGetRequests([
      { property: 'ClientProfile', subProperty: 'EIN' }
    ]);
    return this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_GET_CLIENT_DATA, CVR_REQUEST).pipe(
      map((cwResponse: CwWpModels.apiModels.ICwWpResponse) => cwResponse.data[0])
    );
  }

  getContactpersonFromBusinessConnect(cvr: string): Observable<ApiModels.IContactpersonResponseApiModel> {
    // todo : remove ?source=1
    return this.httpClient.get(AppServices.BusinessCoreConstantService.ENDPOINT_COMPANY_CONTACTPERSON + '/' + cvr + '?source=1', true)
      .pipe(
        timeout(environment.timeout),
        map((IContactpersonResponseApiModel) => {
          if (this.serverErrorResponse(IContactpersonResponseApiModel)) return null;
          return IContactpersonResponseApiModel;
        }),
        catchError(error => {
          this.errorDispatchService.dispatchOtherError(error);
          return throwError(() => error);
        })
      );
  }

  getContactpersonInformationFromBusinessConnect() {
    return this.getBusinessRegistrationNumberFromCaseware().pipe(
      timeout(environment.timeout),
      mergeMap((cvr: string) => this.getContactpersonFromBusinessConnect(cvr)),
      catchError(error => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  updateContactpersonInCaseware(clientStoreRequests: CwWpModels.apiModels.ICvDataStoreRequests): Observable<CwWpModels.apiModels.ICwWpResponse> {
    return this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_STORE_CV_DATA, clientStoreRequests);
  }

  updateContactperson() {
    return this.getBusinessRegistrationNumberFromCaseware().pipe(
      timeout(environment.timeout),
      mergeMap((cvr: string) => this.getContactpersonFromBusinessConnect(cvr)),
      mergeMap((contactpersonResponseApiModel: ApiModels.IContactpersonResponseApiModel) => {
        let bestyrelseKey = 1;
        let direktionKey = 1;
        var response = { requests: [] };
        contactpersonResponseApiModel.contactPersonModel.forEach(contactperson => {
          if (contactperson.roleDescription.includes('Bestyrelse')) {
            response.requests.push(
              {
                group: '',
                form: '',
                id: 'BESTYRELSE' + bestyrelseKey,
                // value: contactperson.fullName,
                value: '',
              },
            );
            response.requests.push(
              {
                group: '',
                form: '',
                id: 'FORMAND' + bestyrelseKey++,
                // value: contactperson.roleName === 'FORMAND' ? '000001|-00001|1|Formand' : '000000|-00001|0|Menigt medlem'
                value: ''
              },
            );
          }
          if (contactperson.roleDescription.includes('Direktion')) {
            response.requests.push(
              {
                group: '',
                form: '',
                id: 'DIREKTION' + direktionKey,
                // value: contactperson.fullName,
                value: '',
              },
            );
            let title = '';
            if (contactperson.roleName === 'DIREKTØR') title = '000001|-00001|Direktør|Direktør';
            if (contactperson.roleName === 'ADM. DIR.') title = '000002|-00001|Adm. direktør|Adm. direktør';
            response.requests.push(
              {
                group: '',
                form: '',
                id: 'DIRTIT' + direktionKey++,
                // value: title
                value: ''
              },
            );
          }
        });
        return this.updateContactpersonInCaseware(response);
        // return response;
      }),
      catchError(error => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getCompanySourceFromBusinessConnect(): Observable<ApiModels.ContactpersonSourceApiModel> {
    return this.httpClient.get(AppServices.BusinessCoreConstantService.ENDPOINT_CONTACTPERSON_CALCULATED_SOURCE, true)
      .pipe(
        timeout(environment.timeout),
        map((contactpersonSourceResponseApiModel) => {
          if (this.serverErrorResponse(contactpersonSourceResponseApiModel)) return null;
          let contactpersonApiModel: ApiModels.ContactpersonSourceApiModel = contactpersonSourceResponseApiModel.sourceModel;
          return contactpersonApiModel;
        }),
        catchError(error => {
          this.errorDispatchService.dispatchOtherError(error);
          return throwError(() => error);
        })
      );
  }

  private serverErrorResponse(responseApiModel: any): boolean {
    if (responseApiModel?.errorModel?.businessErrors.length > 0 || responseApiModel?.errorModel?.systemError) {
      if (responseApiModel?.errorModel?.businessErrors.length > 0) {
        this.errorDispatchService.dispatchBusinessErrors(responseApiModel.errorModel.businessErrors);
      } else if (responseApiModel?.errorModel?.systemError) {
        this.errorDispatchService.dispatchSystemErrors(responseApiModel?.errorModel?.systemError);
      }
      return true;
    }
    return false;
  }
}