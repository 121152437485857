// NgRx
import { createAction } from '@ngrx/store';

export const GetCompanySourceProvider = createAction(
  '[Company] Get company source provider'
);

export const updateCompanyInformation = createAction(
  '[Company] Update company information'
);
