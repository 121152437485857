// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, timeout } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Http
import { AxiosHttpClient } from 'src/app/_http/axios-http-client';
// Services
import { BusinessCoreConstantService } from 'src/app/_services';
// Models
import * as ApiModels from './api-models';
import * as DigitalSigningModels from '../_models';

@Injectable({
  providedIn: 'root'
})

export class DigitalSigningService {
  constructor(
    private httpClient: AxiosHttpClient
  ) { }

  getSigningProvider(): Observable<DigitalSigningModels.DigitalSigningProviderModel> {
    return this.httpClient.get(BusinessCoreConstantService.ENDPOINT_DIGITAL_SIGNING_TEMPLATE, true).pipe(
      timeout(environment.timeout),
      map((digitalSigningTemplateResponse) => {
        return this.mapTemplateResponseToProviderModel(digitalSigningTemplateResponse);
      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  getTemplateDescription(licensedServiceId: string, digitalSigningTemplateId: number): string {
    let templateDescription: string = '';

    switch (licensedServiceId) {
      case BusinessCoreConstantService.PENNEO_LICENSED_SERVICE_ID:
        switch (digitalSigningTemplateId) {
          case 1:
            templateDescription = `Vælg de dokumenter du ønsker, der skal underskrives, samt eventuelle bilag.
            Vælg herefter de personer som skal underskrive dokumenterne. De valgte underskrivere vil blive bedt om at underskrive alle dokumenter. 
            Bilag underskrives ikke.`;
            break;
          case 6:
            templateDescription = `Vælg de dokumenter du ønsker, der skal underskrives, samt eventuelle bilag. 
            Vælg herefter de personer som skal underskrive dokumenterne. De valgte underskrivere vil blive bedt om at underskrive relevante dokumenter. 
            Bilag underskrives ikke.`;
            break;
          case 256:
            templateDescription = `Vælg de dokumenter du ønsker, der skal underskrives, samt eventuelle bilag. 
            Vælg herefter de personer som skal underskrive dokumenterne. De valgte underskrivere vil blive bedt om at underskrive relevante dokumenter. 
            Bilag underskrives ikke.`;
            break;
          case 265:
            templateDescription = `Vælg de dokumenter du ønsker, der skal underskrives, samt eventuelle bilag. 
            Vælg herefter de personer som skal underskrive dokumenterne. De valgte underskrivere vil blive bedt om at underskrive relevante dokumenter. 
            Bilag underskrives ikke.`;
            break;
          case 2375:
            templateDescription = `Vælg de dokumenter du ønsker, der skal underskrives, samt eventuelle bilag. 
            Vælg herefter de personer som skal underskrive dokumenterne. De valgte underskrivere vil blive bedt om at underskrive relevante dokumenter. 
            Bilag underskrives ikke.`;
            break;
          case 2468:
            templateDescription = `Vælg de dokumenter du ønsker, der skal underskrives, samt eventuelle bilag. 
            Vælg herefter de personer som skal underskrive dokumenterne. De valgte underskrivere vil blive bedt om at underskrive relevante dokumenter. 
            Bilag underskrives ikke.`;
            break;
          case 273:
            templateDescription = `Vælg de dokumenter du ønsker, der skal underskrives, samt eventuelle bilag. 
            Vælg herefter de personer som skal underskrive dokumenterne. De valgte underskrivere vil blive bedt om at underskrive relevante dokumenter. 
            Bilag underskrives ikke.`;
            break;
          case 274:
            templateDescription = `Vælg de dokumenter du ønsker, der skal underskrives, samt eventuelle bilag. 
            Vælg herefter de personer som skal underskrive dokumenterne. De valgte underskrivere vil blive bedt om at underskrive relevante dokumenter. 
            Bilag underskrives ikke.`;
            break;
          case 2502:
            templateDescription = `Vælg de dokumenter du ønsker, der skal underskrives, samt eventuelle bilag. 
            Vælg herefter de personer som skal underskrive dokumenterne. De valgte underskrivere vil blive bedt om at underskrive relevante dokumenter. 
            Bilag underskrives ikke.`;
            break;
          case 2503:
            templateDescription = `Vælg de dokumenter du ønsker, der skal underskrives, samt eventuelle bilag. 
            Vælg herefter de personer som skal underskrive dokumenterne. De valgte underskrivere vil blive bedt om at underskrive relevante dokumenter. 
            Bilag underskrives ikke.`;
            break;
        }
        break;
    }
    return templateDescription;
  }

  getSigningProviderEmailTemplate(): Observable<ApiModels.ResponseModels.IDigitalSigningProviderEmailValuesApiModel> {
    return this.httpClient.get(BusinessCoreConstantService.ENDPOINT_DIGITAL_SIGNING_TEMPLATE_EMAIL, true).pipe(
      timeout(environment.timeout),
      map((digitalSigningProviderEmailTemplate: ApiModels.ResponseModels.IDigitalSigningProviderEmailValuesApiModel) => {
        return digitalSigningProviderEmailTemplate;
      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }


  private mapTemplateResponseToProviderModel(
    digitalSigningTemplateResponse: ApiModels.ResponseModels.IDigitalSigningProviderTemplateResponseApiModel
  ): DigitalSigningModels.DigitalSigningProviderModel {
    try {
      let digitalSigningProvider: DigitalSigningModels.DigitalSigningProviderModel = null;
      let digitalSigningTemplates: DigitalSigningModels.DigitalSigningTemplateInformation[] = [];
      try {
        digitalSigningTemplateResponse.data.digitalSigningTemplates.forEach(template => {
          let documentTypes = template.documentTypes.map(type => new DigitalSigningModels.DigitalSigningDocumentTypeModel(type.id, type.name));

          let signerTypes = template.documentTypes.flatMap(type => type.signerTypes.length > 0 ? type.signerTypes : []);
          signerTypes = signerTypes.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i); // remove duplicates
          signerTypes.forEach(signer => delete signer.signOrder); // remove property signOrder

          let digitalSigningTemplate: DigitalSigningModels.DigitalSigningTemplateInformation = null;

          digitalSigningTemplate = new DigitalSigningModels.DigitalSigningTemplateInformation(
            template.id,
            template.name,
            documentTypes,
            signerTypes,
          );

          digitalSigningTemplates.push(digitalSigningTemplate);
        });
      } catch (error) {
        console.error(error);
        return null;
      }
      digitalSigningProvider = new DigitalSigningModels.DigitalSigningProviderModel(
        digitalSigningTemplateResponse.data.licensedServiceMetaData,
        digitalSigningTemplates,
      );
      return digitalSigningProvider;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}