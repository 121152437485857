// Angular
import { Component, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})

export class TimeInputComponent {
  hours = new FormControl('08');
  minutes = new FormControl('00');
  @ViewChild('hoursInput') hoursInput: ElementRef;
  @ViewChild('minutesInput') minutesInput: ElementRef;

  constructor() { }

  public onChangeHours(): void {
    this.onChange(this.hours, 23, this.minutesInput);
  }

  public onChangeMinutes(): void {
    this.onChange(this.minutes, 59);
  }

  private onChange(control: FormControl, max: number, nextInput?: ElementRef): void {
    let input = control.value;
    let onlyNumbersString = input.replace(/\D/g, ''); // This removes any non-digit characters

    // Ensure the value is not greater than max
    if (onlyNumbersString > max) {
      onlyNumbersString = max;
    }

    // Set the value without padding
    control.setValue(onlyNumbersString.toString(), { emitEvent: false });

    // If the input is two characters long, focus and select the next input
    if (control.value.length === 2 && nextInput) {
      nextInput.nativeElement.focus();
      nextInput.nativeElement.select();
    }
  }

  public selectHours(): void {
    this.hoursInput.nativeElement.select();
  }

  public onBlurHours(): void {
    this.onBlur(this.hours);
  }

  public selectMinutes(): void {
    this.minutesInput.nativeElement.select();
  }

  public onBlurMinutes(): void {
    this.onBlur(this.minutes);
  }

  private onBlur(control: FormControl): void {
    let paddedValue = control.value.padStart(2, '0');
    control.setValue(paddedValue, { emitEvent: false });
  }
}