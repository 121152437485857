// Angular modules
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
//Rxjs
import { Observable } from 'rxjs';
// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// Modules
import { AuthModule } from './components/authentication/auth.module';
import { CompanyModule } from './components/company/company.module';
import { ContactpersonModule } from './components/contactperson/contactperson.module';
import { DigitalSigningModule } from './components/digital-signing/digital-signing.module';
import { HomeModule } from './components/home/home.module';
import { LayoutModule } from './components/layout/layout.module';
import { LicensModule } from './components/license/licens.module';
// Other
import { DragAndDropDirective } from './_directive/drag-and-drop.directive';
import { BusinessCoreRouteConstantService } from './_services/business-core-route-constant.service';
import { environment } from '../environments/environment';

const routes: Routes = [
  { path: '', redirectTo: BusinessCoreRouteConstantService.DASHBOARD, pathMatch: 'full' },
  { path: '**', redirectTo: BusinessCoreRouteConstantService.DASHBOARD, pathMatch: 'full' }
];

import { ConfigModel } from './_models/config/configModel';
import { ConfigService } from './_services';

function appInitializerUsingObservables(
  configService: ConfigService
): () => Observable<ConfigModel> {
  return () => configService.getConfig();
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    CompanyModule,
    ContactpersonModule,
    DigitalSigningModule,
    HomeModule,
    LayoutModule,
    LicensModule,
    ReactiveFormsModule,
    DragAndDropDirective,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    RouterModule.forRoot(routes),
    EffectsModule.forRoot([]),
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: appInitializerUsingObservables,
    deps: [ConfigService],
    multi: true,
  }],
  bootstrap: [AppComponent],
})
export class AppModule { }
