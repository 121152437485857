// NgRx
import { createAction, props } from '@ngrx/store';
import * as CertificateModels from '../../_models';
import { ISubmitDisplayResponseModel } from '../../_models/interfaces';

export const loadErstCertificateInfoFromBusinessConnectSuccess = createAction(
  '[Erst Api] Load erst certificate info from Business Connect Success',
  props<{ erstCertificateInfoFromBusinessConnect: CertificateModels.Interfaces.ICertificateDetails; }>()
);

export const loadErstCertificateInfoFromBusinessConnectFailure = createAction(
  '[Erst Api] Load erst certificate info from Business Connect Failure',
  props<{ noCertificateUploaded: boolean; }>()
);

export const loadSuggestedDocumentsSuccess = createAction(
  '[Erst Api] Load suggested documents Success',
);

export const loadSuggestedDocumentsFailure = createAction(
  '[Erst Api] Load suggested documents Failure',
);

export const submitIxbrlSuccess = createAction(
  '[Erst Api] Submit Ixbrl to Erst Success',
  props<{ erstSubmitResponse: ISubmitDisplayResponseModel; }>()
);

export const submitIxbrlFailure = createAction(
  '[Erst Api] Submit Ixbrl to Erst Failure',
  props<{ erstSubmitResponse: ISubmitDisplayResponseModel; }>()
);

export const submitXbrlSuccess = createAction(
  '[Erst Api] Submit Xbrl to Erst Success',
  props<{ erstSubmitResponse: ISubmitDisplayResponseModel; }>()
);

export const submitXbrlFailure = createAction(
  '[Erst Api] Submit Xbrl to Erst Failure',
  props<{ erstSubmitResponse: ISubmitDisplayResponseModel; }>()
);

