// Angular
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Http
import { AxiosHttpClient } from 'src/app/_http/axios-http-client';
// Services
import { CwWpConstantService } from 'src/app/_services';
import { FileService } from 'src/app/_services';
// Models
import * as CwWpModels from '../_models/cw-wp';
import { FileDetailsModel } from '../_models/common';

@Injectable({
  providedIn: 'root'
})

export class WpDataRetrieverService {
  constructor(
    private fileService: FileService,
    private httpClient: AxiosHttpClient
  ) { }

  public getBusinessRegistrationNumber(): Observable<string> {
    const CVR_REQUEST: CwWpModels.apiModels.IClientGetRequests = new CwWpModels.apiModels.IClientGetRequests([
      { property: 'ClientProfile', subProperty: 'EIN' }
    ]);
    return this.httpClient.post(CwWpConstantService.ENDPOINT_GET_CLIENT_DATA, CVR_REQUEST).pipe(
      map((cwResponse: CwWpModels.apiModels.ICwWpPostResponse) => {
        if (cwResponse.error || cwResponse.data[0].error) return null;
        return cwResponse.data[0].data;
      }),
      catchError(error => {
        throwError(() => error);
        return null;
      })
    );
  }

  public getPersonInformationObservable(person: CwWpModels.models.CvDataPersonModel): Observable<CwWpModels.models.CvDataPersonResponseModel> {
    const CV_DATA_GET_REQUESTS: CwWpModels.apiModels.ICvDataGetRequests = new CwWpModels.apiModels.ICvDataGetRequests(Object.values(person).map(e => e.cvData));
    const keys = Object.keys(person);
    return this.httpClient.post(CwWpConstantService.ENDPOINT_GET_CV_DATA, CV_DATA_GET_REQUESTS).pipe(
      map((cwResponse: CwWpModels.apiModels.ICwWpPostResponse) => {
        if (cwResponse.data[0].error) throw cwResponse;
        let personInformation: CwWpModels.models.CvDataPersonResponseModel = {};
        cwResponse.data.forEach((e, index) => {
          if (keys[index] && !e.error) personInformation[keys[index]] = e.data;
        });
        Object.values(personInformation).forEach((e, index) => {
          let personInfo = person[keys[index]];
          if (personInfo.splitFn) personInformation[keys[index]] = e.split(personInfo.splitFn.separator)[personInfo.splitFn.index];
          if (personInfo.switch) personInformation[keys[index]] = personInfo.switch[e] || personInfo.switch['default'] || personInformation[keys[index]];
          if (personInfo.mapFn) personInformation[keys[index]] = personInfo.mapFn(personInformation);
        });
        return personInformation;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }

  public getDocumentObservable(documentId: string): Observable<FileDetailsModel> {
    const DOCUMENT_GET_REQUESTS: CwWpModels.apiModels.IDocumentGetRequests = new CwWpModels.apiModels.IDocumentGetRequests([{ documentId: documentId }]);
    return this.httpClient.post(CwWpConstantService.ENDPOINT_GET_DOCUMENT, DOCUMENT_GET_REQUESTS).pipe(
      map((cwResponse: CwWpModels.apiModels.ICwWpPostResponse) => {
        if (cwResponse.data[0].error) throw cwResponse;
        if (!cwResponse.data[0].data) return null;
        cwResponse.data[0].data.lastModifiedDate = new Date(cwResponse.data[0].data.lastModifiedDate);
        cwResponse.data[0].data.type = this.fileService.conventFileExtensionToFileType(cwResponse.data[0].data.type);
        return cwResponse.data[0].data;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }
}