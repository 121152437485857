// Angular
import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { Subject, Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as DigitalSigningSelectors from '../_state/digital-signing.selectors';
import * as DigitalSigningState from '../_state';
import * as ErrorState from '../../error/_state';
import * as LayoutState from '../../layout/_state';
// Services
import { BusinessCoreRouteConstantService } from 'src/app/_services';
import { WpDataRetrieverService } from 'src/app/_services';
import { DialogService } from '../../../_services/dialog.service';
// Models
import * as ApiModels from '../_service/api-models';
import { SimpleDialogDataModel } from '../../_shared-components/_models';
import { Step } from 'src/app/_models/common';

interface IViewModel {
  licensedServiceMetaData: ApiModels.ResponseModels.DigitalSigningProviderLicensedServiceMetaDataModel;
  cvr: string;
  signingPackageTemplateTypeId: number;
  signingPackageTitle: string;
  emailSubject: string;
  emailText: string;
  signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[];
  documents: ApiModels.RequestModels.DigitalSigningProviderDocumentModel[];
  endAction: ApiModels.RequestModels.Enums.EndActionEnum;
  digitalSigningRequest: ApiModels.RequestModels.IDigitalSigningProviderRequestApiModel;
  signingResponse: ApiModels.ResponseModels.DigitalSigningProviderResponseData;
  loadingSubmission: boolean;
  activeStep: number;
  numberOfSteps: number;
  steps: Step[];
  showFormErrors: boolean;
  loadingProvider: boolean;
}

@Component({
  selector: 'app-digital-signing-submit',
  templateUrl: './digital-signing-submit.component.html',
  styleUrls: ['./digital-signing-submit.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: false,
      },
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DigitalSigningSubmitComponent implements OnInit, OnDestroy {
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
  model: IViewModel = {
    licensedServiceMetaData: null,
    cvr: null,
    signingPackageTemplateTypeId: null,
    signingPackageTitle: null,
    emailSubject: null,
    emailText: null,
    signers: null,
    documents: null,
    endAction: ApiModels.RequestModels.Enums.EndActionEnum.send,
    digitalSigningRequest: new ApiModels.RequestModels.IDigitalSigningProviderRequestApiModel(null, null, null, null, null, null, null),
    signingResponse: null,
    loadingSubmission: false,
    activeStep: 0,
    numberOfSteps: 0,
    steps: [
      { name: 'templatePicker', label: 'Underskriftsflow', completed: false, icon: 'crop_portrait' },
      { name: 'files', label: 'Vælg dokumenter', completed: false, icon: 'attach_file' },
      { name: 'persons', label: 'Vælg underskrivere', completed: false, icon: 'group' },
      { name: 'sendToSigning', label: 'Send', completed: false, icon: 'send', stepActivated: new Subject<boolean> },
    ],
    showFormErrors: false,
    loadingProvider: false,
  };
  subscriptions: Subscription[] = [];

  constructor(
    private wpDataService: WpDataRetrieverService,
    private dialogService: DialogService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.model.numberOfSteps = this.model.steps.length;

    this.store.dispatch(LayoutState.LayoutActions.setNavigationRoutes(
      {
        routes: [
          { label: 'Overblik forsendelser', route: BusinessCoreRouteConstantService.SIGNING_RETRIEVE },
          { label: 'Opret forsendelse', route: BusinessCoreRouteConstantService.SIGNING_SUBMISSION },
        ]
      }
    ));

    this.store.dispatch(DigitalSigningState.DigitalSigningActions.loadDigitalSigningProvider());

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.isLoadingSubmission).subscribe(
      loadingSubmission => this.model.loadingSubmission = loadingSubmission
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.isLoadingProvider).subscribe(
      loading => this.model.loadingProvider = loading
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getLicensedServiceMetaData).subscribe(
      licensedServiceMetaData => this.model.licensedServiceMetaData = licensedServiceMetaData
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getActiveStep).subscribe(
      activeStep => this.model.activeStep = activeStep
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getShowErrors).subscribe(
      showErrors => this.model.showFormErrors = showErrors
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getActiveDigitalSigningTemplate).subscribe(
      activeSigningTemplate => {
        this.model.signingPackageTemplateTypeId = activeSigningTemplate?.id;
      }
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getSigningPackageTitle).subscribe(
      signingPackageTitle => this.model.signingPackageTitle = signingPackageTitle
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getEmailSubject).subscribe(
      emailSubject => this.model.emailSubject = emailSubject
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getEmailText).subscribe(
      emailText => this.model.emailText = emailText
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getSigners).subscribe(
      signers => this.model.signers = signers
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getDocuments).subscribe(
      documents => this.model.documents = documents
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getSigningResponse).subscribe(
      signingResponse => {
        this.model.signingResponse = signingResponse;
        if (signingResponse) {
          var content = `
          <div><b>Forsendelsesnummer:</b> ${signingResponse?.digitalSigningCaseFileId}</div>
          <div><b>Underskriftsflow:</b> ${signingResponse?.digitalSigningCaseFileTypeName}</div>
          <div><b>Link:</b> <a href="${signingResponse?.digitalSigningManageUrl}" target="_blank">${signingResponse?.digitalSigningManageUrl}</a></div>`;
          if (this.model.endAction === ApiModels.RequestModels.Enums.EndActionEnum.send) {
            content +=
              `<div><br>Dokumenterne er nu sendt til underskrift. Via overstående link kan du se status på underskrivelse samt downloade dokumenterne.</div>`;
          } else {
            content +=
              `<div><br>Dokumenterne er nu oprettet til udkast. Via overstående link kan du færdiggøre og sende dokumenter til underskrift.</div>`;
          }
          this.dialogService.openSimpleDialog(new SimpleDialogDataModel(content, null, 'Gennemført', 'succes')).afterClosed().subscribe(() =>
            this.store.dispatch(DigitalSigningState.DigitalSigningActions.clearUploadedData())
          );
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.clearState());
  }

  navigatePrevious(): void {
    this.navigateToStep(this.model.activeStep - 1);
  }

  navigateNext(): void {
    this.navigateToStep(this.model.activeStep + 1);
  }

  navigateToStep(step: number): void {
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setActiveStep({ step: step }));
    if (step === this.model.numberOfSteps - 1) this.model.steps[step].stepActivated.next(true);
  }

  stepApproved(bool: boolean, index: number): void {
    this.model.steps[index].completed = bool;
  }

  submit(endAction: number): void {
    this.store.dispatch(ErrorState.ErrorActions.clearAllErrors());
    var errorMessage = this.model.steps.flatMap(step => !step.completed ? `<div><b>${step.label}:</b> er ikke udfyldt korrekt<div>` : []).join('');
    if (errorMessage.length > 0) {
      errorMessage += `<div><br>Du kan ikke sende til underskrift, før ovenstående er udfyldt korrekt</br></div>`;
      this.store.dispatch(DigitalSigningState.DigitalSigningActions.setShowErrors({ showErrors: true }));
      this.dialogService.openSimpleDialog(new SimpleDialogDataModel(errorMessage, null, 'Advarsel', 'warning'));
      return;
    }
    this.wpDataService.getBusinessRegistrationNumber().subscribe(
      wpCvrResponse => {
        if (!wpCvrResponse) {
          var errorMessage = `<div>Der er ikke angivet et CVR nummer for kunden<div><div>Udfyld CVR nummeret i Caseware - Engagementsegenskaber<div>`;
          errorMessage += `<div><br>Du kan ikke sende til underskrift, før ovenstående er udfyldt korrekt</br></div>`;
          this.dialogService.openSimpleDialog(new SimpleDialogDataModel(errorMessage, null, 'Advarsel', 'warning'));
        } else {
          this.model.endAction = endAction;
          this.store.dispatch(DigitalSigningState.DigitalSigningActions.submitDigitalSigningRequest({
            cvr: wpCvrResponse,
            signingPackageTemplateTypeId: this.model.signingPackageTemplateTypeId,
            signingPackageTitle: this.model.signingPackageTitle,
            emailSubject: this.model.emailSubject,
            emailText: this.model.emailText,
            signers: this.model.signers,
            documents: this.model.documents,
            endAction: this.model.endAction,
          }));
        }
      }
    );
  }
}
