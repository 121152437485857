// NgRx
import { createReducer, on } from '@ngrx/store';
// State
import * as AuthState from '.';
// Models
import * as AuthModels from '../_models';

export interface IAuthState {
    authUser: AuthModels.AuthenticationUser,
    authenticatingUser: boolean,
}

export const initialState: IAuthState = {
    authUser: null,
    authenticatingUser: false,
};

export const AuthReducer = createReducer<IAuthState>(
    initialState,

    // AuthActions
    on(AuthState.AuthActions.logout, (state): IAuthState => {
        return {
            ...state,
            authUser: null,
            authenticatingUser: false,
        };
    }),

    on(AuthState.AuthActions.loginUserCredentials, (state, actionArgs): IAuthState => {
        return {
            ...state,
            authUser: null,
            authenticatingUser: true,
        };
    }),

    on(AuthState.AuthActions.loginApiKey, (state, actionArgs): IAuthState => {
        return {
            ...state,
            authUser: null,
            authenticatingUser: true,
        };
    }),

    on(AuthState.AuthActions.autoLogin, (state, actionArgs): IAuthState => {
        return {
            ...state,
            authUser: null,
            authenticatingUser: true,
        };
    }),

    // AuthApiActions
    on(AuthState.AuthApiActions.loginSuccess, (state, actionArgs): IAuthState => {
        return {
            ...state,
            authUser: actionArgs.authUser,
            authenticatingUser: false,
        };
    }),

    on(AuthState.AuthApiActions.loginFailure, (state, actionArgs): IAuthState => {
        return {
            ...state,
            authUser: null,
            authenticatingUser: false,
        };
    }),
);