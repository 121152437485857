// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Http
import { AxiosHttpClient } from 'src/app/_http/axios-http-client';
// Services
import { BusinessCoreConstantService } from 'src/app/_services';
// Models
import * as ErrorModels from '../_models';
import { IErrorcodesResponseApiModel } from './api-models';


interface IViewModel {
    errorsTranslations: ErrorModels.ErrorClasses.ErrorTranslationModel[],
}

@Injectable({
    providedIn: 'root'
})

export class ErrorTranslationService {

    model: IViewModel = {
        errorsTranslations: null,
    };

    constructor(
        private httpClient: AxiosHttpClient,
    ) {
        this.getErrorcodes();
    }

    getErrorcodes(): Observable<IErrorcodesResponseApiModel> {
        return this.httpClient.get(BusinessCoreConstantService.ENDPOINT_ERRORCODES, false).pipe(
            timeout(environment.timeout),
            map((errorcodesResponse) => {
                var errorTranslations = errorcodesResponse.errorCodeModels.map(errorCode =>
                    new ErrorModels.ErrorClasses.ErrorTranslationModel(errorCode.errorCode, errorCode.errorCodeDescription)
                );
                this.model.errorsTranslations = errorTranslations;
                return errorcodesResponse;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    translateBusinessErrors(businessErrors: ErrorModels.ErrorClasses.BusinessError[]): ErrorModels.ErrorClasses.BusinessError[] {
        if (!this.model.errorsTranslations) return businessErrors;
        var translated = businessErrors.map(error => {
            let translatedError = this.model.errorsTranslations.find(translation => translation.errorCode === error.errorCode);
            let translatedMessage = translatedError ? translatedError.errorTranslation : error.errorMessage;
            return new ErrorModels.ErrorClasses.BusinessError(error.errorCode, translatedMessage);
        });
        return translated;
    }

    translateSystemErrors(systemErrors: ErrorModels.ErrorClasses.SystemError[]): ErrorModels.ErrorClasses.SystemError[] {
        if (!this.model.errorsTranslations) return systemErrors;
        var translated = systemErrors.map(error => {
            let translatedError = this.model.errorsTranslations.find(translation => translation.errorCode === error.errorCode);
            let translatedMessage = translatedError ? translatedError.errorTranslation : error.message;
            return new ErrorModels.ErrorClasses.SystemError(error.id, error.errorType, error.errorCode, translatedMessage);
        });
        return translated;
    }
}