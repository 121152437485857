<div class="card-container">
  <mat-card>
    <div class="image-container">
      <img *ngIf="cardInfo.imagePath" src={{cardInfo?.imagePath}}>
    </div>
    <mat-card-title>{{cardInfo?.title}}</mat-card-title>
    <mat-card-content>{{cardInfo?.description}}</mat-card-content>
    <mat-card-content class="warning-content-box" *ngIf="cardInfo?.validated === false"><mat-icon class="align-icon-with-text"
        iconType="warning"></mat-icon>{{cardInfo?.validateDescription}}
    </mat-card-content>
    <mat-card-actions>
      <button type="submit" [disabled]="!cardInfo?.validated ||cardInfo?.validated === null" mat-raised-button color="accent"
        (click)="cardInfo?.buttonFunction()">
        {{cardInfo?.buttonLabel}}
      </button>
    </mat-card-actions>
  </mat-card>
</div>