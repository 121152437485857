// NgRx
import { createReducer, on } from '@ngrx/store';
// Other
import * as ApiModels from '../_service/api-models';
import * as DigitalSigningState from '.';
import * as DigitalSigningApiModels from '../_service/api-models/response-models';
import * as DigitalSigningModels from '../_models';
import { LoadState } from 'src/app/_models/common/loadState';

export interface IDigitalSigningState {
    activeStep: number;
    showErrors: boolean;
    activeTemplate: DigitalSigningModels.DigitalSigningTemplateInformation;
    digitalSigningProviderLoadState: LoadState;
    licensedServiceMetaData: DigitalSigningApiModels.DigitalSigningProviderLicensedServiceMetaDataModel;
    digitalSigningTemplates: DigitalSigningModels.DigitalSigningTemplateInformation[];
    signingPackageTitle: string;
    emailSubject: string;
    emailText: string;
    signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[];
    documents: ApiModels.RequestModels.DigitalSigningProviderDocumentModel[];
    signingResponse: ApiModels.ResponseModels.DigitalSigningProviderResponseData;
    caseFiles: DigitalSigningModels.DigitalSigningCaseFileModel[];
    loadingSubmission: boolean;
    loadingCaseFiles: boolean;
    loadingEmailTemplates: boolean;
    loadingProvider: boolean;
}

// Create initial state object
export const initialState: IDigitalSigningState = {
    activeStep: 0,
    showErrors: false,
    activeTemplate: null,
    digitalSigningProviderLoadState: 'NOT_LOADED',
    licensedServiceMetaData: null,
    digitalSigningTemplates: null,
    signingPackageTitle: null,
    emailSubject: null,
    emailText: null,
    signers: null,
    documents: [],
    signingResponse: null,
    caseFiles: null,
    loadingSubmission: false,
    loadingCaseFiles: false,
    loadingEmailTemplates: false,
    loadingProvider: false,
};

export const DigitalSigningReducer = createReducer<IDigitalSigningState>(
    initialState,

    // DigitalSigningActions
    on(DigitalSigningState.DigitalSigningActions.clearState, (state, actionArgs): IDigitalSigningState => {
        return initialState;
    }),

    on(DigitalSigningState.DigitalSigningActions.setDigitalSigningProviderLoadState, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            digitalSigningProviderLoadState: actionArgs.loadState,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.loadDigitalSigningProvider, (state): IDigitalSigningState => {
        return {
            ...state,
            loadingProvider: state.digitalSigningProviderLoadState === 'LOADED' ? false : true,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.getDigitalSigningEmailTemplate, (state): IDigitalSigningState => {
        return {
            ...state,
            loadingEmailTemplates: true,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.setActiveStep, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            activeStep: actionArgs.step,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.setShowErrors, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            showErrors: actionArgs.showErrors,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.setActiveTemplateById, (state, actionArgs): IDigitalSigningState => {
        let activeTemplate = state.digitalSigningTemplates?.find(template => template.id === actionArgs.templateId);
        let documents: ApiModels.RequestModels.DigitalSigningProviderDocumentModel[] = JSON.parse(JSON.stringify(state.documents));
        documents?.map(d => { d.documentTypeId = null; return d; });
        let signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[] = JSON.parse(JSON.stringify(state.signers));
        signers?.map(s => { s.signerTypeIds = []; return s; });
        return {
            ...state,
            activeTemplate: activeTemplate,
            documents: documents,
            signers: signers,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.setSigningPackageTitle, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            signingPackageTitle: actionArgs.signingPackageTitle,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.setEmailSubject, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            emailSubject: actionArgs.emailSubject,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.setEmailText, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            emailText: actionArgs.emailText,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.setDocuments, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            documents: actionArgs.documents,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.setSigners, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            signers: actionArgs.signers,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.addSigner, (state, actionArgs): IDigitalSigningState => {
        let updatedSigners: ApiModels.RequestModels.DigitalSigningProviderSignerModel[] = [];

        if (state.signers?.length > 0) updatedSigners = [...state.signers];

        if (Array.isArray(actionArgs.signer)) { // handle multiple signers
            if (actionArgs.index) {
                updatedSigners = [
                    ...updatedSigners.slice(0, actionArgs.index),
                    ...actionArgs.signer,
                    ...updatedSigners.slice(actionArgs.index)
                ];
            } else {
                updatedSigners.push(...actionArgs.signer);
            }
        } else { // handle one signer
            actionArgs.index ? updatedSigners.splice(actionArgs.index, 0, actionArgs.signer) : updatedSigners.push(actionArgs.signer);
        }

        return {
            ...state,
            signers: updatedSigners,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.updateSigner, (state, actionArgs): IDigitalSigningState => {
        let updatedSigners: ApiModels.RequestModels.DigitalSigningProviderSignerModel[] = [...state.signers];
        updatedSigners.splice(actionArgs.index, 1, actionArgs.updatedSigner);

        return {
            ...state,
            signers: updatedSigners,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.removeSigner, (state, actionArgs): IDigitalSigningState => {
        let updatedSigners: ApiModels.RequestModels.DigitalSigningProviderSignerModel[] = [...state.signers];
        updatedSigners.splice(actionArgs.index, 1);

        return {
            ...state,
            signers: updatedSigners,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.clearEmptySigners, (state): IDigitalSigningState => {
        let updatedSigners: ApiModels.RequestModels.DigitalSigningProviderSignerModel[] = [...state.signers];
        updatedSigners = updatedSigners.filter(signer => signer.name || signer.email || signer.signerTypeIds);
        return {
            ...state,
            signers: updatedSigners,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.submitDigitalSigningRequest, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            loadingSubmission: true,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.clearUploadedData, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            activeStep: 0,
            showErrors: false,
            activeTemplate: null,
            signingPackageTitle: null,
            emailSubject: null,
            emailText: null,
            signers: null,
            documents: [],
            signingResponse: null,
        };
    }),

    on(DigitalSigningState.DigitalSigningActions.loadCaseFiles, (state): IDigitalSigningState => {
        return {
            ...state,
            loadingCaseFiles: true,
        };
    }),

    // DigitalSigningApiActions
    on(DigitalSigningState.DigitalSigningApiActions.loadDigitalSigningProviderSuccess, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            digitalSigningProviderLoadState: 'LOADED',
            licensedServiceMetaData: actionArgs.digitalSigningProvider?.licensedServiceMetaData,
            digitalSigningTemplates: actionArgs.digitalSigningProvider?.signingTemplates,
            loadingProvider: false,
        };
    }),

    on(DigitalSigningState.DigitalSigningApiActions.loadDigitalSigningProviderFailure, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            digitalSigningProviderLoadState: 'NOT_LOADED',
            licensedServiceMetaData: null,
            digitalSigningTemplates: null,
            loadingProvider: false,
        };
    }),

    on(DigitalSigningState.DigitalSigningApiActions.getDigitalSigningEmailTemplateSuccess, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            loadingEmailTemplates: false,
        };
    }),

    on(DigitalSigningState.DigitalSigningApiActions.getDigitalSigningEmailTemplateFailure, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            loadingEmailTemplates: false,
        };
    }),

    on(DigitalSigningState.DigitalSigningApiActions.submitDigitalSigningRequestSuccess, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            loadingSubmission: false,
            signingResponse: actionArgs.signingResponse,
        };
    }),

    on(DigitalSigningState.DigitalSigningApiActions.submitDigitalSigningRequestFailure, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            loadingSubmission: false,
            signingResponse: null,
        };
    }),

    on(DigitalSigningState.DigitalSigningApiActions.loadCaseFilesSuccess, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            loadingCaseFiles: false,
            caseFiles: actionArgs.caseFiles,
        };
    }),

    on(DigitalSigningState.DigitalSigningApiActions.loadCaseFilesFailure, (state, actionArgs): IDigitalSigningState => {
        return {
            ...state,
            loadingCaseFiles: false,
            caseFiles: [],
        };
    }),
);