import { Component, OnInit, OnDestroy } from '@angular/core';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// Other
import * as ContactpersonModels from '../_models';
import * as ContactpersonSelectors from '../_state/contactperson.selectors';
import * as ContactpersonState from '../_state';

interface IViewModel {
  noType: any[];
  bestyrelse: any[];
  contactpersonsFromBusinessConnect: ContactpersonModels.CasewareContactperson[];
  direktion: any[];
  loadContactpersonInfo: boolean;
  updatingContactpersonInfo: boolean;
  working: boolean;
}

@Component({
  selector: 'app-contactperson',
  templateUrl: './contactperson.component.html',
  styleUrls: ['./contactperson.component.scss']
})
export class ContactpersonComponent implements OnInit, OnDestroy {

  model: IViewModel = {
    noType: [],
    bestyrelse: [],
    contactpersonsFromBusinessConnect: [],
    direktion: [],
    loadContactpersonInfo: false,
    updatingContactpersonInfo: false,
    working: false,
  };

  subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(ContactpersonSelectors.getUpdatingContactpersonInfo).subscribe(
      updatingContactpersonInfo => {
        this.model.updatingContactpersonInfo = updatingContactpersonInfo;
      }
    ));
    this.subscriptions.push(this.store.select(ContactpersonSelectors.getContactPerson).subscribe(
      contactperson => {
        this.model.contactpersonsFromBusinessConnect = contactperson;
        this.model.noType = contactperson.map(contactperson => {
          return {
            name: contactperson.name,
            role: contactperson.role,
            title: contactperson.title,
            sourceDescription: contactperson.sourceDescription
          };
        });
      }
    ));

    this.store.dispatch(ContactpersonState.ContactpersonActions.getContactpersonInformationFromBusinessConnect());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  drop(event: CdkDragDrop<ContactpersonModels.CasewareContactperson[]>, type: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.model[type][event.currentIndex].role = type;
    console.log(this.model[type][event.currentIndex]);
    console.log(this.model[type][event.currentIndex].role);
  }

  updateContactpersonInformation() {
    console.log(this.model.contactpersonsFromBusinessConnect);
    this.store.dispatch(ContactpersonState.ContactpersonActions.updateContactpersonInformation());
  }
}
