import * as AppState from 'src/app/_state/app.state';
import * as ErstActions from './_actions/erst.actions';
import * as ErstApiActions from './_actions/erst-api.actions';

export {
    ErstActions,
    ErstApiActions,
};

export interface State extends AppState.State {
}