// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout, tap } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// HTTP
import { AxiosHttpClient } from '../../../_http/axios-http-client';
// Services
import * as AppServices from '../../../_services/';
import { UserRoleMappingService } from './user-role-mapping.service';
// Models
import * as ApiModels from './api-models';
import * as AuthModels from '../_models';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {

  constructor(
    private businessCoreTokenDecoderService: AppServices.BusinessCoreTokenDecoderService,
    private httpClient: AxiosHttpClient,
    private localRegistryService: AppServices.LocalRegistryService,
    private sessionStorageConstantService: AppServices.SessionStorageConstantService,
    private userRoleMappingService: UserRoleMappingService,
  ) { }

  loginUserCredentials(username: string, password: string): Observable<AuthModels.AuthenticationUser> {
    let body = new ApiModels.IUserCredentialsRequestApiModel(username, password, AppServices.BusinessCoreConstantService.BUSINESS_CONNECT_WP_CLIENT_UID);
    return this.httpClient.post(AppServices.BusinessCoreConstantService.ENDPOINT_USER_CREDENTIALS_TOKEN, body).pipe(
      timeout(environment.timeout),
      map((authenticationResponse) => {
        let authUser: AuthModels.AuthenticationUser = this.mapAuthenticationResponseToAuthenticationUser(authenticationResponse);
        return authUser;
      }),
      tap(authenticationResponse => {
        if (sessionStorage.getItem(this.sessionStorageConstantService.REMEMBER_ME)) this.storeApiKeyInLocalRegistry(authenticationResponse.apiKey);
      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  loginApiKey(apiKey: string, skipDispatchOfErrors?: boolean): Observable<AuthModels.AuthenticationUser> {
    if (skipDispatchOfErrors === undefined) skipDispatchOfErrors = false;
    let body = new ApiModels.IApiKeyRequestApiModel(apiKey, AppServices.BusinessCoreConstantService.BUSINESS_CONNECT_WP_CLIENT_UID);
    return this.httpClient.post(AppServices.BusinessCoreConstantService.ENDPOINT_API_KEY_TOKEN, body, false, skipDispatchOfErrors).pipe(
      timeout(environment.timeout),
      map((authenticationResponse) => {
        let authUser: AuthModels.AuthenticationUser = this.mapAuthenticationResponseToAuthenticationUser(authenticationResponse);
        return authUser;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  retrieveRememberedApiKey(): Observable<string> {
    return this.localRegistryService.read({ requests: [{ path: AppServices.LocalRegistryConstantService.REGISTRY_API_KEY_PATH }] }).pipe(
      timeout(environment.timeout),
      map((wpResponse) => !wpResponse.error && !wpResponse.data[0].error ? wpResponse.data[0].data : null),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  storeApiKeyInLocalRegistry(apiKey: string): void {
    this.localRegistryService.store(
      { requests: [{ path: AppServices.LocalRegistryConstantService.REGISTRY_API_KEY_PATH, value: apiKey, type: 'REG_SZ' }] }
    ).subscribe();
  }

  // todo : refactor
  removeApiKeyFromLocalRegistry(): void {
    this.localRegistryService.delete({ requests: [{ path: AppServices.LocalRegistryConstantService.REGISTRY_API_KEY_PATH }] }).subscribe();
  }

  // Private methods
  private mapAuthenticationResponseToAuthenticationUser(authenticationResponse: ApiModels.IAuthenticationResponseApiModel): AuthModels.AuthenticationUser {
    let authUser: AuthModels.AuthenticationUser = null;

    try {
      let tokenInfo = this.businessCoreTokenDecoderService.getTokenInfo(authenticationResponse.token);

      let userRoles: AuthModels.UserRole[] = [];
      tokenInfo.applicationUserRoles.forEach(applicationUserRole => {
        userRoles.push(this.userRoleMappingService.mapApplicationUserRole2UserRole(applicationUserRole));
      });

      authUser = new AuthModels.AuthenticationUser(
        tokenInfo.applicationUserApiKey,
        tokenInfo.applicationUserName,
        authenticationResponse.token,
        authenticationResponse.expiration,
        userRoles,
      );

    } catch (error) {
      console.error(error);
      return null;
    }
    return authUser;
  }
}
