import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// Other
import * as CompanySelectors from '../_state/company.selectors';
import * as CompanyState from '../_state';

interface IViewModel {
  updatingCompanyInfo: boolean;
}

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  model: IViewModel = {
    updatingCompanyInfo: false
  };
  subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(CompanySelectors.getUpdatingCompanyInfo).subscribe(
      updatingCompanyInfo => {
        this.model.updatingCompanyInfo = updatingCompanyInfo;
      }
    ));
  }

  updateCompanyInformation() {
    // this.companyService.updateEngagementData().subscribe(response => console.log(response));
    this.store.dispatch(CompanyState.CompanyActions.updateCompanyInformation());
  }
}
