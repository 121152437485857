// Services
import { UserInputValidationService } from 'src/app/_services';
// Models
import { CvDataGetModel } from './CvDataGetModel';

export class CvDataPersonModel {
  [name: string]: CvDataGetModel;
  constructor(configObject) {
    Object.entries(configObject).forEach(e => {
      if (validateInput(e)) this[e[0]] = new CvDataGetModel(e[1]);
    }
    );
  }
}

function validateInput(input: any): boolean {
  let keyIsString: boolean = false;
  let valueIsObject: boolean = false;

  keyIsString = UserInputValidationService.stringInput(input[0], 'CvDataPersonModel');
  valueIsObject = UserInputValidationService.objectInput(input[1], 'CvDataPersonModel');

  if (!keyIsString || !valueIsObject) return false;
  return true;
}
