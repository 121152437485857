// NgRx
import { createAction, props } from '@ngrx/store';
// Other
import { ContactpersonSourceApiModel } from '../../_service/api-models/response-models/ContactpersonSourceApiModel';
import * as ContactpersonModels from '../../_models';

export const GetContactpersonSourceProviderSuccess = createAction(
  '[Contactperson Api] Get contactperson source provider Success',
  props<{ contactpersonSourceProvider: ContactpersonSourceApiModel; }>()
);

export const GetContactpersonSourceProviderFailure = createAction(
  '[Contactperson Api] Get contactperson source provider Failure'
);

export const getContactpersonInformationFromBusinessConnectSuccess = createAction(
  '[Contactperson Api] Get contact person Information from Business Connect Success',
  props<{ contactperson: ContactpersonModels.CasewareContactperson[]; }>()
);

export const getContactpersonInformationFromBusinessConnectFailure = createAction(
  '[Contactperson Api] Get contact person Information from Business Connect Failure'
);

export const updateContactpersonInformationSuccess = createAction(
  '[Contactperson Api] Update contactperson information Success'
);

export const updateContactpersonInformationFailure = createAction(
  '[Contactperson Api] Update contactperson information Failure'
);