// NgRx
import { createReducer, on } from '@ngrx/store';
// State
import * as LayoutState from './';
// Models
import { NavigationButtonModel } from '../_models/navigationButtonModel';


export interface ILayoutState {
    navigationRoutes: NavigationButtonModel[];
}

// Create initial state object
export const initialState: ILayoutState = {
    navigationRoutes: [],
};

export const LayoutReducer = createReducer<ILayoutState>(
    initialState,

    // LayoutActions
    on(LayoutState.LayoutActions.setNavigationRoutes, (state, actionArgs): ILayoutState => {
        return {
            ...state,
            navigationRoutes: actionArgs.routes,
        };
    }),
);