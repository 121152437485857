// NgRx
import { createReducer, on } from '@ngrx/store';
// Other
import * as CompanyState from './';
import { CompanySourceApiModel } from '../_service/api-models/response-models/CompanySourceApiModel';

export interface ICompanyState {
  updatingCompanyInfo: boolean,
  companySourceProvider: CompanySourceApiModel;
  isLoading: boolean,
}
export const initialState: ICompanyState = {
  updatingCompanyInfo: false,
  companySourceProvider: null,
  isLoading: false,
};

export const CompanyReducer = createReducer<ICompanyState>(
  initialState,
  // companyActions
  on(CompanyState.CompanyActions.GetCompanySourceProvider, (state): ICompanyState => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(CompanyState.CompanyActions.updateCompanyInformation, (state): ICompanyState => {
    return {
      ...state,
      updatingCompanyInfo: true
    };
  }),

  // UserChangePasswordApiActions
  on(CompanyState.CompanyApiActions.GetCompanySourceProviderSuccess, (state, actionArgs): ICompanyState => {
    return {
      ...state,
      companySourceProvider: actionArgs.companySourceProvider,
      isLoading: false,
    };
  }),

  on(CompanyState.CompanyApiActions.GetCompanySourceProviderFailure, (state): ICompanyState => {
    return {
      ...state,
      companySourceProvider: null,
      isLoading: false,
    };
  }),

  on(CompanyState.CompanyApiActions.updateCompanyInformationSuccess, (state): ICompanyState => {
    return {
      ...state,
      updatingCompanyInfo: false,
    };
  }),

  on(CompanyState.CompanyApiActions.updateCompanyInformationFailure, (state): ICompanyState => {
    return {
      ...state,
      updatingCompanyInfo: false,
    };
  }),
);