// Angular
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export abstract class CwWpErrorConstantService {
  public static readonly VersionWrongFormat = 'CSP version nummer er af forkert format';
  public static readonly BadClientRequest = 'Kan ikke hente engagement information';
  public static readonly BadClientStoreRequest = 'Kan ikke gemme engagement information';
  public static readonly BadCvRequest = 'Kan ikke hente cv data';
  public static readonly BadCvStoreRequest = 'Kan ikke gemme cv data';
  public static readonly BadDocumentRequest = 'Kan ikke hente dokumenter';
  public static readonly BadDocumentStoreRequest = 'Kan ikke gemme dokumenter';
  public static readonly BadFilesDeleteRequest = 'Kan ikke slette filer';
  public static readonly BadFilesReadRequest = 'Kan ikke hente data fra filer';
  public static readonly BadFilesRequest = 'Kan ikke hente filer';
  public static readonly BadFilesStoreRequest = 'Kan ikke gemme i filer';
  public static readonly BadRegistryDeleteRequest = 'Kan ikke slette i lokalt register';
  public static readonly BadRegistryRequest = 'Kan ikke hente i lokalt register';
  public static readonly BadRegistryStoreRequest = 'Kan ikke gemme i lokalt register';
  public static readonly BadVersionRequest = 'Kan ikke hente lokal csp version';
}
