// Angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// Environment
import { environment } from '../../environments/environment';
// Axios
import axios from 'axios';
// Auth
import * as AuthModels from '../components/authentication/_models';
import * as AuthSelectors from '../components/authentication/_state/auth.selectors';
import * as AuthState from '../components/authentication/_state';
// Service
import { BusinessCoreConstantService } from '../_services';
import { ErrorDispatchService } from '../_services';
import { ErrorService } from '../_services/error.service';

@Injectable({
  providedIn: 'root'
})

export class AxiosHttpClient {
  http = axios;
  authUser: AuthModels.AuthenticationUser = null;
  token: string = null;
  urlsWithAuthorization: string[] = [];
  urlsWithSkipDispatchOfErrors: string[] = [];

  constructor(
    private errorDispatchService: ErrorDispatchService,
    private errorService: ErrorService,
    private store: Store<AuthState.State>,
  ) {
    this.store.select(AuthSelectors.getAuthUser).subscribe(
      authUser => {
        this.authUser = authUser;
      }
    );

    let scope = this;

    // Interceptor for http requests 
    this.http.interceptors.request.use(async function (config) {

      // data that needs to be stringified
      if (config.data && !config.url.includes(environment.host)) {
        config.data = JSON.stringify(config.data);
      }

      // if request dont need authorization: return here
      if (!scope.urlsWithAuthorization.find(requestUrl => requestUrl === config.url)) return config;

      // get authorization token
      let token = await scope.getToken();

      // set authorization header
      config.headers['Authorization'] = 'Bearer ' + token;
      return config;
    });

    // Interceptor for http response 
    this.http.interceptors.response.use(function (response) {
      let bcError: boolean = false;
      let cwError: boolean = false;
      let skipDispatchOfErrors: boolean = false;

      // Determine, if dispatch of errors should be skipped
      skipDispatchOfErrors = scope.urlsWithSkipDispatchOfErrors.find(requestUrl => requestUrl === response.config.url) !== undefined;

      // control and handle business connect errors
      if (response.request?.responseURL?.includes(environment.host)) {
        bcError = scope.errorService.handleBusinessConnectErrors(response.data, skipDispatchOfErrors);
        // throw error if business connect errors are present 
        if (bcError) throw response.data;
      }

      // control and handle caseware csp errors
      if (response.request?.responseURL?.includes('cw-wp')) {
        cwError = scope.errorService.handleCwWpErrors(response.data, skipDispatchOfErrors);
        // throw error if caseware errors are present 
        if (cwError) throw response.data;
      }

      // return response if no errors are found
      return response;

    }, function (error: any) {
      let bcError: boolean = false;
      let skipDispatchOfErrors: boolean = false;

      // Determine, if dispatch of errors should be skipped
      skipDispatchOfErrors = scope.urlsWithSkipDispatchOfErrors.find(requestUrl => requestUrl === error.request.responseURL) !== undefined;

      // control and handle business connect errors
      if (error.request?.responseURL?.includes(environment.host))
        bcError = scope.errorService.handleBusinessConnectErrors(error.response?.data, skipDispatchOfErrors);
      if (bcError) return Promise.reject(error.response?.data);

      // if no business connect errors are present, handle error
      if (!bcError) scope.errorService.handleHttpErrors(error);

      // reject promise with error
      return Promise.reject(error);
    });
  }

  // wrap axios get request in an Observable
  get(url: string, authorization?: boolean, skipDispatchOfErrors?: boolean): Observable<any> {
    if (authorization && this.urlsWithAuthorization.indexOf(url) === -1) this.urlsWithAuthorization.push(url);
    if (skipDispatchOfErrors && this.urlsWithSkipDispatchOfErrors.indexOf(url) === -1) this.urlsWithSkipDispatchOfErrors.push(url);
    return new Observable<any>((subscriber) => {
      axios.get(url)
        .then(response => subscriber.next(response.data))
        .catch(error => subscriber.error(error))
        .finally(() => subscriber.complete());
    });
  }

  // wrap axios post request in an Observable
  post(url: string, data: object, authorization?: boolean, skipDispatchOfErrors?: boolean): Observable<any> {
    if (authorization && this.urlsWithAuthorization.indexOf(url) === -1) this.urlsWithAuthorization.push(url);
    if (skipDispatchOfErrors && this.urlsWithSkipDispatchOfErrors.indexOf(url) === -1) this.urlsWithSkipDispatchOfErrors.push(url);
    return new Observable<any>((subscriber) => {
      axios.post(url, data)
        .then(response => subscriber.next(response.data))
        .catch(error => subscriber.error(error))
        .finally(() => subscriber.complete());
    });
  }

  private async getToken(): Promise<string> {
    let tokenExpired = true;
    if (this.authUser && this.authUser.expiration && this.authUser.token) tokenExpired = new Date(this.authUser.expiration) < new Date();
    if (tokenExpired) {
      let response = await this.refreshToken(this.authUser.apiKey);
      return response.data.token;
    } else {
      return this.authUser.token;
    }
  }

  private async refreshToken(apiKey: string): Promise<any> {
    return axios.post(
      BusinessCoreConstantService.ENDPOINT_API_KEY_TOKEN, { apikey: apiKey, applicationClientUID: BusinessCoreConstantService.BUSINESS_CONNECT_WP_CLIENT_UID });
  }
}
