import { NgModule, Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[iconType]'
})
export class IconTypeDirective {
  @Input() iconType: string;

  constructor(
    private icon: MatIcon,
  ) {
    setTimeout(() => { this.setIcon(this.iconType); });
  }

  setIcon(iconType: string) {
    switch (iconType) {
      case 'close':
        this.icon.fontIcon = 'close';
        break;
      case 'error':
        this.icon.fontIcon = 'cancel';
        break;
      case 'file':
        this.icon.fontIcon = 'attach_file';
        break;
      case 'info':
        this.icon.fontIcon = 'info';
        break;
      case 'remove':
        this.icon.fontIcon = 'close';
        break;
      case 'succes':
        this.icon.fontIcon = 'check_circle';
        break;
      case 'warning':
        this.icon.fontIcon = 'error';
        break;
      default:
        throw Error(`34e59697-d064-4277-bcf0-be5009372105 - The function [setIcon] does not recognize the type: ${iconType}`);
    }
  }
}

@Directive({
  selector: '[iconText], [iconTextPlacement]'
})
export class IconTextDirective implements OnInit {
  @Input() iconText: string;
  @Input() iconTextPlacement: string;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    //this creates the wrapping div
    const NEW_PARENT_DIV = this.renderer.createElement('div');
    NEW_PARENT_DIV.style = "display: flex; align-items: center;";

    //this creates the text line
    const DIV_ICON_TEXT = this.renderer.createElement('div');
    const ICON_TEXT = this.renderer.createText(this.iconText);
    this.renderer.appendChild(DIV_ICON_TEXT, ICON_TEXT);

    const ELEMENT = this.element.nativeElement; //this is the element to wrap
    ELEMENT.style = `font-size: 20px; width: 20px; height: 20px; ${this.iconTextPlacement === 'after' ? 'margin-left:' : 'margin-right:'}0.25rem`;
    const PARENT = ELEMENT.parentNode; //this is the parent containing el
    this.renderer.insertBefore(PARENT, NEW_PARENT_DIV, ELEMENT); //here we place div before el

    if (this.iconTextPlacement === 'after') {
      this.renderer.appendChild(NEW_PARENT_DIV, DIV_ICON_TEXT); //here we append the second line in div, after el
      this.renderer.appendChild(NEW_PARENT_DIV, ELEMENT); //here we place el in div
    } else {
      this.renderer.appendChild(NEW_PARENT_DIV, ELEMENT); //here we place el in div
      this.renderer.appendChild(NEW_PARENT_DIV, DIV_ICON_TEXT); //here we append the second line in div, after el
    }
  }
}

@NgModule({
  declarations: [IconTextDirective, IconTypeDirective],
  exports: [IconTextDirective, IconTypeDirective]
})
export class IconDirective { }