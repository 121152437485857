// NgRx
import { createAction, props } from '@ngrx/store';
import { CompanySourceApiModel } from '../../_service/api-models/response-models/CompanySourceApiModel';

export const GetCompanySourceProviderSuccess = createAction(
  '[Company Api] Get company source provider Success',
  props<{ companySourceProvider: CompanySourceApiModel; }>()
);

export const GetCompanySourceProviderFailure = createAction(
  '[Company Api] Get company source provider Failure'
);

export const updateCompanyInformationSuccess = createAction(
  '[Company Api] Update company information Success'
);

export const updateCompanyInformationFailure = createAction(
  '[Company Api] Update company information Failure'
);