// NgRx
import { createReducer, on } from '@ngrx/store';

import * as UpdateState from './';


export interface IUpdateState {
    isCspUpdateRequired: boolean;
    checkingForUpdate: boolean;
}

// Create initial state object
export const initialState: IUpdateState = {
    isCspUpdateRequired: null,
    checkingForUpdate: false,
};

export const UpdateReducer = createReducer<IUpdateState>(
    initialState,

    // UpdateActions
    on(UpdateState.UpdateActions.isCspUpdateRequired, (state): IUpdateState => {
        return {
            ...state,
            checkingForUpdate: true,
        };
    }),

    // UpdateApiActions
    on(UpdateState.UpdateApiActions.isCspUpdateRequiredSuccess, (state, actionArgs): IUpdateState => {
        return {
            ...state,
            isCspUpdateRequired: actionArgs.updateRequired,
            checkingForUpdate: false,
        };
    }),

    on(UpdateState.UpdateApiActions.isCspUpdateRequiredFailure, (state): IUpdateState => {
        return {
            ...state,
            isCspUpdateRequired: false,
            checkingForUpdate: false,
        };
    }),
);