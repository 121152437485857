// NgRx
import { createFeatureSelector, createSelector, } from '@ngrx/store';
// Other
import { IErrorState } from './error.reducer';


const getErrorFeatureState = createFeatureSelector<IErrorState>('error');

export const getBusinessErrors = createSelector(
    getErrorFeatureState,
    state => state.businessErrors
);

export const getClientErrors = createSelector(
    getErrorFeatureState,
    state => state.clientErrors
);

export const getCwWpErrors = createSelector(
    getErrorFeatureState,
    state => state.cwWpErrors
);

export const getHttpErrors = createSelector(
    getErrorFeatureState,
    state => state.httpErrors
);

export const getSystemErrors = createSelector(
    getErrorFeatureState,
    state => state.systemErrors
);
