// Other
import * as AppState from 'src/app/_state/app.state';
import * as CompanyActions from './_actions/company.actions';
import * as CompanyApiActions from './_actions/company-api.actions';

export {
    CompanyActions,
    CompanyApiActions,
};

export interface State extends AppState.State {
}