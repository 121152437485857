// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Angular Material
import { MaterialModule } from '../../_material/material.module';

// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// Component
import { ContactpersonComponent } from './contactperson-component/contactperson.component';

// Other
import { ContactpersonEffects } from './_state/contactperson.effects';
import { ContactpersonReducer } from './_state/contactperson.reducer';

@NgModule({
  imports: [
    BrowserModule,
    DragDropModule,
    MaterialModule,
    StoreModule.forFeature('contactperson', ContactpersonReducer),
    EffectsModule.forFeature([ContactpersonEffects]),
  ],
  declarations: [
    ContactpersonComponent,
  ],
  exports: [],
})
export class ContactpersonModule { }