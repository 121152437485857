// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
// Angular Material
import { MaterialModule } from 'src/app/_material/material.module';
import { SharedComponentsModule } from 'src/app/components/_shared-components/shared-components.module';
// Components
import { StepCertifikatComponent } from './erst-submission-step-certifikat/erst-submission-step-certifikat.component';
import { StepIndsendComponent } from './erst-submission-step-indsend/erst-submission-step-indsend.component';
import { StepRegnskaberComponent } from './erst-submission-step-regnskaber/erst-submission-step-regnskaber.component';
// Directives
import { IconDirective } from 'src/app/_directive/icon.directive';
import { EllipsisDirective } from 'src/app/_directive/ellipsis-tooltip.directive';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    MaterialModule,
    SharedComponentsModule,
    IconDirective,
    EllipsisDirective,
  ],
  declarations: [
    StepCertifikatComponent,
    StepIndsendComponent,
    StepRegnskaberComponent,
  ],
  exports: [
    StepCertifikatComponent,
    StepIndsendComponent,
    StepRegnskaberComponent,
  ]
})
export class ErhvervsstyrelsenStepModule { }