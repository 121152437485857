// NgRx
import { createReducer, on } from '@ngrx/store';
// State
import * as LicenseState from '.';
// Models
import { LoadState } from 'src/app/_models/common';
import { LicensedServicesModel } from '../_models/LicensedServicesModel';


export interface ILicenseState {
    availableLicensedServices: LicensedServicesModel[];
    availableLicensedServicesLoadState: LoadState;
    digitalSigningLicensedService: LicensedServicesModel;
    erstLicensedService: LicensedServicesModel;
    loadingLicenses: boolean;
}

// Create initial state object
export const initialState: ILicenseState = {
    availableLicensedServices: [],
    availableLicensedServicesLoadState: 'NOT_LOADED',
    digitalSigningLicensedService: null,
    erstLicensedService: null,
    loadingLicenses: false,
};

export const LicenseReducer = createReducer<ILicenseState>(
    initialState,

    // LicensActions
    on(LicenseState.LicenseActions.clearState, (state, actionArgs): ILicenseState => {
        return initialState;
    }),

    on(LicenseState.LicenseActions.getAvailableLicensedServices, (state, actionArgs): ILicenseState => {
        return {
            ...state,
            loadingLicenses: state.availableLicensedServicesLoadState === 'LOADED' ? false : true,
        };
    }),

    on(LicenseState.LicenseActions.setAvailableLicensedServicesLoadState, (state, actionArgs): ILicenseState => {
        return {
            ...state,
            availableLicensedServicesLoadState: actionArgs.state
        };
    }),

    on(LicenseState.LicenseActions.setDigitalSigningLicensedService, (state, actionArgs): ILicenseState => {
        return {
            ...state,
            digitalSigningLicensedService: actionArgs.licensedService,
        };
    }),

    on(LicenseState.LicenseActions.setErstLicensedService, (state, actionArgs): ILicenseState => {
        return {
            ...state,
            erstLicensedService: actionArgs.licensedService,
        };
    }),

    // LicensApiActions
    on(LicenseState.LicenseApiActions.getAvailableLicensedServicesSuccess, (state, actionArgs): ILicenseState => {
        if (JSON.stringify(actionArgs.availableLicensedServices) === JSON.stringify(state.availableLicensedServices))
            return { ...state, loadingLicenses: false };

        return {
            ...state,
            availableLicensedServices: actionArgs.availableLicensedServices,
            availableLicensedServicesLoadState: 'LOADED',
            loadingLicenses: false

        };
    }),

    on(LicenseState.LicenseApiActions.getAvailableLicensedServicesFailure, (state): ILicenseState => {
        if (state.availableLicensedServices === null)
            return { ...state, loadingLicenses: false };
        return {
            ...state,
            availableLicensedServices: null,
            availableLicensedServicesLoadState: 'NOT_LOADED',
            loadingLicenses: false
        };
    }),

    on(LicenseState.LicenseApiActions.licenseValidatedSuccess, (state, actionArgs): ILicenseState => {
        let entry = Object.entries(state).find(e => e[1]?.licensedServiceId === actionArgs.licensedServiceId);
        let licens = { ...entry[1] };
        licens.licensedServiceUserValidated = true;
        return {
            ...state,
            [entry[0]]: licens,
        };
    }),

    on(LicenseState.LicenseApiActions.licenseValidatedFailure, (state, actionArgs): ILicenseState => {
        let entry = Object.entries(state).find(e => e[1]?.licensedServiceId === actionArgs.licensedServiceId);
        let licens = { ...entry[1] };
        licens.licensedServiceUserValidated = false;
        return {
            ...state,
            [entry[0]]: licens,
        };
    }),
);