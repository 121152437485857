<mat-form-field class="time-input-container" (click)="selectHours()">
  <div class="time-input">
    <div class="time-input">
      <input #hoursInput matInput class="hours" maxlength="2" [formControl]="hours" (click)="selectHours()" (input)="onChangeHours()"
        (blur)="onBlurHours()">
      :
      <input #minutesInput matInput class="minuts" maxlength="2" [formControl]="minutes" (click)="selectMinutes()"
        (input)="onChangeMinutes()" (blur)="onBlurMinutes()">
    </div>
  </div>
</mat-form-field>