import { IClientGetRequests } from './request-models/client/IClientGetRequests';
import { IClientStoreRequests } from './request-models/client/IClientStoreRequests';
import { ICvDataGetRequests } from './request-models/cvdata/ICvDataGetRequests';
import { ICvDataStoreRequests } from './request-models/cvdata/ICvDataStoreRequests';
import { IDocumentGetRequests } from './request-models/document/IDocumentGetRequests';
import { IDocumentStoreRequests } from './request-models/document/IDocumentStoreRequests';
import { FilesGetRequest } from './request-models/files/FilesGetRequest';
import { FilesReadRequest } from './request-models/files/FilesReadRequest';
import { FilesStoreRequest } from './request-models/files/FilesStoreRequest';
import { IFilesGetRequests } from './request-models/files/IFilesGetRequests';
import { IFilesReadRequests } from './request-models/files/IFilesReadRequests';
import { IFilesStoreRequests } from './request-models/files/IFilesStoreRequests';
import { IRegistryDeleteRequests } from './request-models/local-registry/IRegistryDeleteRequests';
import { IRegistryGetRequests } from './request-models/local-registry/IRegistryGetRequests';
import { IRegistryStoreRequests } from './request-models/local-registry/IRegistryStoreRequests';
import { ICwWpResponse } from './response-models/ICwWpResponse';
import { ICwWpGetResponse } from './response-models/ICwWpGetResponse';
import { ICwWpPostResponse } from './response-models/ICwWpPostResponse';

export {
  IClientGetRequests,
  IClientStoreRequests,
  ICvDataGetRequests,
  ICvDataStoreRequests,
  IDocumentGetRequests,
  IDocumentStoreRequests,
  FilesGetRequest,
  FilesReadRequest,
  FilesStoreRequest,
  IFilesGetRequests,
  IFilesReadRequests,
  IFilesStoreRequests,
  IRegistryDeleteRequests,
  IRegistryGetRequests,
  IRegistryStoreRequests,
  ICwWpResponse,
  ICwWpGetResponse,
  ICwWpPostResponse,
};