// Angular
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as DigitalSigningSelectors from '../../_state/digital-signing.selectors';
// Services
import { DigitalSigningCaseFilesService } from '../../_service/digital-signing-casefiles.service';
// Models
import { DigitalSigningCaseFileModel } from '../../_models';

interface IViewModel {
  caseFiles: DigitalSigningCaseFileModel[];
  loadingCaseFiles: boolean,
}

@Component({
  selector: 'casefile-view',
  templateUrl: './casefile-view-component.html',
  styleUrls: ['./casefile-view-component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class DigitalSigningCaseFileViewComponent implements OnInit, OnDestroy {

  model: IViewModel = {
    caseFiles: [],
    loadingCaseFiles: false,
  };

  subscriptions: Subscription[] = [];

  constructor(
    private caseFileService: DigitalSigningCaseFilesService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(DigitalSigningSelectors.isCaseFileLoading).subscribe(
      caseFileLoading => this.model.loadingCaseFiles = caseFileLoading
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getCaseFiles).subscribe(
      caseFiles => {
        // this.model.caseFiles = caseFiles.slice().sort((a, b) => b.caseFileId - a.caseFileId);
        this.model.caseFiles = JSON.parse(JSON.stringify(caseFiles.slice().sort((a, b) => b.caseFileId - a.caseFileId)));
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  openLink(caseFile: DigitalSigningCaseFileModel): void {
    window.open(caseFile.caseFileUrl, 'blank');
  }

  downloadDocumentsFromCaseFile(index: number): void {
    this.model.caseFiles[index].downloading = true;
    this.caseFileService.getCaseFileDocuments(this.model.caseFiles[index].caseFile).subscribe({
      next: () => this.model.caseFiles[index].downloading = false,
      error: () => this.model.caseFiles[index].downloading = false,
    });
  }
}
