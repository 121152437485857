// Angular
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
// NgRx
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Other
import * as ContactpersonModels from '../_models';
import * as ContactpersonState from '.';
import { ContactpersonService } from '../_service/contactperson.service';
@Injectable()
export class ContactpersonEffects {

  constructor(
    private actions$: Actions,
    private contactpersonService: ContactpersonService,
    private store: Store<ContactpersonState.State>
  ) { }

  getContactpersonInformationFromBusinessConnect$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(ContactpersonState.ContactpersonActions.getContactpersonInformationFromBusinessConnect),
        mergeMap((actionArgs) =>
          this.contactpersonService.getContactpersonInformationFromBusinessConnect().pipe(
            map((contactpersonResponseApiModel) => ContactpersonState.ContactpersonApiActions.getContactpersonInformationFromBusinessConnectSuccess(
              {
                contactperson: contactpersonResponseApiModel.contactPersonModel.map(contactperson => {
                  return {
                    name: contactperson.fullName,
                    role: null,
                    title: null,
                    sourceDescription: contactperson.roleDescription,
                  };
                })
              }
            )),
            catchError(error => {
              this.store.dispatch(ContactpersonState.ContactpersonApiActions.updateContactpersonInformationFailure());
              return EMPTY;
            })

          )
        )
      );
  });

  updateContactpersonInformation$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(ContactpersonState.ContactpersonActions.updateContactpersonInformation),
        mergeMap((actionArgs) =>
          this.contactpersonService.updateContactperson().pipe(
            map((contactpersonResponseApiModel) => {
              return ContactpersonState.ContactpersonApiActions.updateContactpersonInformationSuccess();
            }),
            catchError(error => {
              this.store.dispatch(ContactpersonState.ContactpersonApiActions.updateContactpersonInformationFailure());
              return EMPTY;
            })

          )
        )
      );
  });

  GetContactpersonSourceProvider$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(ContactpersonState.ContactpersonActions.GetContactpersonSourceProvider),
        mergeMap((actionArgs) =>
          this.contactpersonService.getCompanySourceFromBusinessConnect().pipe(
            map((companySourceApiModel) => {
              return ContactpersonState.ContactpersonApiActions.GetContactpersonSourceProviderSuccess({ contactpersonSourceProvider: companySourceApiModel });
            }),
            catchError(error => {
              this.store.dispatch(ContactpersonState.ContactpersonApiActions.GetContactpersonSourceProviderFailure());
              return EMPTY;
            })

          )
        )
      );
  });
}