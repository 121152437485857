// Angular
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// Others
import * as DigitalSigningSelectors from '../../../_state/digital-signing.selectors';
import * as DigitalSigningState from '../../../_state';

interface IViewModel {
  signingPackageTitle: string;
  emailSubject: string;
  emailText: string;
}

const DefaultEmailSubject = 'Dokumenter til din underskrift';
const DefaultEmailText = 'Find venligst dokument/dokumenter vedhæftet til din gennemgang og underskrift';

@Component({
  selector: 'app-digital-signing-step-email-info',
  templateUrl: './digital-signing-step-email-info.component.html',
  styleUrls: ['./digital-signing-step-email-info.component.scss'],
})

export class StepDigitalSigningEmailInfoComponent implements OnInit, OnDestroy {
  @Input() showFormErrors: boolean = false;
  @Input() stepActivated: Observable<any>;
  @Output() stepApproved = new EventEmitter<boolean>();

  model: IViewModel = {
    signingPackageTitle: '',
    emailSubject: DefaultEmailSubject,
    emailText: DefaultEmailText,
  };

  @ViewChild('signingPackageTitle') signingPackageTitleField: ElementRef;

  subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.stepActivated.subscribe(() => {
      // todo : find better solution then timeout
      setTimeout(() => {
        this.signingPackageTitleField.nativeElement.select();
      }, 200);
    }));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getSigningPackageTitle).subscribe(
      signingPackageTitle => {
        this.model.signingPackageTitle = signingPackageTitle;
        this.validate();
      }
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getEmailSubject).subscribe(
      emailSubject => {
        this.model.emailSubject = emailSubject;
        if (this.model.emailSubject === null)
          this.store.dispatch(DigitalSigningState.DigitalSigningActions.setEmailSubject({ emailSubject: DefaultEmailSubject }));
        this.validate();
      }
    ));

    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getEmailText).subscribe(
      emailText => {
        this.model.emailText = emailText;
        if (this.model.emailText === null)
          this.store.dispatch(DigitalSigningState.DigitalSigningActions.setEmailText({ emailText: DefaultEmailText }));
        this.validate();
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getProviderEmailTemplate() {
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.getDigitalSigningEmailTemplate());
  }

  signingPackageTitleChange(): void {
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setSigningPackageTitle({ signingPackageTitle: this.model.signingPackageTitle }));
  }

  emailSubjectChange(): void {
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setEmailSubject({ emailSubject: this.model.emailSubject }));
  }

  emailTextChange(): void {
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setEmailText({ emailText: this.model.emailText }));
  }

  validate(): void {
    setTimeout(() => this.stepApproved.emit(Boolean(this.model.signingPackageTitle && this.model.emailSubject && this.model.emailText)), 0);
  }
}

