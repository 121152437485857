// Angular modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
// Angular Material
import { MaterialModule } from '../../_material/material.module';
// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Directives
import { SpinnerDirective } from 'src/app/_directive/spinner.directive';
// Modules
import { ErrorModule } from '../error/error.module';
// Component
import { LoginUserCredentialsComponent } from './login-user-credentials-component/login-user-credentials.component';
// Other
import { AuthGuard } from '../../_guards/auth.guard';
import { AuthEffects } from './_state/auth.effects';
import { AuthReducer } from './_state/auth.reducer';
import { BusinessCoreRouteConstantService } from '../../_services/business-core-route-constant.service';

const routes: Routes = [
    { path: BusinessCoreRouteConstantService.LOGIN, component: LoginUserCredentialsComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [
        BrowserModule,
        SpinnerDirective,
        ErrorModule,
        FormsModule,
        MaterialModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('auth', AuthReducer),
        EffectsModule.forFeature([AuthEffects]),
    ],
    declarations: [
        LoginUserCredentialsComponent,
    ],
    exports: []
})
export class AuthModule { }