// Angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
//NgRx
import { Store } from '@ngrx/store';
// State
import * as ErrorSelectors from '../_state/error.selectors';
import * as ErrorState from '../_state';
// Service
import { ErrorTranslationService } from '../_service/error-translation.service';
// Models
import * as ErrorModels from '../_models';

interface IViewModel {
  errorPanelVisible: boolean,
  businessErrors: ErrorModels.ErrorClasses.BusinessError[],
  clientErrors: ErrorModels.ErrorInterfaces.IClientError[],
  cwWpErrors: ErrorModels.ErrorClasses.CwWpError[],
  httpErrors: ErrorModels.ErrorClasses.HttpError[],
  systemErrors: ErrorModels.ErrorClasses.SystemError[],
  errorsTranslations: ErrorModels.ErrorClasses.ErrorTranslationModel[];
}

@Component({
  selector: 'app-error-panel',
  templateUrl: './error-panel.component.html',
  styleUrls: ['./error-panel.component.scss'],
})

export class ErrorPanelComponent implements OnInit, OnDestroy {
  errors: string[] = [];

  subscriptions: Subscription[] = [];

  model: IViewModel = {
    errorPanelVisible: false,
    businessErrors: [],
    clientErrors: [],
    cwWpErrors: [],
    httpErrors: [],
    systemErrors: [],
    errorsTranslations: null,
  };

  constructor(
    private errorTranslationService: ErrorTranslationService,
    private store: Store<ErrorState.State>,
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.store.select(ErrorSelectors.getBusinessErrors).subscribe(
      businessErrors => {
        this.model.businessErrors = this.errorTranslationService.translateBusinessErrors(businessErrors);
        this.model.errorPanelVisible = this.calculateErrorPanelVisible();
      }
    ));

    this.subscriptions.push(this.store.select(ErrorSelectors.getClientErrors).subscribe(
      clientErrors => {
        this.model.clientErrors = clientErrors;
        this.model.errorPanelVisible = this.calculateErrorPanelVisible();
      }
    ));

    this.subscriptions.push(this.store.select(ErrorSelectors.getCwWpErrors).subscribe(
      cwWpErrors => {
        this.model.cwWpErrors = cwWpErrors;
        this.model.errorPanelVisible = this.calculateErrorPanelVisible();
      }
    ));

    this.subscriptions.push(this.store.select(ErrorSelectors.getHttpErrors).subscribe(
      httpErrors => {
        this.model.httpErrors = httpErrors;
        this.model.errorPanelVisible = this.calculateErrorPanelVisible();
      }
    ));

    this.subscriptions.push(this.store.select(ErrorSelectors.getSystemErrors).subscribe(
      systemError => {
        this.model.systemErrors = this.errorTranslationService.translateSystemErrors(systemError);
        this.model.errorPanelVisible = this.calculateErrorPanelVisible();
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  clearAllErrors(): void {
    this.store.dispatch(ErrorState.ErrorActions.clearAllErrors());
  }

  clearBusinessErrors(): void {
    this.store.dispatch(ErrorState.ErrorActions.clearBusinessErrors());
  }

  clearClientErrors(): void {
    this.store.dispatch(ErrorState.ErrorActions.clearClientErrors());
  }

  clearCwWpErrors(): void {
    this.store.dispatch(ErrorState.ErrorActions.clearCwWpErrors());
  }

  clearHttpErrors(): void {
    this.store.dispatch(ErrorState.ErrorActions.clearHttpErrors());
  }

  clearSystemErrors(): void {
    this.store.dispatch(ErrorState.ErrorActions.clearSystemErrors());
  }

  calculateErrorPanelVisible(): boolean {
    let errorPanelVisible =
      this.model.businessErrors?.length > 0 ||
      this.model.clientErrors?.length > 0 ||
      this.model.cwWpErrors?.length > 0 ||
      this.model.httpErrors?.length > 0 ||
      this.model.systemErrors?.length > 0;

    if (errorPanelVisible)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    return errorPanelVisible;
  }

  private translateBusinessErrors(businessErrors: ErrorModels.ErrorClasses.BusinessError[]): ErrorModels.ErrorClasses.BusinessError[] {
    if (!this.model.errorsTranslations) return businessErrors;
    var translated = businessErrors.map(error => {
      let translatedError = this.model.errorsTranslations.find(translation => translation.errorCode === error.errorCode);
      let translatedMessage = translatedError ? translatedError.errorTranslation : error.errorMessage;
      return new ErrorModels.ErrorClasses.BusinessError(error.errorCode, translatedMessage);
    });
    return translated;
  }

  private translateSystemErrors(systemErrors: ErrorModels.ErrorClasses.SystemError[]): ErrorModels.ErrorClasses.SystemError[] {
    if (!this.model.errorsTranslations) return systemErrors;
    var translated = systemErrors.map(error => {
      let translatedError = this.model.errorsTranslations.find(translation => translation.errorCode === error.errorCode);
      let translatedMessage = translatedError ? translatedError.errorTranslation : error.message;
      return new ErrorModels.ErrorClasses.SystemError(error.id, error.errorType, error.errorCode, translatedMessage);
    });
    return translated;
  }
}
