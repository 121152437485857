<div class="digital-signing-step-template-picker-container">

  <mat-grid-list cols="3" rowHeight="300px">
    <mat-grid-tile *ngFor="let template of model.signingTemplates; index as i">
      <shared-card *ngIf="template" [sharedCardInfo]="{
        'imagePath': null, 
        'title': template?.name,
        'content': getTemplateDescription(template.id), 
        'buttonLabel': 'Vælg',
        'selected': model.activeTemplate?.id === template.id}" (buttonPressed)="setActiveTemplate(template)">
      </shared-card>
    </mat-grid-tile>
  </mat-grid-list>

</div>