// Angular
import { Component, Inject, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// Other
import { SimpleDialogDataModel } from '../_models';

@Component({
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
})

export class SimpleDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SimpleDialogDataModel,
    public dialogRef: MatDialogRef<SimpleDialogComponent>,
    private domSanitizer: DomSanitizer,
  ) { }

  @HostListener("window:keydown.Escape")
  private onEscape() {
    this.dialogRef.close(false);
  }

  bypassSecurityTrustHtml(html: any): any {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
