// Services
import { UserInputValidationService } from 'src/app/_services';
// Models
import { SuggestionsModels } from './suggestionsModels';

export class ProviderModel {
  suggestions: SuggestionsModels;

  constructor(data: any) {
    if (!validateInput(data)) return null;
    this.suggestions = new SuggestionsModels(data.suggestions);
  }
}

function validateInput(input: any) {
  let suggestionsIsObject: boolean = true;
  if (input.suggestions) suggestionsIsObject = UserInputValidationService.objectInput(input.suggestions, 'ProviderModel');

  if (!suggestionsIsObject) return false;
  return true;
}