// NgRx
import { createAction, props } from '@ngrx/store';
// Other
import * as AuthenticationModels from '../../_models';


export const loginSuccess = createAction(
    '[Auth Api] Login Success',
    props<{ authUser: AuthenticationModels.AuthenticationUser; }>()
);

export const loginFailure = createAction(
    '[Auth Api] Login Failure'
);

