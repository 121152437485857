// Angular
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
// NgRx
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Other
import * as CompanyState from '.';
import { CompanyService } from '../_service/company.service';
@Injectable()
export class CompanyEffects {

  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private store: Store<CompanyState.State>
  ) { }

  GetCompanySourceProvider$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(CompanyState.CompanyActions.GetCompanySourceProvider),
        mergeMap((actionArgs) =>
          this.companyService.getCompanySourceFromBusinessConnect().pipe(
            map((companySourceApiModel) => {
              return CompanyState.CompanyApiActions.GetCompanySourceProviderSuccess({ companySourceProvider: companySourceApiModel });
            }),
            catchError(error => {
              this.store.dispatch(CompanyState.CompanyApiActions.GetCompanySourceProviderFailure());
              return EMPTY;
            })

          )
        )
      );
  });

  updateCompanyInformation$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(CompanyState.CompanyActions.updateCompanyInformation),
        mergeMap((actionArgs) =>
          this.companyService.updateEngagementData().pipe(
            map((companyResponseApiModel) => {
              return CompanyState.CompanyApiActions.updateCompanyInformationSuccess();
            }),
            catchError(error => {
              this.store.dispatch(CompanyState.CompanyApiActions.updateCompanyInformationFailure());
              return EMPTY;
            })

          )
        )
      );
  });
}