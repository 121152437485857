// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Angular Material
import { MaterialModule } from '../../_material/material.module';

// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// Component
import { CompanyComponent } from './company-component/company.component';

// Other
import { CompanyEffects } from './_state/company.effects';
import { CompanyReducer } from './_state/company.reducer';

@NgModule({
  imports: [
    BrowserModule,
    MaterialModule,
    StoreModule.forFeature('company', CompanyReducer),
    EffectsModule.forFeature([CompanyEffects]),
  ],
  declarations: [
    CompanyComponent,
  ],
  exports: [],
})
export class CompanyModule { }