<div class="erst-submission-container"
  [ngClass]="{'disabled-container': model.isLoading || model.isLoadingCertificateFromBusinessConnect || model.isLoadingSuggestedDocuments}">
  <div class="stepper-container">
    <mat-stepper labelPosition="bottom" #stepper [selectedIndex]="model.activeStep"
      (selectionChange)="navigateToStep($event.selectedIndex)">

      <ng-template matStepperIcon="edit" let-index="index">
        <mat-icon [style]="model.steps[index].iconStyle">{{model.steps[index].icon}}</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="error" let-index="index">
        <mat-icon [style]="model.steps[index].iconStyle">{{model.steps[index].icon}}</mat-icon>
      </ng-template>

      <ng-template [matStepperIcon]="this.model.steps[0].name">
        <mat-icon [style]="model.steps[0].iconStyle">{{model.steps[0].icon}}</mat-icon>
      </ng-template>
      <mat-step [state]="this.model.steps[0].name" [label]="this.model.steps[0].label" [completed]="this.model.steps[0].completed"
        [hasError]="model.showFormErrors && !this.model.steps[0].completed" errorMessage="Området er ikke udfyldt korrekt">
        <app-erst-submission-step-certifikat [showFormErrors]="model.showFormErrors"
          (stepApproved)="stepApproved($event, 0)"></app-erst-submission-step-certifikat>
      </mat-step>

      <ng-template [matStepperIcon]="this.model.steps[1].name">
        <mat-icon [style]="model.steps[1].iconStyle">{{model.steps[1].icon}}</mat-icon>
      </ng-template>
      <mat-step [state]="this.model.steps[1].name" [label]="this.model.steps[1].label" [completed]="this.model.steps[1].completed"
        [hasError]="model.showFormErrors && !this.model.steps[1].completed" errorMessage="Området er ikke udfyldt korrekt">
        <app-erst-submission-step-regnskaber (stepApproved)="stepApproved($event, 1)"></app-erst-submission-step-regnskaber>
      </mat-step>

      <ng-template [matStepperIcon]="this.model.steps[2].name">
        <mat-icon [style]="model.steps[2].iconStyle">{{model.steps[2].icon}}</mat-icon>
      </ng-template>
      <mat-step [state]="this.model.steps[2].name" [label]="this.model.steps[2].label" [completed]="false">
        <app-erst-submission-step-indsend></app-erst-submission-step-indsend>
      </mat-step>
    </mat-stepper>
  </div>

  <div class=" navigate-container">
    <span class="previousStep">
      <button mat-raised-button color="accent" *ngIf="model.activeStep > 0" (click)="navigatePrevious()">
        <mat-icon fontIcon="navigate_before"></mat-icon>
        Forrige
      </button>
    </span>
    <span class="nextStep">
      <button mat-raised-button color="accent" *ngIf="model.activeStep < model.numberOfSteps - 1" (click)="navigateNext()">
        Næste
        <mat-icon fontIcon="navigate_next"></mat-icon>
      </button>
      <button mat-raised-button color="primary" *ngIf="model.activeStep === model.numberOfSteps-1"
        [disabled]="!this.model.steps[0].completed || !this.model.steps[1].completed" (click)="submit()">
        Indsend regnskab
        <mat-icon class="icon-sm" fontIcon="send"></mat-icon>
      </button>
    </span>
  </div>
</div>
<mat-spinner spinnerPosition="center"
  *ngIf="model.isLoading || model.isLoadingCertificateFromBusinessConnect || model.isLoadingSuggestedDocuments"></mat-spinner>