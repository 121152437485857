// Angular
import { Component, Inject, HostListener, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// State
import * as DigitalSigningModels from '../../_models';
import * as DigitalSigningSelectors from '../../_state/digital-signing.selectors';
import * as DigitalSigningState from '../../_state';
// Other
import * as ApiModels from '../../_service/api-models';
import { DigitalSigningSuggestionService } from '../../_service/digital-signing-suggestion.service';

interface dialogData {
  documents: ApiModels.RequestModels.DigitalSigningProviderDocumentModel[],
  signers: ApiModels.RequestModels.DigitalSigningProviderSignerModel[],
}

interface IViewModel {
  documentTypes: DigitalSigningModels.DigitalSigningDocumentTypeModel[];
  signersTypes: DigitalSigningModels.DigitalSigningSignerTypeModel[];
}

@Component({
  selector: 'suggestion-view',
  templateUrl: './suggestion-view.component.html',
  styleUrls: ['./suggestion-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DigitalSigningSuggestionViewComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  model: IViewModel = {
    documentTypes: [],
    signersTypes: [],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: dialogData,
    public dialogRef: MatDialogRef<DigitalSigningSuggestionViewComponent>,
    private store: Store,
    private suggestionService: DigitalSigningSuggestionService,
  ) { }

  @HostListener("window:keydown.Escape")
  private onEscape() {
    this.activateStep(1);
    this.close();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(DigitalSigningSelectors.getActiveDigitalSigningTemplate).subscribe(
      activeSigningTemplate => {
        this.model.documentTypes = activeSigningTemplate?.documentTypes;
        this.model.signersTypes = activeSigningTemplate?.signerTypes;
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  addAndClose(step: number) {
    this.addSuggestions();
    this.activateStep(step);
    this.close();
  }

  dontAddAndClose(step: number) {
    this.activateStep(step);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  addSuggestions() {
    this.suggestionService.addSuggestion(this.dialogData.documents, this.dialogData.signers);
  }

  activateStep(step: number) {
    this.store.dispatch(DigitalSigningState.DigitalSigningActions.setActiveStep({ step: step }));
    this.close();
  }

  getDocumentNameFromId(id: number): string {
    if (id) {
      let documentType = this.model.documentTypes.find(document => document.id === id);
      if (documentType) return documentType.name;
    }
    return null;
  }

  getSignerRolesFromIds(ids: number[]): string {
    if (ids) {
      let signerTypes = this.model.signersTypes.filter(signer => ids.includes(signer.id));
      if (signerTypes.length > 0) {
        let roles = signerTypes.map(signerType => signerType.role);
        if (roles.length === 1) return roles[0];
        let last = roles.pop();
        return roles.join(', ') + ' og ' + last;
      };
    }
    if (this.model.signersTypes.length === 1) return this.model.signersTypes[0].role;
    return null;
  }

}
