// NgRx
import { createAction, props } from '@ngrx/store';
// Other
import * as ErrorModels from '../../_models';

export const clearAllErrors = createAction(
    '[Error] Clear all errors'
);

export const addBusinessErrors = createAction(
    '[Error] Add business errors',
    props<{ businessErrors: ErrorModels.ErrorClasses.BusinessError[]; }>()
);

export const clearBusinessErrors = createAction(
    '[Error] Clear business errors'
);

export const addClientError = createAction(
    '[Error] Add ClientError',
    props<{ clientError: ErrorModels.ErrorInterfaces.IClientError; }>()
);

export const clearClientErrors = createAction(
    '[Error] Clear ClientErrors'
);

export const addCwWpError = createAction(
    '[Error] Add CwWpError',
    props<{ cwWpError: ErrorModels.ErrorClasses.CwWpError; }>()
);

export const clearCwWpErrors = createAction(
    '[Error] Clear CwWpErrors'
);

export const addHttpError = createAction(
    '[Error] Add HttpError',
    props<{ httpError: ErrorModels.ErrorClasses.HttpError; }>()
);

export const clearHttpErrors = createAction(
    '[Error] Clear HttpErrors'
);

export const addSystemError = createAction(
    '[Error] Add system error',
    props<{ systemError: ErrorModels.ErrorClasses.SystemError; }>()
);

export const clearSystemErrors = createAction(
    '[Error] Clear system errors'
);
