// NgRx
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { ILicenseState } from './license.reducer';

// Selectors
const getLicensFeatureState = createFeatureSelector<ILicenseState>('licens');

export const getAvailableLicensedServices = createSelector(
  getLicensFeatureState,
  state => state.availableLicensedServices
);

export const getAvailableLicensedServicesLoadState = createSelector(
  getLicensFeatureState,
  state => state.availableLicensedServicesLoadState
);

export const getDigitalSigningLicensedService = createSelector(
  getLicensFeatureState,
  state => state.digitalSigningLicensedService
);

export const getErstLicensedService = createSelector(
  getLicensFeatureState,
  state => state.erstLicensedService
);

export const getLoadingLicenses = createSelector(
  getLicensFeatureState,
  state => state.loadingLicenses
);
