// Angular
import { Injectable } from '@angular/core';
// Other
import { CvDataPersonModel } from '../_models/cw-wp/models';

@Injectable({
  providedIn: 'root',
})

export abstract class CwWpCvDataConstantService {
  private static readonly MANAGEMENT_MEMBER_NAME = 'DIREKTION';
  private static readonly MANAGEMENT_MEMBER_TITLE = 'DIRTIT';
  private static readonly MANAGEMENT_MEMBER_SECONDARY_TITLE = 'DIRTITA';
  private static readonly MANAGEMENT_MEMBER_EMAIL = ['MAPPE:A14.DN8', 'MAPPE:A14.DN17', 'MAPPE:A14.DN97', 'MAPPE:A14.DN27', 'MAPPE:A14.DN37', 'MAPPE:A14.DN47', 'MAPPE:A14.DN57', 'MAPPE:A14.DN77', 'MAPPE:A14.DN87',];

  private static readonly BOARD_MEMBER_NAME = 'BESTYRELSE';
  private static readonly BOARD_MEMBER_TITLE = 'FORMAND';
  private static readonly BOARD_MEMBER_EMAIL = ['MAPPE:A15.DN8', 'MAPPE:A16.DN8', 'MAPPE:A17.DN8', 'MAPPE:A18.DN8', 'MAPPE:A19.DN8', 'MAPPE:A20.DN8', 'MAPPE:A22.DN8', 'MAPPE:A23.DN8', 'MAPPE:A24.DN8', 'MAPPE:A25.DN8', 'MAPPE:A26.DN8', 'MAPPE:A27.DN8', 'MAPPE:A28.DN8', 'MAPPE:A29.DN8', 'MAPPE:A30.DN8',];

  private static readonly AUDITOR_CV_ID = 'UNDERSKRIVREV';
  private static readonly AUDITOR_MAIL = 'MAILREV';

  private static readonly CHAIRMAN_NAME = 'GE1.DIR';
  private static readonly CHAIRMAN_TITLE = 'TITLEDIR';
  private static readonly CHAIRMAN_MAIL = 'MAILDIR';

  public static readonly FINANCIAL_STATEMENT_PLACEHOLDER_ID = 'ED2';
  public static readonly SPECIFICATIONS_PLACEHOLDER_ID = 'ED3';
  public static readonly TAX_SPECIFICATIONS_PLACEHOLDER_ID = 'ED4';
  public static readonly INTERNAL_STATEMENT_PLACEHOLDER_ID = 'ED8';
  public static readonly LETTER_OF_AGREEMENT_PLACEHOLDER_ID = 'ED9';
  public static readonly PROTOCOL_PLACEHOLDER_ID = 'ED12';
  public static readonly MANAGEMENT_LETTER_PLACEHOLDER_ID = 'ED13';
  public static readonly SUMMARY_GENERAL_MEETING_PLACEHOLDER_ID = 'ED15';
  public static readonly COMPANY_TAX_RETURN_PLACEHOLDER_ID = 'ED17';
  public static readonly APPENDIX_PLACEHOLDER_ID = 'ED18';
  public static readonly AUDIT_PROTOCOL_PLACEHOLDER_ID = 'ED19';
  public static readonly OTHER_D_PLACEHOLDER_ID = 'ED20';
  public static readonly OTHER_B_PLACEHOLDER_ID = 'ED21';
  public static readonly OTHER_DT_PLACEHOLDER_ID = 'ED22';
  public static readonly OTHER_DB_PLACEHOLDER_ID = 'ED23';
  public static readonly OTHER_DBR_PLACEHOLDER_ID = 'ED24';
  public static readonly OTHER_DR_PLACEHOLDER_ID = 'ED25';
  public static readonly OTHER_BR_PLACEHOLDER_ID = 'ED26';

  public static readonly SIGNING_PACKAGE_IDS = { group: 'BCWP', form: 'SIGNING', id: 'PACKAGE_IDS' };
  public static readonly ENGAGEMENT_GUID = { group: 'BCWP', form: 'GENERAL', id: 'ENGAGEMENT_GUID' };

  public static getManagementMembersCvRequest(memberNumber: number): CvDataPersonModel {
    return new CvDataPersonModel({
      name: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.MANAGEMENT_MEMBER_NAME + memberNumber, }, },
      title: {
        cvData: { group: '', form: '', id: CwWpCvDataConstantService.MANAGEMENT_MEMBER_TITLE + memberNumber, },
        mapFn: (v) => v.title ? v.title.split("|")[2] == '1' ? v.secTitle : v.title.split("|")[2] : null
      },
      email: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.MANAGEMENT_MEMBER_EMAIL[memberNumber - 1], } },
      secTitle: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.MANAGEMENT_MEMBER_SECONDARY_TITLE + memberNumber, } },
    });
  }
  public static getBoardMembersCvRequest(memberNumber: number): CvDataPersonModel {
    return new CvDataPersonModel({
      name: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.BOARD_MEMBER_NAME + memberNumber, } },
      title: {
        cvData: { group: '', form: '', id: CwWpCvDataConstantService.BOARD_MEMBER_TITLE + memberNumber, },
        mapFn: (v) => v.title ? v.title.split("|")[3] : null
      },
      email: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.BOARD_MEMBER_EMAIL[memberNumber - 1], } },
    });
  }

  public static getAuditorCvRequest(memberNumber: number): CvDataPersonModel {
    return new CvDataPersonModel({
      name: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.AUDITOR_CV_ID + (memberNumber + (memberNumber * 1) - 1) }, },
      title: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.AUDITOR_CV_ID + (memberNumber + (memberNumber * 1)) }, },
      email: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.AUDITOR_MAIL + memberNumber, } },
    });
  }

  public static getChairmanCvRequest(): CvDataPersonModel {
    return new CvDataPersonModel({
      name: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.CHAIRMAN_NAME } },
      title: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.CHAIRMAN_TITLE } },
      email: { cvData: { group: '', form: '', id: CwWpCvDataConstantService.CHAIRMAN_MAIL } },
    });
  }
}
