export class LicensedServicesModel {
  constructor(
    public licensedServiceId: string,
    public licensedServiceName: string,
    public licensedServiceDescription: string,
    public licensedServiceImageUrl: string,
    public licensedServiceGroup: number,
    public licensedServiceGroupImageUrl: string,
    public licensedServiceUserValidated: boolean
  ) { }
}