// Services
import { UserInputValidationService } from 'src/app/_services';

export class DigitalSigningDocumentCasewareRequest {
  casewareDocumentId: string;
  documentTypeId: number;

  constructor(data: any) {
    if (!validateInput(data)) return null;
    this.casewareDocumentId = data.casewareDocumentId;
    this.documentTypeId = data.documentTypeId;
  }
}

function validateInput(input: any) {
  let CasewareDocumentIdIsRequiredProperty: boolean = false;
  let casewareDocumentIdIsString: boolean = false;
  let documentTypeIdIsNumber: boolean = true;

  CasewareDocumentIdIsRequiredProperty = UserInputValidationService.propertyRequired(input, 'casewareDocumentId', 'DigitalSigningDocumentCasewareRequest');
  if (CasewareDocumentIdIsRequiredProperty)
    casewareDocumentIdIsString = UserInputValidationService.stringInput(input.casewareDocumentId, 'DigitalSigningDocumentCasewareRequest');
  if (input.documentTypeId) documentTypeIdIsNumber = UserInputValidationService.numberInput(input.documentTypeId, 'DigitalSigningDocumentCasewareRequest');

  if (!CasewareDocumentIdIsRequiredProperty || !casewareDocumentIdIsString || !documentTypeIdIsNumber) return false;
  return true;
}