// State
import * as AppState from 'src/app/_state/app.state';
import * as LicenseActions from './_actions/license.actions';
import * as LicenseApiActions from './_actions/license-api.actions';

export {
    LicenseActions,
    LicenseApiActions,
};

export interface State extends AppState.State {
}