<div class="card-container">
  <mat-card>
    <div *ngIf="sharedCardInfo?.imagePath" class="image-container">
      <img src={{sharedCardInfo?.imagePath}}>
    </div>
    <mat-card-title EllipsisToolTip [matTooltip]="sharedCardInfo?.title" matTooltipDisabled="true">
      {{sharedCardInfo?.title}}
    </mat-card-title>
    <mat-card-content>
      {{sharedCardInfo?.content}}
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="sharedCardInfo.selected" mat-raised-button disabled>
        <mat-icon color="accent" fontIcon="check_circle"></mat-icon>
      </button>
      <button *ngIf="!sharedCardInfo.selected" mat-raised-button color="accent" (click)="buttonPressedEmit()">
        {{sharedCardInfo?.buttonLabel}}
      </button>
    </mat-card-actions>
  </mat-card>
</div>