// Angular
import { Injectable } from '@angular/core';
// Other
import { UserRole } from '../_models';


@Injectable({
    providedIn: 'root'
})
export class UserRoleMappingService {
    constructor() {
    }

    mapApplicationUserRole2UserRole(applicationUserRole: string): UserRole {
        switch (applicationUserRole) {
            case "Admin":
                return 'Admin';
            case "User":
                return 'User';
            case "ProviderBindingService":
                return 'ProviderBindingService';
            case "ErhvervsstyrelsenIndsender":
                return 'ErhvervsstyrelsenIndsender';
            case "ErhvervsstyrelsenLogViewer":
                return 'ErhvervsstyrelsenLogViewer';
            case "SuperUser":
                return 'SuperUser';
            case "Support":
                return 'Support';
            case "RealOwnerService":
                return 'RealOwnerService';
            case "PEPListService":
                return 'PEPListService';
            case "TaxonomyEditor":
                return 'TaxonomyEditor';
            case "TaxonomyService":
                return 'TaxonomyService';
            case "CertificateManager":
                return 'CertificateManager';
            case "LicenseManager":
                return 'LicenseManager';
            case "CollaborateEntityManager":
                return 'CollaborateEntityManager';
            default:
                console.error("PLEASE update UserRole type in [UserRoles.ts] and UserRole map in code [UserRoleMappingService.ts] - Unknown [ApplicationUserRole]: " + applicationUserRole);
                return 'UNKNOWN';
        }
    }
}