<div class="app-layout-container">
    <mat-toolbar class="top cwi-mat-primary">
        <img src="https://bcwp.caseware.dk/assets/img/CaseWare-Logo-RGB-Primary_Dark-TM.png" (click)="navigateToHome()">
        <span class="spacer">{{model.environmentText}}</span>
        <button mat-icon-button (click)="logOut()" matTooltip="Log ud" matTooltipPosition="below" *ngIf="model.userAuthenticated">
            <mat-icon fontIcon="logout"></mat-icon>
        </button>
    </mat-toolbar>
    <div class="update-container">
        <div class="update-component" *ngIf="model.userAuthenticated">
            <app-update-component></app-update-component>
        </div>
    </div>
    <div class="error-container" *ngIf="model.userAuthenticated">
        <div class="error-panel">
            <app-error-panel></app-error-panel>
        </div>
    </div>
    <div class="router-outlet-container" *ngIf="this.model.routerOutletVisibility">
        <div class="router-outlet">
            <router-outlet></router-outlet>
        </div>
    </div>
    <mat-toolbar class="bottom">
        <div *ngFor="let route of model.navigationRoutes">
            <a mat-button color="accent" [routerLink]="'/' + route.route">{{route.label}}</a>
        </div>
    </mat-toolbar>
    <div class="watermark">{{model.environmentText}}</div>
</div>