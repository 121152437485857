// Services
import { UserInputValidationService } from 'src/app/_services';
// Models
import { CvDataPersonModel } from 'src/app/_models/cw-wp/models/CvDataPersonModel';

export class DigitalSigningSignerCasewareRequest {
  signers: CvDataPersonModel[];
  switchTitle?: Object = null;
  mapFn?: Function;

  constructor(data: any) {
    if (!validateInput(data)) return null;
    this.signers = data.contacts.map(contact => new CvDataPersonModel(contact));
    this.mapFn = data.mapFn;
    if (data.switchTitle) this.switchTitle = data.switchTitle;
  }
}

function validateInput(input: any) {
  let contactsIsRequiredProperty: boolean = false;
  let contactsIsArray: boolean = false;
  let switchTitleIsObject: boolean = false;
  let mapFnIsFunction: boolean = true;

  contactsIsRequiredProperty = UserInputValidationService.propertyRequired(input, 'contacts', 'DigitalSigningSignerCasewareRequest');
  if (contactsIsRequiredProperty) contactsIsArray = UserInputValidationService.arrayInput(input.contacts, false, 'DigitalSigningSignerCasewareRequest');
  if (input.switchTitle) switchTitleIsObject = UserInputValidationService.objectInput(input.switchTitle, 'CvDataGetModel');
  if (input.mapFn) mapFnIsFunction = UserInputValidationService.functionInput(input.mapFn, 'DigitalSigningSignerCasewareRequest');


  if (!contactsIsRequiredProperty || !contactsIsArray) return false;
  if (!contactsIsRequiredProperty || !contactsIsArray || !mapFnIsFunction) return false;
  return true;
}