// NgRx
import { createReducer, on } from '@ngrx/store';
// Other
import * as ContactpersonState from './';
import * as ContactpersonModels from '../_models';
import { ContactpersonSourceApiModel } from '../_service/api-models/response-models/ContactpersonSourceApiModel';

export interface IContactpersonState {
  contactpersonSourceProvider: ContactpersonSourceApiModel,
  contactperson: ContactpersonModels.CasewareContactperson[],
  updatingContactpersonInfo: boolean,
  isLoading: boolean,
}
export const initialState: IContactpersonState = {
  contactpersonSourceProvider: null,
  contactperson: [],
  updatingContactpersonInfo: false,
  isLoading: false,
};

export const ContactpersonReducer = createReducer<IContactpersonState>(
  initialState,
  // contactpersonActions
  on(ContactpersonState.ContactpersonActions.GetContactpersonSourceProvider, (state): IContactpersonState => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(ContactpersonState.ContactpersonActions.updateContactpersonInformation, (state): IContactpersonState => {
    return {
      ...state,
      updatingContactpersonInfo: true,
      isLoading: true
    };
  }),

  on(ContactpersonState.ContactpersonActions.getContactpersonInformationFromBusinessConnect, (state): IContactpersonState => {
    return {
      ...state,
      isLoading: true
    };
  }),

  // contactpersonApiActions
  on(ContactpersonState.ContactpersonApiActions.GetContactpersonSourceProviderSuccess, (state, actionArgs): IContactpersonState => {
    return {
      ...state,
      contactpersonSourceProvider: actionArgs.contactpersonSourceProvider,
      isLoading: false,
    };
  }),

  on(ContactpersonState.ContactpersonApiActions.GetContactpersonSourceProviderFailure, (state): IContactpersonState => {
    return {
      ...state,
      contactpersonSourceProvider: null,
      isLoading: false,
    };
  }),

  on(ContactpersonState.ContactpersonApiActions.getContactpersonInformationFromBusinessConnectSuccess, (state, actionArgs): IContactpersonState => {
    return {
      ...state,
      contactperson: actionArgs.contactperson,
      isLoading: false,
    };
  }),

  on(ContactpersonState.ContactpersonApiActions.getContactpersonInformationFromBusinessConnectFailure, (state): IContactpersonState => {
    return {
      ...state,
      contactperson: null,
      isLoading: false,
    };
  }),

  on(ContactpersonState.ContactpersonApiActions.updateContactpersonInformationSuccess, (state): IContactpersonState => {
    return {
      ...state,
      updatingContactpersonInfo: false,
      isLoading: false,
    };
  }),

  on(ContactpersonState.ContactpersonApiActions.updateContactpersonInformationFailure, (state): IContactpersonState => {
    return {
      ...state,
      updatingContactpersonInfo: false,
      isLoading: false,
    };
  }),
);