// Other
import * as AppState from 'src/app/_state/app.state';
import * as ContactpersonActions from './_actions/contactperson.actions';
import * as ContactpersonApiActions from './_actions/contactperson-api.actions';

export {
    ContactpersonActions,
    ContactpersonApiActions,
};

export interface State extends AppState.State {
}