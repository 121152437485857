// Angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// Services
import { BusinessCoreRouteConstantService } from 'src/app/_services';
// Models
import * as DashBoardModels from '../_models';
// State
import * as LayoutState from '../../layout/_state';
import * as LicenseState from '../../license/_state';
import * as LicenseSelectors from '../../license/_state/license.selectors';

interface IViewModel {
  digitalSigningCard: DashBoardModels.DashBoardCardModel;
  erstCard: DashBoardModels.DashBoardCardModel;
  loadingLicenses: boolean;
}

@Component({
  // No selector -> component is only for routing
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  model: IViewModel = {
    digitalSigningCard: null,
    erstCard: null,
    loadingLicenses: false
  };

  constructor(
    private router: Router,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(LayoutState.LayoutActions.setNavigationRoutes(
      {
        routes: []
      }
    ));

    this.subscriptions.push(this.store.select(LicenseSelectors.getLoadingLicenses).subscribe(
      loadingLicenses => {
        this.model.loadingLicenses = loadingLicenses;
      }
    ));
    this.store.dispatch(LicenseState.LicenseActions.getAvailableLicensedServices());

    this.subscriptions.push(this.store.select(LicenseSelectors.getErstLicensedService).subscribe(
      erstLicensedService => {
        this.model.erstCard = erstLicensedService
          ? new DashBoardModels.DashBoardCardModel(
            erstLicensedService.licensedServiceGroupImageUrl
              ? erstLicensedService.licensedServiceGroupImageUrl
              : erstLicensedService.licensedServiceImageUrl,
            'Indsendelsesservice',
            // erstLicensedService.licensedServiceName,
            erstLicensedService.licensedServiceDescription,
            () => this.router.navigate([BusinessCoreRouteConstantService.ERST_SUBMISSION]),
            'Gå til',
            erstLicensedService.licensedServiceUserValidated,
            'Du har ikke rettighed til dette produkt. Kan tilføjes i Business Connect'
          )
          : null;
      }
    ));
    this.subscriptions.push(this.store.select(LicenseSelectors.getDigitalSigningLicensedService).subscribe(
      digitalSigningLicensedService => {
        this.model.digitalSigningCard = digitalSigningLicensedService
          ? new DashBoardModels.DashBoardCardModel(
            digitalSigningLicensedService.licensedServiceGroupImageUrl
              ? digitalSigningLicensedService.licensedServiceGroupImageUrl
              : digitalSigningLicensedService.licensedServiceImageUrl,
            'Digital Signering',
            // digitalSigningLicensedService.licensedServiceName,
            digitalSigningLicensedService.licensedServiceDescription,
            () => this.router.navigate([BusinessCoreRouteConstantService.SIGNING_RETRIEVE]),
            'Gå til',
            digitalSigningLicensedService.licensedServiceUserValidated,
            'Integration er ikke opsat korrekt i Business Connect'
          )
          : null;
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  };
}
