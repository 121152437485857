<div class="contactperson-container" [ngClass]="{'disabled-container': model.working}">
  <button mat-raised-button color="primary" (click)="updateContactpersonInformation()">Opdater kontaktpersoner</button>


  <div cdkDropListGroup class="contactperson-lists-container">

    <div class="contactperson-column">
      <h1>Ikke fordelt</h1>
      <div cdkDropList [cdkDropListData]="model.noType" class="contactperson-list" (cdkDropListDropped)="drop($event, 'noType')">
        <div class="contactperson-box" *ngFor="let contactperson of model.noType" cdkDrag>
          <div>{{contactperson.name}}</div>
          <div>{{contactperson.sourceDescription}}</div>
        </div>
      </div>
    </div>

    <div class="contactperson-column">
      <h1>Bestyrelse</h1>
      <div cdkDropList [cdkDropListData]="model.bestyrelse" class="contactperson-list" (cdkDropListDropped)="drop($event, 'bestyrelse')">
        <div class="contactperson-box" *ngFor="let bestyrelse of model.bestyrelse" cdkDrag>
          <div>{{bestyrelse.name}}</div>
          <div>{{bestyrelse.sourceDescription}}</div>
        </div>
      </div>
    </div>

    <div class="contactperson-column">
      <h1>Direktion</h1>
      <div cdkDropList [cdkDropListData]="model.direktion" class="contactperson-list" (cdkDropListDropped)="drop($event, 'direktion')">
        <div class="contactperson-box" *ngFor="let direktion of model.direktion" cdkDrag>
          <div>{{direktion.name}}</div>
          <div>{{direktion.sourceDescription}}</div>
        </div>
      </div>
    </div>

  </div>



</div>

<mat-spinner spinnerPosition="center" *ngIf="model.working"></mat-spinner>