// Angular
import { NgModule } from '@angular/core';
// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LicenseReducer } from './_state/license.reducer';
import { LicenseEffects } from './_state/license.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('licens', LicenseReducer),
    EffectsModule.forFeature([LicenseEffects]),
  ],
  declarations: [],
  exports: []
})
export class LicensModule { }