// Angular
import { Injectable } from '@angular/core';
// Models
import { TokenInfo } from '../_models/common/tokenInfo';

@Injectable({
    providedIn: 'root'
})
export class BusinessCoreTokenDecoderService {
    private readonly FSR_TOKEN_TYPE = 'FSR.TokenType';
    private readonly FSR_APPLICATION_USER_ID = 'FSR.ApplicationUserId';
    private readonly FSR_APPLICATION_USER_APIKEY = 'FSR.ApplicationUserApiKey';
    private readonly FSR_APPLICATION_USER_NAME = 'FSR.ApplicationUserName';
    private readonly FSR_APPLICATION_USER_EMAIL = 'FSR.ApplicationUserEmail';
    private readonly FSR_APPLICATION_USER_PHONE_NUMBER = 'FSR.ApplicationUserPhoneNumber';
    private readonly FSR_APPLICATION_CUSTOMER_ID = 'FSR.ApplicationCustomerId';
    private readonly FSR_APPLICATION_CLIENT_ID = 'FSR.ApplicationClientId';
    private readonly FSR_APPLICATION_CLIENT_UID = 'FSR.ApplicationClientUID';
    private readonly FSR_APPLICATION_USER_ROLES = 'FSR.ApplicationUserRoles';


    private readonly SUB = 'sub';
    private readonly JTI = 'jti';
    private readonly EXP = 'exp';
    private readonly ISS = 'iss';
    private readonly AUD = 'aud';

    public getTokenInfo(jwtToken: string): TokenInfo {
        let jwtPayload = atob(jwtToken.split('.')[1]);
        let jsonTokenInfo = JSON.parse(jwtPayload);

        let tokenInfo: TokenInfo = new TokenInfo();

        tokenInfo.sub = jsonTokenInfo[this.SUB];
        tokenInfo.jti = jsonTokenInfo[this.JTI];
        tokenInfo.exp = jsonTokenInfo[this.EXP];
        tokenInfo.iss = jsonTokenInfo[this.ISS];
        tokenInfo.aud = jsonTokenInfo[this.AUD];

        tokenInfo.tokenType = jsonTokenInfo[this.FSR_TOKEN_TYPE];
        tokenInfo.applicationUserId = parseInt(jsonTokenInfo[this.FSR_APPLICATION_USER_ID]);
        tokenInfo.applicationUserApiKey = jsonTokenInfo[this.FSR_APPLICATION_USER_APIKEY];
        tokenInfo.applicationUserName = jsonTokenInfo[this.FSR_APPLICATION_USER_NAME];
        tokenInfo.applicationUserEmail = jsonTokenInfo[this.FSR_APPLICATION_USER_EMAIL];
        tokenInfo.applicationUserPhoneNumber = jsonTokenInfo[this.FSR_APPLICATION_USER_PHONE_NUMBER];
        tokenInfo.applicationCustomerId = parseInt(jsonTokenInfo[this.FSR_APPLICATION_CUSTOMER_ID]);
        tokenInfo.applicationClientId = jsonTokenInfo[this.FSR_APPLICATION_CLIENT_ID];
        tokenInfo.applicationClientUID = jsonTokenInfo[this.FSR_APPLICATION_CLIENT_UID];
        tokenInfo.applicationUserRoles = [];
        var tempApplicationUserRoles = jsonTokenInfo[this.FSR_APPLICATION_USER_ROLES];
        if (tempApplicationUserRoles)
            tokenInfo.applicationUserRoles = tempApplicationUserRoles.split(",").map(function (item) {
                return item.trim();
            });

        tokenInfo.expirationDate = new Date(1000 * tokenInfo.exp);

        return tokenInfo;
    }
}