// Angular
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as AuthSelectors from '../_state/auth.selectors';
import * as AuthState from '../_state';
import * as ErrorState from '../../error/_state';
// Services
import { SessionStorageConstantService } from '../../../_services';
// Models
import * as AuthModels from '../_models';
import { UserCredentials } from '../../../_models/common';

interface IViewModel {
  authUser: AuthModels.AuthenticationUser,
  authenticatingUser: boolean,
  userCredentials: UserCredentials,
  rememberMeChecked: boolean | null,
  returnUrl: null,
  hidePassword: boolean,
}

@Component({
  templateUrl: './login-user-credentials.component.html',
  styleUrls: ['./login-user-credentials.component.scss'],
})

export class LoginUserCredentialsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  model: IViewModel = {
    authUser: null,
    authenticatingUser: false,
    userCredentials: { username: null, password: null },
    rememberMeChecked: null,
    returnUrl: null,
    hidePassword: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sessionStorageConstantService: SessionStorageConstantService,
    private store: Store,
  ) { }

  @HostListener("window:keydown.Enter")
  private onEnter() {
    this.onLogin();
  }

  ngOnInit(): void {
    sessionStorage.removeItem(this.sessionStorageConstantService.REMEMBER_ME); // removes remember_me -> only necessary on new login

    this.store.dispatch(ErrorState.ErrorActions.clearAllErrors());

    this.model.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.subscriptions.push(this.store.select(AuthSelectors.getAuthUser).subscribe(
      (authUser: AuthModels.AuthenticationUser) => {
        this.model.authUser = authUser;
        if (authUser) this.router.navigateByUrl(this.model.returnUrl);
      }
    ));

    if (!this.model.authUser) this.store.dispatch(AuthState.AuthActions.autoLogin());

    this.subscriptions.push(this.store.select(AuthSelectors.getAuthenticatingUser).subscribe(
      (authenticatingUser: boolean) => this.model.authenticatingUser = authenticatingUser
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setRememberMe(): void {
    this.model.rememberMeChecked
      ? sessionStorage.setItem(this.sessionStorageConstantService.REMEMBER_ME, 'true')
      : sessionStorage.removeItem(this.sessionStorageConstantService.REMEMBER_ME);
  }

  onLogin(): void {
    if (!this.model?.userCredentials?.username || !this.model?.userCredentials?.password) return;
    this.store.dispatch(ErrorState.ErrorActions.clearAllErrors());
    this.store.dispatch(AuthState.AuthActions.loginUserCredentials({
      userCredentials: new UserCredentials({
        username: this.model?.userCredentials?.username,
        password: this.model?.userCredentials?.password
      }),
    }));
  }
}
