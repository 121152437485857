// Angular
import { Injectable } from '@angular/core';
// NgRx
import { Store } from '@ngrx/store';
// Other
import * as ErrorState from '../components/error/_state';
import * as ErrorModels from '../components/error/_models';

@Injectable({
  providedIn: 'root'
})

export class ErrorDispatchService {
  constructor(
    private store: Store<ErrorState.State>
  ) { }

  dispatchBusinessErrors(businessErrors: ErrorModels.ErrorClasses.BusinessError[]): void {
    try {
      this.store.dispatch(ErrorState.ErrorActions.addBusinessErrors({ businessErrors: businessErrors }));
    }
    catch (error) {
      console.error(error);
    }
  }

  dispatchCwWpErrors(cwWpError: ErrorModels.ErrorClasses.CwWpError): void {
    try {
      this.store.dispatch(ErrorState.ErrorActions.addCwWpError({ cwWpError: cwWpError }));
    }
    catch (error) {
      console.error(error);
    }
  }

  dispatchHttpErrors(httpError: ErrorModels.ErrorClasses.HttpError): void {
    try {
      this.store.dispatch(ErrorState.ErrorActions.addHttpError({ httpError: httpError }));
    }
    catch (error) {
      console.error(error);
    }
  }

  dispatchSystemErrors(systemError: ErrorModels.ErrorClasses.SystemError): void {
    try {
      this.store.dispatch(ErrorState.ErrorActions.addSystemError({ systemError: systemError }));
    }
    catch (error) {
      console.error(error);
    }
  }

  dispatchOtherError(error: any): void {
    try {
      let clientError: ErrorModels.ErrorInterfaces.IClientError = {
        name: "Unknown error type",
        message: error?.message ? error?.message : (error ?? null),
        stack: error?.stack ?? null
      };
      this.store.dispatch(ErrorState.ErrorActions.addClientError({ clientError: clientError }));
    }
    catch (error) {
      console.error(error);
    }
  }
}