// Services
import { UserInputValidationService } from 'src/app/_services';

export class erstConfigFileModel {
  documentId: string;

  constructor(data: erstConfigFileModel) {
    if (!data.documentId || !validateInput(data)) return null;
    this.documentId = data.documentId || null;
  }
}

function validateInput(input: any): boolean {
  let documentIdIsString = input.documentId ? UserInputValidationService.stringInput(input.documentId, 'erstConfigFileModel') : true;
  if (!documentIdIsString) return false;
  return true;
}