// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, take } from 'rxjs';
// NgRx
import { Store } from '@ngrx/store';
// Services
import { DialogService } from 'src/app/_services';
import { UpdateService } from '../_service/update.service';
// State
import * as UpdateSelectors from '../_state/update.selectors';
import * as UpdateState from '../_state';
// Models
import { SimpleDialogDataModel } from '../../_shared-components/_models';


interface IViewModel {
  isLoading: boolean,
  message: string,
}

@Component({
  selector: 'app-update-component',
  templateUrl: './update.component.html',
})
export class UpdateComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  model: IViewModel = {
    isLoading: true,
    message: null
  };

  constructor(
    private dialogService: DialogService,
    private store: Store,
    private updateService: UpdateService
  ) { }


  ngOnInit() {
    this.store.dispatch(UpdateState.UpdateActions.isCspUpdateRequired());

    this.subscriptions.push(this.store.select(UpdateSelectors.isCheckingForUpdate).subscribe(
      checkingForUpdate => this.model.isLoading = checkingForUpdate
    ));

    this.subscriptions.push(this.store.select(UpdateSelectors.isCspUpdateRequired).subscribe(
      updateRequired => {
        if (updateRequired) {
          this.dialogService.openSimpleDialog(new SimpleDialogDataModel(
            'Det er nødvendigt at opdatere integrationen.<br><br>Ved at klikke på opdatere vil installations pakken automatisk blive downloadet og kan installeres ved at åbne downloaded fil og følge installations vejledningen',
            'Hent opdatering',
            'Opdatering krævet',
            'info'
          )).afterClosed().pipe(take(1)).subscribe(updateButtonPressed => {
            if (updateButtonPressed) {
              this.updateService.downloadCspFilesPackage();
              this.model.message = 'Integration kan genstartes efter opdateringen ved at trykke F5';
            } else {
              this.model.message = 'Integration skal opdateres';
            }
          });
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}