// State
import * as AppState from 'src/app/_state/app.state';
import * as UpdateActions from './_actions/update.actions';
import * as UpdateApiActions from './_actions/update-api.actions';

export {
    UpdateActions,
    UpdateApiActions,
};

export interface State extends AppState.State {
}