// Services
import { BusinessCoreConstantService } from 'src/app/_services';
import { UserInputValidationService } from 'src/app/_services';
// Models
import { ProviderModel } from './providerModel';

export class configDigitalSigningModel {
  [providerId: string]: ProviderModel;

  constructor(config: any) {
    Object.entries(config).forEach(e => {
      if (validateInput(e)) createProvider(this, e);
      // if (validateInput(e) && getProviderId(e[0])) this[getProviderId(e[0])] = new ProviderModel(e[1]);
    });
  }
}
function createProvider(_this, e: any[]) {
  let providerId = getProviderId(e[0]);
  let providerModel = new ProviderModel(e[1]);
  if (!providerId || Object.values(providerModel).length === 0) return;
  _this[providerId] = providerModel;
}

function getProviderId(name: string): string {
  let id: string = null;
  switch (name) {
    case 'penneo':
      id = BusinessCoreConstantService.PENNEO_LICENSED_SERVICE_ID;
      break;
    default:
      console.error(`Digital signing provider [${name}] is not known`);
      break;
  }
  return id;
}

function validateInput(input: any): boolean {
  let isString = UserInputValidationService.stringInput(input[0], 'configDigitalSigningModel');
  let isObject = UserInputValidationService.objectInput(input[1], 'configDigitalSigningModel');

  if (!isString || !isObject) return false;
  return true;
}