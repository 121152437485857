// angular
import { Component, EventEmitter, Input, OnDestroy, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
// rxjs
import { Subscription, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export type InputTypes = 'text';

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label: string = '';
  @Input() inputType: InputTypes = 'text';
  @Input() placeholder: string = '';
  @Input() value: any;
  @Input() propertyOfValue: string = null;
  @Input() required: boolean = false;
  @Input() autoCompleteOptions: any[] = [];

  @Output() valueChange = new EventEmitter<any>();

  subscriptions: Subscription[] = [];

  autoCompleteDisplayControl = new FormControl<string>('');
  autoCompleteValueControl = new FormControl<any>('');
  autoCompleteFilteredOptions: Observable<any[]>;

  constructor() { }

  public ngOnInit(): void {
    this.autoCompleteDisplayControl.valueChanges.subscribe(() => {
      if (typeof this.autoCompleteDisplayControl.value === 'string') {
        this.propertyOfValue
          ? this.autoCompleteValueControl.value[this.propertyOfValue] = this.autoCompleteDisplayControl.value
          : this.autoCompleteValueControl.setValue(this.autoCompleteDisplayControl.value);
      } else {
        this.autoCompleteValueControl.setValue(JSON.parse(JSON.stringify(this.autoCompleteDisplayControl.value)));
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['autoCompleteOptions']) {
      this.autoCompleteOptions = changes['autoCompleteOptions'].currentValue;
      this.autoCompleteFilteredOptions = this.autoCompleteDisplayControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const newValue = typeof value === 'string' ? value : value[this.propertyOfValue];
          return newValue ? this.filterAutoComplete(newValue as string) : this.autoCompleteOptions.slice();
        }),
      );
    }

    if (changes['value'] && changes['value'].currentValue) {
      this.autoCompleteValueControl.setValue(changes['value'].currentValue);
      this.propertyOfValue
        ? this.autoCompleteDisplayControl.setValue(changes['value'].currentValue[this.propertyOfValue])
        : this.autoCompleteDisplayControl.setValue(changes['value'].currentValue);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public updateValue(): void {
    if (typeof this.autoCompleteDisplayControl.value === 'string') {
      setTimeout(() => {
        this.valueChange.emit(this.autoCompleteValueControl.value);
      }, 100);
    } else {
      this.valueChange.emit(this.autoCompleteValueControl.value);
    }
  }

  public displayAutoComplete(value: any): string {
    // BUG : This is a reference to the mat-autocomplete component not this component. therefore this.propertyOfValue is undefined
    return typeof value === 'string' ? value : value?.name;
    // return this.propertyOfValue ? value[this.propertyOfValue] : value;
  }

  private filterAutoComplete(filter: string): any[] {
    const filterValue = filter.toLowerCase();
    return this.autoCompleteOptions.filter(option => this.propertyOfValue
      ? option[this.propertyOfValue].toLowerCase().includes(filterValue)
      : option.toLowerCase().includes(filterValue)
    );
  }
}
