// Angular
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
// NgRx
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// Service
import { UpdateService } from '../_service/update.service';
import * as UpdateState from ".";


@Injectable()
export class UpdateEffects {

    constructor(
        private actions$: Actions,
        private updateService: UpdateService,
        private store: Store<UpdateState.State>
    ) { }

    isCspUpdateRequired$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(UpdateState.UpdateActions.isCspUpdateRequired),
                mergeMap(() =>
                    this.updateService.cspFilesUpdateRequired().pipe(
                        map((updateRequired: boolean) => {
                            return UpdateState.UpdateApiActions.isCspUpdateRequiredSuccess({ updateRequired: updateRequired }
                            );
                        }),
                        catchError(error => {
                            this.store.dispatch(UpdateState.UpdateApiActions.isCspUpdateRequiredFailure());
                            return EMPTY;
                        })
                    )
                ),
            );
    });
}