// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
// Angular Material
import { MaterialModule } from '../../_material/material.module';
// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Directives
import { EllipsisDirective } from 'src/app/_directive/ellipsis-tooltip.directive';
import { IconDirective } from 'src/app/_directive/icon.directive';
import { SpinnerDirective } from 'src/app/_directive/spinner.directive';
// Modules
import { DigitalSigningStepModule } from './digital-signing-submit-component/steps/digital-signing-steps.module';
import { SharedComponentsModule } from 'src/app/components/_shared-components/shared-components.module';
// Component
import { DigitalSigningCaseFileViewComponent } from './digital-signing-retrieve-component/casefile-view-component/casefile-view-component';
import { DigitalSigningSubmitComponent } from './digital-signing-submit-component/digital-signing-submit.component';
import { DigitalSigningRetrieveComponent } from './digital-signing-retrieve-component/digital-signing-retrieve.component';
import { DigitalSigningSuggestionViewComponent } from './digital-signing-submit-component/suggestion-view/suggestion-view.component';
// Other
import { DigitalSigningEffects } from './_state/digital-signing.effects';
import { DigitalSigningReducer } from './_state/digital-signing.reducer';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    OverlayModule,
    EllipsisDirective,
    IconDirective,
    SpinnerDirective,
    DigitalSigningStepModule,
    SharedComponentsModule,
    BrowserModule,
    MaterialModule,
    StoreModule.forFeature('digitalSigning', DigitalSigningReducer),
    EffectsModule.forFeature([DigitalSigningEffects]),
  ],
  declarations: [
    DigitalSigningCaseFileViewComponent,
    DigitalSigningSubmitComponent,
    DigitalSigningRetrieveComponent,
    DigitalSigningSuggestionViewComponent,
  ],
  exports: [],
})
export class DigitalSigningModule { }