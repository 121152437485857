// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
// Angular Material
import { MaterialModule } from '../../_material/material.module';
// Components
import { CompanyComponent } from '../company/company-component/company.component';
import { ContactpersonComponent } from '../contactperson/contactperson-component/contactperson.component';
import { DashboardCardComponent } from './dashboard-component/dashboard-card/dashboard-card.component';
import { DashboardComponent } from './dashboard-component/dashboard.component';
import { DigitalSigningRetrieveComponent } from '../digital-signing/digital-signing-retrieve-component/digital-signing-retrieve.component';
import { DigitalSigningSubmitComponent } from '../digital-signing/digital-signing-submit-component/digital-signing-submit.component';
import { ErstSubmissionComponent } from '../erhvervsstyrelsen/erst-submission/erst-submission.component';
// Directives
import { IconDirective } from 'src/app/_directive/icon.directive';
import { SpinnerDirective } from 'src/app/_directive/spinner.directive';
// Module
import { CompanyModule } from '../company/company.module';
import { ErhvervsstyrelsenModule } from '../erhvervsstyrelsen/erhvervsstyrelsen.module';
import { ErrorModule } from '../error/error.module';
// Other
import { AuthGuard } from './../../_guards/auth.guard';
import { BusinessCoreRouteConstantService } from './../../_services/business-core-route-constant.service';


const routes: Routes = [
    { path: BusinessCoreRouteConstantService.COMPANY, component: CompanyComponent, canActivate: [AuthGuard] },
    { path: BusinessCoreRouteConstantService.CONTACTPERSON, component: ContactpersonComponent, canActivate: [AuthGuard] },
    { path: BusinessCoreRouteConstantService.DASHBOARD, component: DashboardComponent, canActivate: [AuthGuard] },
    { path: BusinessCoreRouteConstantService.SIGNING_RETRIEVE, component: DigitalSigningRetrieveComponent, canActivate: [AuthGuard] },
    { path: BusinessCoreRouteConstantService.SIGNING_SUBMISSION, component: DigitalSigningSubmitComponent, canActivate: [AuthGuard] },
    { path: BusinessCoreRouteConstantService.ERST_SUBMISSION, component: ErstSubmissionComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [
        BrowserModule,
        IconDirective,
        SpinnerDirective,
        CompanyModule,
        ErhvervsstyrelsenModule,
        ErrorModule,
        MaterialModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        DashboardCardComponent,
        DashboardComponent,
    ],
    exports: [
    ]
})
export class HomeModule { }