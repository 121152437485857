import { NgModule, Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[dragAndDropFile]'
})
export class DragAndDropFileDirective {

  @Output() onFileDropped = new EventEmitter<FileList>();
  @HostBinding('style.opacity') private workspace_opacity = '1';

  // Dragover listener, when files are dragged over host element
  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.workspace_opacity = '0.5';
  }

  // Dragleave listener, when files are dragged away from host element
  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.workspace_opacity = '1';
  }

  // Drop listener, when files are dropped on host element
  @HostListener('drop', ['$event']) public ondrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.workspace_opacity = '1';
    let files = event.dataTransfer!.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }
}

@Directive({
  selector: '[ignoreDropFile]'
})
export class IgnoreDropFileDirective {

  // Dragover listener, when files are dragged over host element
  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  // Dragleave listener, when files are dragged away from host element
  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
    event.preventDefault();
  }

  // Drop listener, when files are dropped on host element
  @HostListener('drop', ['$event']) public ondrop(event: DragEvent) {
    event.preventDefault();
  }
}

@NgModule({
  declarations: [
    DragAndDropFileDirective,
    IgnoreDropFileDirective
  ],
  exports: [
    DragAndDropFileDirective,
    IgnoreDropFileDirective
  ]
})
export class DragAndDropDirective { }
