// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
// NgRx
import { Store } from '@ngrx/store';
// State
import * as AuthState from '../authentication/_state';
import * as AuthSelectors from '../authentication/_state/auth.selectors';
import * as LayoutSelectors from './_state/layout.selectors';
import * as LicenseState from '../license/_state';
import * as UpdateSelectors from '../update/_state/update.selectors';
// Environment
import { environment } from 'src/environments/environment';
// Services
import { BusinessCoreRouteConstantService } from 'src/app/_services';
// Models
import { NavigationButtonModel } from './_models/navigationButtonModel';

interface IViewModel {
  environmentText: string;
  userAuthenticated: boolean;
  checkingForUpdate: boolean;
  cspUpdateRequired: boolean;
  navigationRoutes: NavigationButtonModel[];
  routerOutletVisibility: boolean;
}

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {

  model: IViewModel = {
    environmentText: null,
    userAuthenticated: false,
    checkingForUpdate: false,
    cspUpdateRequired: false,
    navigationRoutes: [],
    routerOutletVisibility: false,
  };

  subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
    private router: Router,
  ) { }


  ngOnInit(): void {
    this.environmentText(environment.type);

    this.subscriptions.push(this.store.select(AuthSelectors.getAuthUser).subscribe(
      authUser => {
        this.model.userAuthenticated = authUser !== null;
        this.calculateRouterOutletVisibility();
      }
    ));

    this.subscriptions.push(this.store.select(UpdateSelectors.isCspUpdateRequired).subscribe(
      updateRequired => {
        this.model.cspUpdateRequired = updateRequired !== null ? updateRequired : true;
        this.calculateRouterOutletVisibility();
      }
    ));

    this.subscriptions.push(this.store.select(UpdateSelectors.isCheckingForUpdate).subscribe(
      checkingForUpdate => {
        this.model.checkingForUpdate = checkingForUpdate;
        this.calculateRouterOutletVisibility();
      }
    ));

    this.subscriptions.push(this.store.select(LayoutSelectors.getNavigationRoutes).subscribe(
      navigationRoutes => {
        setTimeout(() => {
          this.model.navigationRoutes = navigationRoutes;
        }, 0);
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  calculateRouterOutletVisibility(): void {
    setTimeout(() => {
      if (!this.model.userAuthenticated) {
        this.model.routerOutletVisibility = true;
        return;
      }
      if (this.model.checkingForUpdate || this.model.cspUpdateRequired) {
        this.model.routerOutletVisibility = false;
        return;
      }
      this.model.routerOutletVisibility = true;
    }, 0);
  }

  environmentText(environmentType: string): void {
    let environmentText = null;
    switch (environmentType) {
      case 'dev':
        this.model.environmentText = 'UDVIKLING';
        break;
      case 'qa':
        this.model.environmentText = 'TESTMILJØ';
        break;
      case 'rc':
        this.model.environmentText = 'RC';
        break;
      case 'prod':
        this.model.environmentText = '';
        break;
      default:
        throw Error(`fb66832f-debb-4fab-8783-d2077ffced93 - The function [environmentText] does not recognize the type: ${environmentType}`);
    }
    return environmentText;
  }


  navigateToHome(): void {
    this.router.navigate([BusinessCoreRouteConstantService.DASHBOARD]);
  }

  logOut(): void {
    this.store.dispatch(AuthState.AuthActions.logout());
    this.store.dispatch(LicenseState.LicenseActions.clearState());
  }
}