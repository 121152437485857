// Angular
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, timeout } from 'rxjs/operators';

import * as ApiModels from '../../company/_service/api-models';
import * as AppServices from '../../../_services/';
import * as CwWpModels from '../../../_models/cw-wp';

import { environment } from 'src/environments/environment';

import { AxiosHttpClient } from 'src/app/_http/axios-http-client';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(
    private retrieverService: AppServices.WpDataRetrieverService,
    private errorDispatchService: AppServices.ErrorDispatchService,
    private httpClient: AxiosHttpClient
  ) { }

  getBusinessRegistrationNumberFromCaseware(): Observable<CwWpModels.apiModels.ICwWpResponse> {
    const CVR_REQUEST: CwWpModels.apiModels.IClientGetRequests = new CwWpModels.apiModels.IClientGetRequests([
      { property: 'ClientProfile', subProperty: 'EIN' }
    ]);
    return this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_GET_CLIENT_DATA, CVR_REQUEST);
  }

  getEngagementDataFromBusinessConnect(cvr: string): Observable<ApiModels.ICompanyResponseApiModel> {
    return this.httpClient.get(AppServices.BusinessCoreConstantService.ENDPOINT_COMPANY + '/' + cvr, true)
      .pipe(
        timeout(environment.timeout),
        map((ICompanyResponseApiModel) => {
          if (this.serverErrorResponse(ICompanyResponseApiModel)) return null;
          return ICompanyResponseApiModel;
        }),
        catchError(error => {
          this.errorDispatchService.dispatchOtherError(error);
          return throwError(() => error);
        })
      );
  }

  // updateEngagementDataInCaseware(clientStoreRequests: CwWpModels.apiModels.IClientStoreRequests): Observable<CwWpModels.apiModels.ICwWpResponse> {
  updateEngagementDataInCaseware(clientStoreRequests: CwWpModels.apiModels.IClientStoreRequests): void {
    console.log(clientStoreRequests);
    // todo : remember cloud integration
    // todo : what if it is the same
    // todo : should null be skipped, gives error
    this.httpClient.post(AppServices.CwWpConstantService.ENDPOINT_STORE_CLIENT_DATA, clientStoreRequests).subscribe(console.log);
  }

  updateEngagementData() {
    return this.retrieverService.getBusinessRegistrationNumber().pipe(
      timeout(environment.timeout),
      // mergeMap((cvrModel) => { console.log(cvrModel); return this.getEngagementDataFromBusinessConnect(cvrModel); }),
      // mergeMap((companyResponseApiModel: ApiModels.ICompanyResponseApiModel) => {
      //   // list of properties can be found here https://developers.caseware.com/sdk/desktop/Reference/classes/CWClientProfile.html
      //   var clientStoreRequests = new CwWpModels.apiModels.IClientStoreRequests([
      //     { property: 'Name', value: companyResponseApiModel.companyModel.companyName },
      //     { property: 'Address1', value: companyResponseApiModel.companyModel.companyStreet },
      //     { property: 'PostalCode', value: companyResponseApiModel.companyModel.companyZip },
      //     { property: 'City', value: companyResponseApiModel.companyModel.companyCity },
      //     { property: 'Country', value: companyResponseApiModel.companyModel.companyCountry },
      //     { property: 'Phone', value: companyResponseApiModel.companyModel.companyPhone },
      //   ]);
      //   return this.updateEngagementDataInCaseware(clientStoreRequests);
      map(() => {
        var clientStoreRequests = new CwWpModels.apiModels.IClientStoreRequests([
          { property: 'Name', value: null },
        ]);
        return this.updateEngagementDataInCaseware(clientStoreRequests);
      }),
      catchError(error => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getCompanySourceFromBusinessConnect(): Observable<ApiModels.CompanySourceApiModel> {
    return this.httpClient.get(AppServices.BusinessCoreConstantService.ENDPOINT_COMPANY_CALCULATED_SOURCE, true)
      .pipe(
        timeout(environment.timeout),
        map((ICompanySourceResponseApiModel) => {
          if (this.serverErrorResponse(ICompanySourceResponseApiModel)) return null;
          let companySourceApiModel: ApiModels.CompanySourceApiModel = ICompanySourceResponseApiModel.sourceModel;
          return companySourceApiModel;
        }),
        catchError(error => {
          this.errorDispatchService.dispatchOtherError(error);
          return throwError(() => error);
        })
      );
  }

  private serverErrorResponse(responseApiModel: any): boolean {
    if (responseApiModel?.errorModel?.businessErrors.length > 0 || responseApiModel?.errorModel?.systemError) {
      if (responseApiModel?.errorModel?.businessErrors.length > 0) {
        this.errorDispatchService.dispatchBusinessErrors(responseApiModel.errorModel.businessErrors);
      } else if (responseApiModel?.errorModel?.systemError) {
        this.errorDispatchService.dispatchSystemErrors(responseApiModel?.errorModel?.systemError);
      }
      return true;
    }
    return false;
  }
}