<div class="digital-signing-casefile-view-container">
  <div *ngFor="let caseFile of model.caseFiles; index as i">
    <mat-card class="example-card">
      <mat-card-header>
        <mat-icon mat-card-avatar color="accent" class="icon-lg">
          {{caseFile.status === 3 ? 'cancel' : (caseFile.status === 2 ? 'check_circle' : 'schedule') }}</mat-icon>
        <mat-card-title EllipsisToolTip [matTooltip]="caseFile.title">{{caseFile.title}}</mat-card-title>
        <mat-card-subtitle class="mat-subheading-1">Casefile Id: {{caseFile.caseFileId}}</mat-card-subtitle>
      </mat-card-header>
      <!-- normal content -->
      <mat-card-content *ngIf="!caseFile.error">
        <div>
          <span class="mat-body-2">Status på forsendelsen: </span>{{caseFile.vendorStatusDescription}}
        </div>
        <ul>
          <li *ngFor="let document of caseFile.documents"><span class="mat-body-2">{{document.title}}: </span>{{document.description}}</li>
        </ul>
      </mat-card-content>
      <!-- error content -->
      <mat-card-content *ngIf="caseFile.error && caseFile.errorCode === 94">
        <div>
          <span class="mat-body-2">Status på forsendelsen: </span> Forsendelsen kan ikke findes
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="caseFile.error && caseFile.errorCode === 93">
        <div>
          <span class="mat-body-2">Status på forsendelsen: </span> Du har ikke de korrekt adgangs rettigheder til forsendelsen
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-button color="accent" [disabled]="caseFile.error" matTooltip="Link til forsendelsen" (click)="openLink(caseFile)">
          LINK
        </button>
        <button mat-button *ngIf="!caseFile.downloading" color="accent" [disabled]="caseFile.status !==2" matTooltip="Download dokumenter"
          (click)="downloadDocumentsFromCaseFile(i)">
          HENT
        </button>
        <button mat-button disabled="true" *ngIf="caseFile.downloading" matTooltip="Downloader dokumenter">
          <mat-spinner diameter="20" spinnerPosition="element-x-center" color="accent"></mat-spinner>
        </button>
      </mat-card-actions>
      <mat-card-footer>
        <mat-progress-bar *ngIf="caseFile.downloading" color="accent" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </div>
</div>