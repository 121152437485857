// Angular
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
// Environment
import { environment } from 'src/environments/environment';
// Services
import { BusinessCoreAppSettingsService } from './_services/business-core-app-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // refactor : add environment
  title = 'CW* Business Connect';

  constructor(
    private appSettingsService: BusinessCoreAppSettingsService,
    private titleService: Title,
  ) {
    switch (environment.type) {
      case 'dev':
        this.title = 'CW* Business Connect (DEV)';
        break;
      case 'qa':
        this.title = 'CW* Business Connect (QA)';
        break;
      case 'rc':
        this.title = 'CW* Business Connect (RC)';
        break;
      case 'prod':
        break;
      default:
        throw Error(`29c20cad-9852-4e5e-9f01-5b1c279a5651 - The function [setTitle] does not recognize the environment type: ${environment.type}`);
    }
    this.titleService.setTitle(this.title);
  }
}
