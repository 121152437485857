// Angular
import { Component, EventEmitter, Input, OnDestroy, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, ReplaySubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'mat-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SelectSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() appearance: any;
  @Input() autoSelectOnlyOption: boolean = false;
  @Input() color: string;
  @Input() label: string = '';
  @Input() placeholderLabel: string = 'Søg';
  @Input() multiSelect: boolean = false;
  @Input() noEntriesFoundLabel: string = 'Intet resultat';
  @Input() options: any[];
  @Input() propertyOfValue: string = null;
  @Input() propertyOfValueToDisplay: string = null;
  @Input() required: boolean = false;
  @Input() value: any;
  @Input() useSelectTrigger: boolean = false;
  @Output() selectionChange = new EventEmitter<any>();

  subscriptions: Subscription[] = [];

  optionsController: FormControl<any[]> = new FormControl<any[]>([]); // same type as @input() options: any[]
  filterController: FormControl<string> = new FormControl<string>('');
  filteredOptionsController: ReplaySubject<any[]> = new ReplaySubject<any[]>(1); // same type as @input() options: any[]

  @ViewChild('selector', { static: true }) selector: MatSelect;

  constructor() { }

  ngOnInit(): void {
    this.subscriptions.push(this.optionsController.valueChanges.subscribe(value => this.selectionChange.emit(value)));
    this.subscriptions.push(this.filterController.valueChanges.subscribe(() => this.filterOptions()));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterOptions();
    if (changes['options']) {
      if (this.autoSelectOnlyOption && this.options && this.options.length === 1) {
        this.setOptionsControllerValue(this.multiSelect ? this.options : this.options[0]);
      }
    }
    if (changes['value']) {
      if (!changes['value'].currentValue || JSON.stringify(changes['value'].currentValue) === JSON.stringify(changes['value'].previousValue)) return;
      let value;
      if (this.multiSelect) {
        value = this.propertyOfValue
          ? this.options.filter(option => changes['value'].currentValue.includes(option[this.propertyOfValue]))
          : changes['value'].currentValue;
      } else {
        value = this.propertyOfValue
          ? this.options.find(option => option[this.propertyOfValue] === changes['value'].currentValue)
          : changes['value'].currentValue;
      }
      this.setOptionsControllerValue(value);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  createToolTip(): string {
    return (this.propertyOfValueToDisplay
      ? this.optionsController.value.map(o => o[this.propertyOfValueToDisplay])
      : this.optionsController.value
    ).join('\n');
  }

  private setOptionsControllerValue(value: any): void {
    // timeout used to prevent: NG0100: ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.optionsController.setValue(value);
    });
  }

  private filterOptions(): void {
    if (!this.options) return;
    let search = this.filterController.value;
    if (!search) {
      this.filteredOptionsController.next(this.options.slice());
    } else {
      search = search.toLowerCase();
      this.filteredOptionsController.next(
        this.options.filter(type => this.propertyOfValueToDisplay ?
          type[this.propertyOfValueToDisplay].toLowerCase().indexOf(search) > -1 :
          type.toLowerCase().indexOf(search) > -1)
      );
    }
  }
}
