// Angular
import { Injectable } from '@angular/core';
// Http
import { AxiosHttpClient } from '../_http/axios-http-client';
// Environment
import { environment } from 'src/environments/environment';
// Services
import { BusinessCoreConstantService } from './business-core-constant.service';

interface IAppSettingsModel {
  build: {
    buildPipeline: string,
    buildNumber: string;
  },
  release: {
    releasePipeline: string,
    releaseName: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class BusinessCoreAppSettingsService {
  appSettingsModel: IAppSettingsModel = {
    build: {
      buildPipeline: null,
      buildNumber: null
    },
    release: {
      releasePipeline: null,
      releaseName: null
    }
  };

  constructor(
    private httpClient: AxiosHttpClient
  ) {
    this.getAppSettings();
  }

  private getAppSettings() {
    return this.httpClient.get(BusinessCoreConstantService.URL_TO_APP_SETTINGS_FILE).subscribe(response => {
      this.appSettingsModel.build.buildPipeline = response['BuildInfo']['BuildPipeline'];
      this.appSettingsModel.build.buildNumber = response['BuildInfo']['BuildNumber'];
      this.appSettingsModel.release.releasePipeline = response['ReleaseInfo']['ReleasePipeline'];
      this.appSettingsModel.release.releaseName = response['ReleaseInfo']['ReleaseName'];

      console.info("##############################################################################################");
      console.info("Build & Release Info:");
      console.info("BuildPipeline: " + this.getBuildPipeline());
      console.info("BuildNumber: " + this.getBuildNumber());
      console.info("ReleasePipeline: " + this.getReleasePipeline());
      console.info("ReleaseName: " + this.getReleaseName());
      console.info("Environment host: " + environment.host);
      console.info("##############################################################################################");
    });
  }

  getBuildPipeline(): string {
    return this.appSettingsModel.build.buildPipeline;
  }

  getBuildNumber(): string {
    return this.appSettingsModel.build.buildNumber;
  }

  getReleasePipeline(): string {
    return this.appSettingsModel.release.releasePipeline;
  }

  getReleaseName(): string {
    return this.appSettingsModel.release.releaseName;
  }
}