import { IEnvironment } from "./IEnvironment";

export const environment: IEnvironment = {
  production: false,
  type: 'dev',
  host: 'https://bc-dev.caseware.dk',
  timeout: 180000,
  url: 'https://bcwp-dev.caseware.dk',
  web: 'bcw-dev.caseware.dk',
};
